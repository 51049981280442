/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { Project } from "../../concept/project";
import { projectDetailsPath } from "../../routing/resource-paths";
import { ApplicationState } from "../../service/application-state.service";

@Pipe({
    name: "projectDetailsPath",
    standalone: true,
})
export class ProjectDetailsPathPipe implements PipeTransform {
    constructor(private app: ApplicationState) {
    }

    transform(value: Project): string {
        return projectDetailsPath(value, this.app.requireCurrentOrg());
    }
}
