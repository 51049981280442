/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component } from "@angular/core";
import { ButtonComponent } from "typedb-platform-framework";
import { orgPath } from "../../../routing/resource-paths";
import { PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { ApplicationState } from "../../../service/application-state.service";
import { RouterLink } from "@angular/router";

@Component({
    selector: "tp-internal-404-page",
    templateUrl: "internal-404-page.component.html",
    styleUrls: ["internal-404-page.component.scss"],
    standalone: true,
    imports: [PageScaffoldComponent, ButtonComponent, RouterLink],
})
export class Internal404PageComponent {
    readonly linkTarget = orgPath(this.app.requireCurrentOrg());

    constructor(private app: ApplicationState) {
    }
}
