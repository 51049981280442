/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";
import {TeamProto} from "../../protocol/concept";

export interface Team extends BaseResource {
    name: string;
    createdAt: Date;
}

export function partialTeamOf(data: TeamProto): PartialWithUuid<Team> {
    const team: PartialWithUuid<Team> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        name: data.hasName ? data.name : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
    };
    return stripUndefinedValues(team) as PartialWithUuid<Team>;
}

export function teamProtoOf(team: Partial<Team>): TeamProto {
    return new TeamProto({
        uuid: team.uuid ? stringToBytes(team.uuid) : undefined,
        id: team.id,
        name: team.name,
        createdAt: team.createdAt?.getTime(),
    });
}

export type TeamColumn = "id" | "name" | "createdAt";

export const teamProperties: Record<TeamColumn, Property> = {
    id: { id: "id", name: "Team ID", attributeType: "id" },
    name: { id: "name", name: "Team Name", attributeType: "name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const teamPropertiesList = [teamProperties.id, teamProperties.name, teamProperties.createdAt];
