@if (table.ready$ | async) {
  <table mat-table [dataSource]="table.items$" matSort (matSortChange)="tableService.handleMatSortChange(table, $event)">
    <ng-container matColumnDef="orgId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.orgId.name }}</th>
      <td mat-cell *matCellDef="let invitation">{{ invitation.org.id }}</td>
    </ng-container>
    <ng-container matColumnDef="orgName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.orgName.name }}</th>
      <td mat-cell *matCellDef="let invitation">{{ invitation.org.name }}</td>
    </ng-container>
    <ng-container matColumnDef="userEmail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.userEmail.name }}</th>
      <td mat-cell *matCellDef="let invitation">{{ invitation.userEmail }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.createdAt.name }}</th>
      <td mat-cell *matCellDef="let invitation">{{ invitation.createdAt | datetime }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let invitation">
        <button
          mat-fab aria-label="more" [disabled]="!rowPopupMenuItems!(invitation).length"
          [matMenuTriggerFor]="rowPopupMenu" [matMenuTriggerData]="{ items: rowPopupMenuItems!(invitation) }"
          (click)="$event.stopPropagation()"
        >
          <fa-icon [icon]="['fal', 'ellipsis']"/>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
    <tr mat-row *matRowDef="let invitation; columns: table.displayedColumns"></tr>
  </table>
} @else {
  <tp-spinner/>
}

<mat-menu #rowPopupMenu="matMenu">
  <ng-template matMenuContent let-items="items">
    @for (item of items; track item) {
      <button mat-menu-item (click)="item.action()">{{ item.label }}</button>
    }
  </ng-template>
</mat-menu>
