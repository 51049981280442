/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { combineLatest, first, map, of, startWith, Subject, switchMap } from "rxjs";
import {
    ConfirmationModalComponent, ConfirmationModalData, MenuItem, ModalComponent, StrongConfirmationModalComponent,
    StrongConfirmationModalData, ButtonComponent
} from "typedb-platform-framework";
import { Invitation, InvitationColumn } from "../../../concept/organization";
import { LOADING, NO_ITEMS_SELECTED } from "../../../framework/strings";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { ResourceTable } from "../../../service/resource-table.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { InvitationsTableComponent } from "../../org/invitations-table/invitations-table.component";
import { resourceAvailabilityStreamOf, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { AsyncPipe } from "@angular/common";
import { InvitationDialogComponent } from "../invite/invitation-dialog.component";
import { UsersTableComponent } from "../table/users-table.component";
import { MatDialog } from "@angular/material/dialog";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: "tp-members-page",
    templateUrl: "./members-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, ModalComponent, ConfirmationModalComponent, TableToolbarComponent, AsyncPipe,
        UsersTableComponent, TablePaginatorComponent, InvitationsTableComponent, ButtonComponent, FontAwesomeModule,
        MatTooltipModule,
    ],
})
export class MembersPageComponent implements OnDestroy {
    readonly org = this.app.requireCurrentOrg();
    readonly currentUser = this.app.requireCurrentUser();

    private readonly unsubMembers$ = new Subject<void>();
    readonly members = this.orgCtl.membersTable(this.unsubMembers$);
    private readonly unsubInvitations$ = new Subject<void>();
    invitations?: ResourceTable<Invitation, InvitationColumn>;
    readonly availability$ = resourceAvailabilityStreamOf(this.members);

    readonly cannotInviteReason$ = this.app.hasOrgAdminAccess$.pipe(map(isAdmin => {
        if (!isAdmin) return "Only organization administrators can invite members";
        return null;
    }), startWith(LOADING));
    readonly cannotRemoveReason$ = this.app.hasOrgAdminAccess$.pipe(switchMap(isAdmin => {
        if (!isAdmin) return of("Only organization administrators can remove members");
        else return this.members.selectionChanged$.pipe(map(() => {
            if (!this.members.isAnySelected()) return NO_ITEMS_SELECTED;
            if (this.members.selected.some(x => x.id === this.currentUser.id)) return "You can't remove yourself from an organization";
            return null;
         }));
    }), startWith(LOADING));
    readonly inviteEnabled$ = this.cannotInviteReason$.pipe(map(x => !x));
    readonly removeEnabled$ = this.cannotRemoveReason$.pipe(map(x => !x));

    readonly invitationsTableRowPopupMenuItems: (invitation: Invitation) => MenuItem[] = (invitation) => {
        return [
            new MenuItem("Resend invitation email", () => {
                this.orgApi.resendInvitation(invitation.uuid).subscribe(() => {
                    this.snackbar.success(`A new invitation email has been sent to '${invitation.userEmail}'`);
                });
            }),
            new MenuItem("Revoke invitation", () => {
                this.openRevokeInvitationModal(invitation);
            }),
        ];
    }

    constructor(
        public app: ApplicationState, private orgApi: OrgApi, private snackbar: SnackbarService,
        private orgCtl: OrgController, private dialog: MatDialog
    ) {
        this.app.hasOrgAdminAccess$.pipe(first()).subscribe((isAdmin) => {
            if (isAdmin) this.invitations = this.orgCtl.invitationsTable(this.unsubInvitations$);
        });
    }

    ngOnDestroy() {
        this.unsubMembers$.next();
        this.unsubInvitations$.next();
    }

    onInviteClick() {
        this.dialog.open(InvitationDialogComponent, { width: "600px" }).beforeClosed().subscribe(() => { this.invitations!.refresh(); });
    }

    openRevokeInvitationModal(invitation: Invitation) {
        const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
            data: {
                title: "Revoke invitation",
                body: `Are you sure you would like to revoke ${invitation.userEmail}'s invitation to ${invitation.org?.name}?`,
                confirmText: "Revoke invitation",
                confirmButtonStyle: "primary-solid red",
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.orgApi.revokeInvitation(invitation.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Invitation revoked successfully.`);
                this.invitations!.refresh();
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openRemoveMembersModal() {
        const selected = this.members.selected;
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: selected.length === 1 ? "Remove member" : "Remove members",
                body: confirmationMessage("remove", selected, "member") + ` They will no longer be able to view this organization. This does not prevent them connecting to running TypeDB Cloud deployments, but they will no longer be accessible via the web UI.`,
                confirmText: selected.length === 1 ? "Remove member" : "Remove members",
                strongConfirmationString: strongConfirmationStringOf(selected),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => combineLatest(selected.map(x => this.orgApi.removeMember({ orgUuid: this.org.uuid, userUuid: x.uuid }))))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Member(s) removed successfully.`);
                this.members.refresh();
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
