/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    DeploymentCtlProtoCreateDeploymentReq, DeploymentCtlProtoDestroyDeploymentReq, DeploymentCtlProtoGetAccessLevelsReq,
    DeploymentCtlProtoGetDeploymentOptionsReq, DeploymentCtlProtoGetDeploymentPriceReq,
    DeploymentCtlProtoGetDeploymentReq, DeploymentCtlProtoGetLatestTypeDBReq, DeploymentCtlProtoListServerLogsReq, DeploymentCtlProtoListServersReq,
    DeploymentCtlProtoReq, DeploymentCtlProtoResumeDeploymentReq, DeploymentCtlProtoSuspendDeploymentReq,
    DeploymentCtlProtoUpdateDeploymentReq, DeploymentCtlProtoUpdateToLatestTypeDBReq,
} from "../../../application/protocol/deployment-controller";
import { ApiResponse, responseOf } from "../../concept/api-response";
import { Ok, PartialWithUuid } from "../../concept/base";
import { Deployment, deploymentProtoOf, partialDeploymentOf, Server, ServerLogBatch, serverLogBatchOf, serverOf } from "../../concept/deployment";
import { DeploymentOptions, deploymentOptionsOf } from "../../concept/deployment-options";
import { AccessLevel, accessLevelOf } from "../../concept/iam";
import { stringToBytes } from "../../util";
import { ApiService } from "../backend/api.service";
import { map, Observable, Subject, takeWhile } from "rxjs";
import { Injectable } from "@angular/core";
import { UnsubListener } from "../backend/platform-api-backend.service";

@Injectable({
    providedIn: "root",
})
export class DeploymentApi {
    constructor(private api: ApiService) {}

    getAccessLevels(deploymentUuids: string[]): Observable<AccessLevel[]> {
        return this.api.deploymentReqRes(new DeploymentCtlProtoReq({ getAccessLevels: new DeploymentCtlProtoGetAccessLevelsReq({ deploymentUuids: deploymentUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    getDeploymentOptions(): Observable<DeploymentOptions> {
        return this.api.deploymentReqRes(new DeploymentCtlProtoReq({ getDeploymentOptions: new DeploymentCtlProtoGetDeploymentOptionsReq() }))
            .pipe(map((res) => deploymentOptionsOf(res.getDeploymentOptions)));
    }

    getLatestTypeDBVersion(): Observable<string> {
        return this.api.deploymentReqRes(new DeploymentCtlProtoReq({ getLatestTypedb: new DeploymentCtlProtoGetLatestTypeDBReq() }))
            .pipe(map((res) => res.getLatestTypedb.typedbVersion));
    }

    createDeployment(deployment: Partial<Deployment>): Observable<Ok> {
        return this.api.deploymentReq(new DeploymentCtlProtoReq({
            createDeployment: new DeploymentCtlProtoCreateDeploymentReq({ deployment: deploymentProtoOf(deployment) })
        }));
    }

    getDeployment(props: { deploymentId: string, projectUuid: string }, unsub$: UnsubListener): Observable<ApiResponse<Deployment>> {
        return this.api.deploymentReqSub(new DeploymentCtlProtoReq(
            { getDeployment: new DeploymentCtlProtoGetDeploymentReq({ deploymentId: props.deploymentId, projectUuid: stringToBytes(props.projectUuid) }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialDeploymentOf(res.data.getDeployment.deployment)))
        );
    }

    updateDeployment(deployment: PartialWithUuid<Deployment>): Observable<Ok> {
        return this.api.deploymentReq(new DeploymentCtlProtoReq(
            { updateDeployment: new DeploymentCtlProtoUpdateDeploymentReq({ deployment: deploymentProtoOf(deployment) }) }
        ));
    }

    updateToLatestTypeDB(deploymentUuid: string): Observable<Ok> {
        return this.api.deploymentReq(new DeploymentCtlProtoReq(
            { updateToLatestTypedb: new DeploymentCtlProtoUpdateToLatestTypeDBReq({ deploymentUuid: stringToBytes(deploymentUuid) }) }
        ));
    }

    suspendDeployment(deploymentUuid: string): Observable<Ok> {
        return this.api.deploymentReq(new DeploymentCtlProtoReq(
            { suspendDeployment: new DeploymentCtlProtoSuspendDeploymentReq({ deploymentUuid: stringToBytes(deploymentUuid) }) }
        ));
    }

    resumeDeployment(deploymentUuid: string): Observable<Ok> {
        return this.api.deploymentReq(new DeploymentCtlProtoReq(
            { resumeDeployment: new DeploymentCtlProtoResumeDeploymentReq({ deploymentUuid: stringToBytes(deploymentUuid) }) }
        ));
    }

    destroyDeployment(deploymentUuid: string): Observable<Ok> {
        return this.api.deploymentReq(new DeploymentCtlProtoReq(
            { destroyDeployment: new DeploymentCtlProtoDestroyDeploymentReq({ deploymentUuid: stringToBytes(deploymentUuid) }) }
        ));
    }

    getDeploymentPriceCentsPerHour(props: { deployment: Partial<Deployment>; orgUuid: string }): Observable<number> {
        return this.api.deploymentReqRes(new DeploymentCtlProtoReq(
            { getDeploymentPrice: new DeploymentCtlProtoGetDeploymentPriceReq({ orgUuid: stringToBytes(props.orgUuid), deployment: deploymentProtoOf(props.deployment) }) }
        )).pipe(map(res => res.getDeploymentPrice.centsPerHour));
    }

    listServers(deploymentUuid: string): Observable<Server[]> {
        return this.api.deploymentReqRes(new DeploymentCtlProtoReq(
            { listServers: new DeploymentCtlProtoListServersReq({ deploymentUuid: stringToBytes(deploymentUuid) }) }
        )).pipe(map(res => res.listServers.servers.map(serverOf)));
    }

    listServerLogs(
        props: { deploymentUuid: string, serverUuid: string, serverId: number, initialLineCount: number },
        unsub$: UnsubListener
    ): Observable<ServerLogBatch> {
        return this.api.deploymentReqSub(new DeploymentCtlProtoReq({
            listServerLogs: new DeploymentCtlProtoListServerLogsReq({
                deploymentUuid: stringToBytes(props.deploymentUuid),
                serverUuid: stringToBytes(props.serverUuid),
                serverIndex: props.serverId,
                initialLineCount: props.initialLineCount
            })
        }), unsub$).pipe(map(res => serverLogBatchOf(res.data.listServerLogs.logBatch)));
    }

    listServerLogsSnapshot(
        props: { deploymentUuid: string, serverUuid: string, serverId: number, maxLines: number }
    ): Observable<ServerLogBatch> {
        return this.api.deploymentReqSub(new DeploymentCtlProtoReq({
            listServerLogs: new DeploymentCtlProtoListServerLogsReq({
                deploymentUuid: stringToBytes(props.deploymentUuid),
                serverUuid: stringToBytes(props.serverUuid),
                serverIndex: props.serverId,
                initialLineCount: props.maxLines,
                snapshot: true,
            })
        }), new Subject<void>()).pipe(
            map(res => serverLogBatchOf(res.data.listServerLogs.logBatch)),
            takeWhile(res => !res.isEndOfFile, true),
        );
    }
}
