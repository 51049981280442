/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { ProviderOptions } from "../../concept/deployment-options";
import { ScopeData } from "./scope-modal/scope-modal.component";

@Pipe({
    name: "providerOptionsScope",
    standalone: true,
})
export class ProviderOptionsScopePipe implements PipeTransform {

    transform(value: ProviderOptions[], allOptions: ProviderOptions[]): { scope: ScopeData, openInPopup: boolean } {
        let simplifiedValidOptions = this.simplifiedOptions(value)
        let simplifiedAllOptions = this.simplifiedOptions(allOptions)
        if (simplifiedAllOptions.every((option) => simplifiedValidOptions.some((validOption) => {
            return validOption.id === option.id && option.machineTypes.every((machineType) => validOption.machineTypes.includes(machineType))
        }))) return { scope: "All Deployments", openInPopup: false }

        let scope = simplifiedAllOptions.map((option) => {
            let validOption = simplifiedValidOptions.find((validOption) => option.id === validOption.id);
            if (!validOption || validOption.machineTypes.length === 0) return { name: option.name, scope: "None" };
            let validMachineTypes = validOption.machineTypes
            if (
                option.machineTypes.every((machineType) => validMachineTypes.includes(machineType))
            ) return { name: validOption.name, scope: "All Machine Types" };
            else return { name: validOption.name, scope: validMachineTypes.join(", ") }
        })
        return { scope, openInPopup: true }
    }

    private simplifiedOptions(options: ProviderOptions[]) {
        return options.map((providerOption) => {
            return {
                id: providerOption.id,
                name: providerOption.name,
                machineTypes: providerOption.regions.flatMap((region) =>
                    region.machineTypes.sort((a, b) => a.cpu - b.cpu)
                        .map(machineType => machineType.vendorId)
                ).filter((elem, index, self) => self.indexOf(elem) === index)
            };
        });
    }
}
