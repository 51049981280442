<mat-form-field>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input #input matInput [formControl]="formControl" [type]="revealed ? 'text' : 'password'">
  <button type="button" tabindex="-1" mat-icon-button matSuffix (click)="revealed = !revealed" aria-label="Hide password" aria-pressed="hide">
    <fa-icon [icon]="revealed ? ['fal', 'eye-slash'] : ['fal', 'eye']"/>
  </button>
  @if (formControl.getError("errorText")) {
    <mat-error>{{ formControl.getError("errorText") }}</mat-error>
  }
</mat-form-field>
