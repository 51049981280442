/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LoginScaffoldComponent } from "../../login/scaffold/login-scaffold.component";
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter, first, map, Observable, of, Subject, switchMap } from 'rxjs';
import { ApplicationState } from "../../../service/application-state.service";
import { Organization } from "../../../concept/organization";
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AsyncPipe } from '@angular/common';
import { FormActionsComponent, FormComponent, requiredValidator, SpinnerComponent } from "typedb-platform-framework";
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { SnackbarService } from "../../../service/snackbar.service";
import { AuthorizationService } from "../../../service/authorization.service";
import { hasAdminAccess } from "../../../concept/iam";
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrgCreationDialogComponent } from "../create/org-creation-dialog.component";
import { MatDialog } from '@angular/material/dialog';
import { Ok } from "../../../concept/base";
import { SubscriptionApi } from "../../../service/subscription/subscription-api.service";

function orgOptionOf(org: Organization, enabled: boolean) {
    return { value: org, viewValue: `${org.id}${enabled ? "" : " (Admin access required)"}`, enabled }
}

@Component({
    selector: 'tp-marketplace-integration',
    standalone: true,
    imports: [
        LoginScaffoldComponent, MatSelectModule, FontAwesomeModule, AsyncPipe, FormComponent,
        FormActionsComponent, ReactiveFormsModule, MatTooltipModule, SpinnerComponent
    ],
    templateUrl: './marketplace-integration.component.html',
    styleUrl: './marketplace-integration.component.scss',
})
export class MarketplaceIntegrationComponent implements OnInit {
    @ViewChild(MatSelect) orgSelector!: MatSelect;
    @Input({ required: true }) providerName?: string;
    @Input({ required: true }) isSubmitting$!: Subject<boolean>;
    @Output() setAccount = new EventEmitter<{ org: Organization, token: string }>();

    constructor(
        public app: ApplicationState, private route: ActivatedRoute,
        private formBuilder: FormBuilder, private snackbar: SnackbarService,
        private authorization: AuthorizationService, private dialog: MatDialog
    ) { }

    buttonIdPrefix = `${this.providerName?.toLowerCase()}MarketplaceIntegration`
    private token?: string

    getAccessLevels(orgs: Organization[]) {
        return this.authorization.orgAccessLevels(orgs.map(x => x.uuid))
    }

    readonly orgOptions$ = this.app.currentUserOrgs$.pipe(
        filter(orgs => !!orgs), map(orgs => orgs!),
        switchMap(orgs => combineLatest([of(orgs), this.getAccessLevels(orgs)])),
        map(([orgs, accessLevels]) =>
            orgs.map((x, idx) => orgOptionOf(x, hasAdminAccess(accessLevels[idx])))
        )
    )
    readonly form = this.formBuilder.group({
        org: [null as Organization | null, [requiredValidator]]
    })

    ngOnInit() {
        this.route.queryParamMap
            .pipe(filter(queryParams => queryParams.has("token")))
            .subscribe(queryParams => { this.token = decodeURIComponent(queryParams.get("token")!) })
        this.orgOptions$.pipe(first()).subscribe((orgs) => {
            this.form.patchValue({ org: orgs[0].value })
        })
    }

    onCreateOrgClick() {
        this.orgSelector.close();
        this.dialog.open(OrgCreationDialogComponent)
            .componentInstance.redirectAfterClose = false;
    }

    onSubmitConnectOrganizationForm() {
        if (!this.token) {
            this.isSubmitting$.next(false);
            this.snackbar.errorPersistent("No token found in URL parameter");
            return;
        }
        const form = this.form.value;
        const org = form.org!;
        this.setAccount.emit({ org, token: this.token })
    }
}
