<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    @if (breadcrumb$ | async; as breadcrumb) {
      <tp-breadcrumb [items]="breadcrumb.items" [url]="breadcrumb.url"/>
    }
    <div class="flex-spacer"></div>
    <tp-button
      [matTooltip]="(cannotSuspendReason$ | async) || ''" buttonStyle="secondary" buttonId="suspendDeployment"
      [enabled]="(suspendEnabled$ | async) || false" (click)="openSuspendResumeModal('suspend')"
    >
      <fa-icon [icon]="['fal', 'circle-pause']"/> Suspend
    </tp-button>
    <tp-button
      [matTooltip]="(cannotResumeReason$ | async) || ''" buttonStyle="secondary" buttonId="resumeDeployment"
      [enabled]="(resumeEnabled$ | async) || false" (click)="openSuspendResumeModal('resume')"
    >
      <fa-icon [icon]="['fal', 'circle-play']"/> Resume
    </tp-button>
  </div>
  @if (deployment$ | async; as deployment) {
    <article>
      <section>
        <div class="section-title-row">
          <h2>{{ deployment.id }}</h2>
          <tp-button
            (click)="openEditModal()" buttonStyle="secondary stroke" [height]="40"
            [enabled]="(hasWriteAccess$ | async) || false" buttonId="editDeployment"
          >
            <fa-icon [icon]="['fal', 'pen-to-square']"/> Edit
          </tp-button>
        </div>

        <div class="getting-started-info">
          <tp-link-panels style="margin-top: 16px;">
            <tp-link-panel title="Quickstart" iconURL="https://cdn.sanity.io/images/xndl14mc/production/95922b6b30dd0c362db959cfe778199ce783637c-99x107.svg" href="https://typedb.com/docs/home/quickstart">
              <p>
                Using the server address(es) in the "Servers" section below, connect, set up a database and run queries.
              </p>
            </tp-link-panel>
            <tp-link-panel title="Crash Course" iconURL="https://cdn.sanity.io/images/xndl14mc/production/5cc35cf9f1d71af32a5d65426f2a6409cb0f72da-89x98.svg" href="https://typedb.com/docs/home/crash-course">
              <p>
                Learn how to query TypeDB by taking the <u>TypeDB Crash Course</u>.
              </p>
            </tp-link-panel>
            <tp-link-panel title="Set Admin Password" iconURL="https://cdn.sanity.io/images/xndl14mc/production/46340fae32ca7c2a378c59d940747ae92f8430d3-110x80.svg" href="https://typedb.com/docs/manual/configuring/users">
              <p>
                Connect using TypeDB Studio, and you'll be prompted to set the admin password.
                Or set it in TypeDB Console.
              </p>
            </tp-link-panel>
          </tp-link-panels>
        </div>
      </section>

      <section>
        <h3 class="section-title-row">Servers</h3>

        @if (serversReady$ | async) {
          <table mat-table [dataSource]="servers$">
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
              <td mat-cell *matCellDef="let server"><tp-table-copyable-text-cell [text]="server.address || 'Loading...'"/></td>
            </ng-container>
            <ng-container matColumnDef="serverStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let server">{{ server.status | serverStatus }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let server"><tp-table-status-cell [status]="serverIcon(server)"/></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="serverColumns"></tr>
            <tr mat-row class="table-row-clickable" *matRowDef="let server; columns: serverColumns" (click)="handleServerTableRowClick(server)"></tr>
          </table>
        } @else {
          <tp-spinner/>
        }
      </section>

      <section>
        <tp-properties-table title="Deployment Details">
          <tp-properties-table-row key="Deployment ID">{{ deployment.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Project">
            <p><a [routerLink]="deployment.project | projectDetailsPath">{{ deployment.project.id }}</a></p>
          </tp-properties-table-row>
          @if (runtime$ | async; as runtime) {
            <tp-properties-table-row key="Availability" [loading]="runtime.availableServerCount < runtime.totalServers">
              {{ availabilityText$ | async }}
            </tp-properties-table-row>
          } @else {
            <tp-properties-table-row key="Availability" [loading]="true"></tp-properties-table-row>
          }
          <tp-properties-table-row key="Provider">{{ deployment.provider.name }}</tp-properties-table-row>
          <tp-properties-table-row key="Region">{{ deployment.region | region:"long" }}</tp-properties-table-row>
          <tp-properties-table-row key="Machine Type">{{ deployment.machineType | machineType }}</tp-properties-table-row>
          <tp-properties-table-row key="Storage Capacity">{{ deployment.storageGB | storageSize }}</tp-properties-table-row>
          <tp-properties-table-row key="Storage Type">{{ deployment.storageType | storageType }}</tp-properties-table-row>
          <tp-properties-table-row key="TypeDB Version">
            {{ deployment.typeDBVersion }}
            @if (typeDBUpgradeAvailable$ | async) {
              <div class="flex-spacer"></div>
              <tp-button
                buttonStyle="primary-outline green stroke" [height]="32" [enabled]="true"
                buttonId="updateToLatestTypeDB" (click)="openUpdateTypeDBModal()"
              >
                Update to {{ latestTypeDBVersion$ | async }}
              </tp-button>
            }
          </tp-properties-table-row>
          <tp-properties-table-row key="Status">{{ deployment.status | deploymentStatus }}</tp-properties-table-row>
          <tp-properties-table-row key="Creation Date">{{ deployment.createdAt | datetime }}</tp-properties-table-row>
        </tp-properties-table>
      </section>

      <tp-delete-resource-section
        title="Destroy this deployment"
        [display]="{
            enabled: (destroyEnabled$ | async) || false,
            enabledText: 'Once you destroy a deployment, all of its data will be permanently erased. Please be certain.',
            disabledText: (cannotDestroyReason$ | async) || ''
        }"
        [buttonIcon]="['fal', 'trash-can-xmark']"
        buttonText="Destroy" (buttonPressed)="openDestroyModal()"
      />
    </article>
  }
</tp-page-scaffold>
