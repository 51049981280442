/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { from, map } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
    selector: "tp-user-avatar",
    template: "@if (avatarUrl) {<img [src]='avatarUrl' alt=''/>}",
    styleUrls: ["./user-avatar.component.scss"],
    standalone: true,
    imports: [AsyncPipe],
})
export class UserAvatarComponent implements OnChanges {
    @Input({ required: true }) userEmail?: string | null; // passing userEmail, not user, so OnPush change detection works
    avatarUrl?: SafeUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.onEmailChanged(this.userEmail);
    }

    ngOnChanges(changes: SimpleChanges) {
        const email = changes["userEmail"]?.currentValue as string | null | undefined;
        this.onEmailChanged(email);
    }

    private onEmailChanged(email: string | null | undefined) {
        if (!email) {
            this.avatarUrl = this.sanitizer.bypassSecurityTrustUrl("https://www.gravatar.com/avatar?d=mp&s=32");
            return;
        }
        this.sha256(email.trim().toLowerCase()).subscribe(emailHash => {
            this.avatarUrl = this.sanitizer.bypassSecurityTrustUrl(this.getGravatarUrl(emailHash, 32));
        });
    }

    private getGravatarUrl(emailHash: string, size: number): string {
        return `https://gravatar.com/avatar/${emailHash}?d=mp&s=${size}&r=g`;
    }

    // source: https://gist.github.com/technikhil314/40f3c41e1039f4c7964843e47a9f25fc
    private sha256(value: string) {
        const bufSrc = new TextEncoder().encode(value);
        return from(crypto.subtle.digest("SHA-256", bufSrc)).pipe(map(buf => {
            return Array.from(new Uint8Array(buf)).map(x => x.toString(16).padStart(2, "0")).join("");
        }));
    }
}
