<tp-login-scaffold [showPromotionalText]="true">
  @if (selectedTab$ | async; as selectedTab) {
    <div>
      <div class="sign-tab-container">
        <div class="sign-tab-item sign-in-tab" [class.sign-tab-item-active]="selectedTab === 'sign-in'">
          <a routerLink="/sign-in" id="signInTab">Sign In</a>
        </div>
        <div class="sign-tab-item sign-up-tab" [class.sign-tab-item-active]="selectedTab === 'sign-up'">
          <a routerLink="/sign-up" id="signUpTab">Sign Up</a>
        </div>
      </div>
      <div class="form-container with-sign-tabs">
        @switch (selectedTab) {
          @case ("sign-in") {
            <tp-form [formGroup]="signInForm" [isSubmitting$]="isSubmitting$" (doSubmit)="onSubmitSignInForm()">
              <tp-form-input type="email" autocomplete="username" [form]="signInForm" label="Email" field="email"/>
              <tp-form-password-input autocomplete="current-password" [form]="signInForm" label="Password" field="password"/>
              <tp-form-actions [isSubmitting]="isSubmitting$ | async" submitText="Sign in" buttonIdPrefix="signIn"/>
            </tp-form>

            <a routerLink="/forgot-password">
              <tp-button style="margin-top: 24px" type="button" [height]="20" buttonStyle="secondary" buttonId="forgotPassword">Forgot Password</tp-button>
            </a>
          }
          @case ("sign-up") {
            <tp-form [formGroup]="signUpForm" [isSubmitting$]="isSubmitting$" (doSubmit)="onSubmitSignUpForm()">
              <tp-form-input type="email" autocomplete="username" [form]="signUpForm" label="Email" field="email"/>
              <tp-form-password-input autocomplete="new-password" [form]="signUpForm" label="Password" field="password"/>

              <mat-error>{{ signUpForm.getError("errorText") }}</mat-error>

              <p class="terms-privacy-additional-info">
                By signing up for TypeDB Cloud, you are accepting the
                <a href="https://typedb.com/legal/terms-of-service" target="_blank" rel="noopener">Terms of Service</a>
                and the
                <a href="https://typedb.com/legal/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>.
              </p>

              <tp-form-actions [isSubmitting]="isSubmitting$ | async" submitText="Sign up" buttonIdPrefix="signUp"/>
            </tp-form>
          }
        }
      </div>
      <tp-button (click)="googleSignIn()" class="sso-button" buttonId="googleSignIn"><fa-icon [icon]="['fab', 'google']"/>Sign in with Google</tp-button>
      <tp-button (click)="microsoftSignIn()" class="sso-button" buttonId="microsoftSignIn"><fa-icon [icon]="['fab', 'microsoft']"/>Sign in with Microsoft</tp-button>
      <tp-button (click)="githubSignIn()" class="sso-button" buttonId="githubSignIn"><fa-icon [icon]="['fab', 'github']"/>Sign in with Github</tp-button>
    </div>
  }
</tp-login-scaffold>
