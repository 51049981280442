/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

export const LOGOUT_REASON = "logout_reason";
export const PROJECT_ID = "project_id";
export const RETURN_TO = "return_to";
export const EMAIL = "email";
