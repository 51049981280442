/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, ViewChild } from "@angular/core";
import { BannerComponent } from "../banner/banner.component";
import { RightSidebarComponent } from "../sidebar/right/right-sidebar.component";
import { SidebarComponent } from "../sidebar/sidebar.component";
import { TopbarComponent } from "../topbar/topbar.component";
import { RouterOutlet } from "@angular/router";
import { AsyncPipe } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SpinnerComponent } from "typedb-platform-framework";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";

@Component({
    selector: "tp-org-subsite-scaffold", // eslint-disable-line @angular-eslint/component-selector
    templateUrl: "./org-subsite-scaffold.component.html",
    styleUrls: ["org-subsite-scaffold.component.scss"],
    standalone: true,
    imports: [
        RouterOutlet, SpinnerComponent, AsyncPipe, BannerComponent, TopbarComponent, MatToolbarModule, SidebarComponent,
        MatSidenavModule, RightSidebarComponent,
    ],
})
export class OrgSubsiteScaffoldComponent {
    @ViewChild(MatSidenav) rightSidenav!: MatSidenav;

    toggleRightSidenav() {
        this.rightSidenav.toggle(undefined, "mouse");
    }
}
