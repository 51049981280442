@if (table.ready$ | async) {
  <table mat-table [dataSource]="table.items$">
    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef>{{ table.columns.period.name }}</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.startTime | datetime }}</td>
    </ng-container>
    <ng-container matColumnDef="totalCost">
      <th mat-header-cell *matHeaderCellDef>{{ table.columns.totalCost.name }}</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.total | currency }}</td>
    </ng-container>
    <ng-container matColumnDef="invoiceStatus">
      <th mat-header-cell *matHeaderCellDef>{{ table.columns.invoiceStatus.name }}</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice | invoiceStatus }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
    <tr mat-row *matRowDef="let invoice; columns: table.displayedColumns"></tr>
  </table>
} @else {
  <tp-spinner/>
}
