/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Parameters } from "../../concept/common";
import { Organization } from "../../concept/organization";
import { Project, ProjectColumn } from "../../concept/project";
import { Team, TeamColumn } from "../../concept/team";
import { User, UserColumn } from "../../concept/user";
import { AuthorizationService } from "../authorization.service";
import { OrgApi } from "../org/org-api.service";
import { OrgProjectsTable, OrgMembersTable } from "../org/org-controller.service";
import { ResourceTable, SpecialColumn } from "../resource-table.service";
import { Observable, Subject } from "rxjs";
import { ApiListResponse } from "../../concept/api-response";
import { TeamApi } from "./team-api.service";
import { ApplicationState } from "../application-state.service";

@Injectable({
    providedIn: "root",
})
export class TeamController {

    constructor(private api: TeamApi, private orgApi: OrgApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    membersTable(team: Team, unsub$: Subject<void>): ResourceTable<User, UserColumn> {
        return new TeamMembersTable(this.app.requireCurrentOrg(), this.orgApi, team, this.api, this.authorization, unsub$);
    }

    projectsTable(team: Team, unsub$: Subject<void>): ResourceTable<Team, TeamColumn> {
        return new TeamProjectsTable(this.app.requireCurrentOrg(), this.orgApi, team, this.api, this.authorization, unsub$);
    }
}

class TeamMembersTable extends OrgMembersTable {
    constructor(org: Organization, orgApi: OrgApi, private team: Team, private teamApi: TeamApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, orgApi, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<User>> {
        return this.teamApi.listMembers(this.team.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (UserColumn | SpecialColumn)[] {
        return ["avatar", ...this.displayedProperties.map(x => x.id as UserColumn), "actions"];
    }
}

class TeamProjectsTable extends OrgProjectsTable {
    constructor(org: Organization, orgApi: OrgApi, private team: Team, private teamApi: TeamApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, orgApi, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Project>> {
        return this.teamApi.listProjects(this.team.uuid, params, this.unsub$);
    }

    override get displayedColumns(): ProjectColumn[] {
        return [...this.displayedProperties.map(x => x.id as ProjectColumn)];
    }
}
