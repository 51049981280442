/*!
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

import { generateTopbar, setupLinks, setupTopbarListeners } from "typedb-web-common/lib";
import { SanityService } from "../../../../service/sanity.service";

@Component({
    selector: "td-topbar",
    template: ``,
    styleUrls: ["./topbar-menu.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class TopbarMenuComponent implements OnInit {
    constructor(private sanity: SanityService, private elementRef: ElementRef<HTMLElement>, private router: Router) {}

    ngOnInit() {
        this.sanity.siteTopbar.subscribe((data) => {
            this.elementRef.nativeElement.innerHTML = generateTopbar(data, "https://typedb.com");
            const headerEl = setupTopbarListeners();

            if (headerEl) {
                setupLinks(headerEl, this.router);
            }
        });
    }
}
