<tp-modal dialogTitle="Download Server Logs" [isBusy]="isSubmitting$ | async">
  <p>Select the number of log lines to fetch, then click "Download".</p>
  <p>Depending on the size of the log, this may take some time to complete.</p>
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <tp-form-input type="number" [form]="form" field="limit" label="Maximum Lines" />
    <tp-form-actions
      [cancellable]="true" submitText="Download" [isSubmitting]="isSubmitting$ | async"
      buttonIdPrefix="downloadServerLogsModal" (cancel)="close()"
    />
  </tp-form>
</tp-modal>
