/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
    SubscriptionCtlProtoStartAddCreditCardReq, SubscriptionCtlProtoGetUpcomingInvoiceReq,
    SubscriptionCtlProtoListCreditCardsReq, SubscriptionCtlProtoListPreviousInvoicesReq,
    SubscriptionCtlProtoRemoveCreditCardReq, SubscriptionCtlProtoReq,
    SubscriptionCtlProtoSetDefaultCreditCardReq, SubscriptionCtlProtoGetAccessLevelsReq,
    SubscriptionCtlProtoSetGCPAccountReq, SubscriptionCtlProtoGetMarketplaceAccountsReq,
    SubscriptionCtlProtoGetMarketplaceAddressesReq, SubscriptionCtlProtoSetAzureAccountReq,
    SubscriptionCtlProtoListCreditsReq, SubscriptionCtlProtoListDiscountsReq, SubscriptionCtlProtoSetAWSAccountReq
} from "../../../application/protocol/subscription-controller";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { ok, Ok } from "../../concept/base";
import { AccessLevel, accessLevelOf } from "../../concept/iam";
import {
    AddCreditCardResponse,
    CreditCard, creditCardOf,
    Credits, creditsOf,
    Discount, discountOf,
    MarketplaceAddresses,
    GCPAccount, gcpAccountOf,
    AzureAccount, azureAccountOf,
    AWSAccount, awsAccountOf,
    Invoice, partialInvoiceOf,
} from "../../concept/subscription";
import { stringToBytes } from "../../util";
import { ApiService } from "../backend/api.service";
import { UnsubListener } from "../backend/platform-api-backend.service";

@Injectable({
    providedIn: "root",
})
export class SubscriptionApi {
    constructor(private api: ApiService) {}

    getAccessLevels(orgUuids: string[]): Observable<AccessLevel[]> {
        return this.api.subscriptionReqRes(new SubscriptionCtlProtoReq({ getAccessLevels: new SubscriptionCtlProtoGetAccessLevelsReq({ orgUuids: orgUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    startAddCreditCard(orgUuid: string): Observable<AddCreditCardResponse> {
        return this.api.subscriptionReqRes(new SubscriptionCtlProtoReq({
            startAddCreditCard: new SubscriptionCtlProtoStartAddCreditCardReq({ orgUuid: stringToBytes(orgUuid) })
        })).pipe(map((res) => ({ token: res.startAddCreditCard.token, apiKey: res.startAddCreditCard.apiKey })));
    }

    listCreditCards(orgUuid: string): Observable<CreditCard[]> {
        return this.api.subscriptionReqRes(new SubscriptionCtlProtoReq({
            listCreditCards: new SubscriptionCtlProtoListCreditCardsReq({
                orgUuid: stringToBytes(orgUuid)
            })
        })).pipe(map((res) => res.listCreditCards.creditCards.map(creditCardOf)));
    }

    removeCreditCard(creditCardId: string): Observable<Ok> {
        return this.api.subscriptionReq(new SubscriptionCtlProtoReq({
            removeCreditCard: new SubscriptionCtlProtoRemoveCreditCardReq({
                creditCardId: creditCardId,
            })
        }));
    }

    setDefaultCreditCard(orgUuid: string, creditCardId: string): Observable<Ok> {
        return this.api.subscriptionReq(new SubscriptionCtlProtoReq({
            setDefaultCreditCard: new SubscriptionCtlProtoSetDefaultCreditCardReq({
                orgUuid: stringToBytes(orgUuid),
                creditCardId,
            })
        }));
    }

    setGCPAccount(orgUuid: string, jwt: string): Observable<Ok> {
        return this.api.subscriptionReq(new SubscriptionCtlProtoReq({
            setGcpAccount: new SubscriptionCtlProtoSetGCPAccountReq({
                orgUuid: stringToBytes(orgUuid),
                jwt
            })
        }));
    }

    setAzureAccount(orgUuid: string, token: string): Observable<Ok> {
        return this.api.subscriptionReq(new SubscriptionCtlProtoReq({
            setAzureAccount: new SubscriptionCtlProtoSetAzureAccountReq({
                orgUuid: stringToBytes(orgUuid),
                token
            })
        }));
    }

    setAWSAccount(orgUuid: string, token: string): Observable<Ok> {
        return this.api.subscriptionReq(new SubscriptionCtlProtoReq({
            setAwsAccount: new SubscriptionCtlProtoSetAWSAccountReq({
                orgUuid: stringToBytes(orgUuid),
                token
            })
        }));
    }

    getMarketplaceAccounts(orgUuid: string): Observable<{ gcp: GCPAccount | null, azure: AzureAccount | null, aws: AWSAccount | null }> {
        return this.api.subscriptionReqRes(new SubscriptionCtlProtoReq({
            getMarketplaceAccounts: new SubscriptionCtlProtoGetMarketplaceAccountsReq({
                orgUuid: stringToBytes(orgUuid)
            })
        })).pipe(map((res) => {
            return {
                gcp: res.getMarketplaceAccounts.hasGcpAccount ? gcpAccountOf(res.getMarketplaceAccounts.gcpAccount) : null,
                azure: res.getMarketplaceAccounts.hasAzureAccount ? azureAccountOf(res.getMarketplaceAccounts.azureAccount) : null,
                aws: res.getMarketplaceAccounts.hasAwsAccount ? awsAccountOf(res.getMarketplaceAccounts.awsAccount) : null,
            }
        }))
    }

    listCredits(orgUuid: string, unsub$: UnsubListener): Observable<ApiListResponse<Credits>> {
        return this.api.subscriptionReqSub(new SubscriptionCtlProtoReq({
            listCredits: new SubscriptionCtlProtoListCreditsReq({
                orgUuid: stringToBytes(orgUuid)
            })
        }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listCredits.credits.map(creditsOf))));
    }

    listDiscounts(orgUuid: string, unsub$: UnsubListener): Observable<ApiListResponse<Discount>> {
        return this.api.subscriptionReqSub(new SubscriptionCtlProtoReq({
            listDiscounts: new SubscriptionCtlProtoListDiscountsReq({
                orgUuid: stringToBytes(orgUuid)
            })
        }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listDiscounts.discounts.map(discountOf))));
    }

    getUpcomingInvoice(orgUuid: string, unsub$: UnsubListener): Observable<ApiResponse<Invoice> | null> {
        return this.api.subscriptionReqSub(new SubscriptionCtlProtoReq({
            getUpcomingInvoice: new SubscriptionCtlProtoGetUpcomingInvoiceReq({
                orgUuid: stringToBytes(orgUuid)
            })
        }), unsub$).pipe(map((res) => {
            return res.data.getUpcomingInvoice.hasInvoice
                ? responseOf(res.type, partialInvoiceOf(res.data.getUpcomingInvoice.invoice))
                : null;
        }));
    }

    listPreviousInvoices(orgUuid: string, unsub$: UnsubListener): Observable<ApiListResponse<Invoice>> {
        return this.api.subscriptionReqSub(new SubscriptionCtlProtoReq({
            listPreviousInvoices: new SubscriptionCtlProtoListPreviousInvoicesReq({
                orgUuid: stringToBytes(orgUuid)
            })
        }), unsub$)
            .pipe(map((res) => responseListOf(res.type, res.data.listPreviousInvoices.invoices.map(partialInvoiceOf))));
    }

    getMarketplaceAddresses(): Observable<MarketplaceAddresses> {
        return this.api.subscriptionReqRes(new SubscriptionCtlProtoReq({
            getMarketplaceAddresses: new SubscriptionCtlProtoGetMarketplaceAddressesReq({})
        })).pipe(map((res) => res.getMarketplaceAddresses))
    }
}
