<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    @if (breadcrumb$ | async; as breadcrumb) {
      <tp-breadcrumb [items]="breadcrumb.items" [url]="breadcrumb.url"/>
    }
    <div class="flex-spacer"></div>
  </div>
  <article>
    <section>
      @if (server$ | async; as server) {
        <div class="section-title-row">
          @if (deployment$ | async; as deployment) {
            <h2>Server {{ server.id }} ({{ deployment.id }})</h2>
          }
        </div>
        <tp-properties-table title="Server Details">
          <tp-properties-table-row key="Server ID">{{ server.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Address" class="tp-address">
            {{ server.address }}
            <button class="btn-copy" mat-icon-button aria-label="copy" (click)="copyServerAddress(); $event.stopPropagation();" matTooltip="Copy to clipboard">
              <fa-icon [icon]="['fal', 'copy']"/>
            </button>
          </tp-properties-table-row>
          <tp-properties-table-row key="Status">{{ server.status | serverStatus }}</tp-properties-table-row>
        </tp-properties-table>
      } @else {
        <tp-spinner/>
      }
    </section>

    <section>
      <div style="display: flex; flex-direction: column">
        <div class="section-title-row logs-header">
          <h3>Logs</h3>
          <div class="flex-spacer"></div>
          <tp-button [height]="40" buttonStyle="secondary stroke" [enabled]="true" buttonId="toggleLogStream" (click)="toggleLogStream()">
            @if (toggleStreamingButtonIcon$ | async; as icon) {<fa-icon [icon]="icon"/>}{{ toggleStreamingButtonText$ | async }}
          </tp-button>
          <tp-button [height]="40" buttonStyle="secondary stroke" [enabled]="true" buttonId="restartLogStream" (click)="restartLogStream()">
            <fa-icon [icon]="['fal', 'arrows-rotate']"/> Restart
          </tp-button>
          <tp-button [height]="40" buttonStyle="secondary stroke" [enabled]="true" buttonId="downloadLogs" (click)="openDownloadLogsDialog()">
            <fa-icon [icon]="['fal', 'download']"/> Download
          </tp-button>
        </div>

        @if (logs$ | async; as logs) {
          <div class="log-viewer-container">
<pre #logViewer>
<code>@for (log of logs; track log) {<span>{{ log }}</span>
}
</code>
</pre>
            <aside class="logs-status"><fa-icon [class]="streamingStatusIconClass$ | async" [icon]="['fas', 'circle']"/> {{ streamingStatusText$ | async }}</aside>
          </div>
        } @else {
          <tp-spinner/>
        }
      </div>
    </section>
  </article>
</tp-page-scaffold>
