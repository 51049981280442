<p>{{ question.spec.body }}</p>
<mat-chip-listbox [multiple]="question.spec.isMultiSelect">
  @for (option of question.spec.options; track option) {
    <mat-chip-option (selectionChange)="onOptionSelectedChange(option, $event)">{{ option.text }}</mat-chip-option>
  }
  @if (question.spec.hasOpenEndedOption) {
    <mat-form-field class="form-field-dense" [ngClass]="{ 'tp-selected': otherOptionSelected }">
      <input matInput [(ngModel)]="otherValueRaw" (blur)="onOtherFieldBlur()" placeholder="Other">
      @if (otherOptionSelected) {<mat-icon matPrefix>check</mat-icon>}
    </mat-form-field>
  }
</mat-chip-listbox>
