/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { generateBanner, setupLinks } from "typedb-web-common/lib";
import { SanityService } from "../../../service/sanity.service";

@Component({
    selector: "tp-banner",
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class BannerComponent implements OnInit {
    constructor(private sanity: SanityService, private elementRef: ElementRef<HTMLElement>, private router: Router) {}

    ngOnInit() {
        this.sanity.appBanner.subscribe((data) => {
            this.elementRef.nativeElement.innerHTML = generateBanner(data, "");
            const el = this.elementRef.nativeElement;
            setupLinks(el, this.router);
        });
    }
}
