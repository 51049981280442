<a
  class="integration-card integration-card-gcp"
  [ngClass]="{'integration-card-active': chip == 'active', 'integration-card-coming-soon' : chip == 'coming-soon', 'disabled': !enabled}"
  [attr.href]="link" target="_blank" (click)="onLinkClick()"
>
  @if (imageIcon) {
    <img [src]="imageIcon.src" [alt]="imageIcon.alt" />
  } @else if (faIcon) {
    <fa-icon [icon]="faIcon"/>
  }
  <div class="integration-info">
    <h3>{{ title }}</h3>
    <ng-content/>
    <p class="integration-link" [style.visibility]="enabled ? 'visible' : 'hidden'">
      @if (buttonLinkText) {
        {{ buttonLinkText }}
      }
    </p>
  </div>
</a>
