/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, Input } from "@angular/core";
import { SpinnerComponent } from "typedb-platform-framework";
import { RouterLink } from "@angular/router";
import { HeadingWithHighlightsComponent, ParagraphWithHighlightsComponent } from "../../../framework/text/text-with-highlights.component";
import { SanityService } from "../../../service/sanity.service";
import { TopbarMenuComponent } from "./topbar/topbar-menu.component";
import { AsyncPipe } from "@angular/common";

@Component({
    selector: "tp-login-scaffold",
    templateUrl: "./login-scaffold.component.html",
    styleUrls: ["./login-scaffold.component.scss"],
    standalone: true,
    imports: [
        SpinnerComponent, RouterLink, TopbarMenuComponent, HeadingWithHighlightsComponent,
        ParagraphWithHighlightsComponent, AsyncPipe
    ],
})
export class LoginScaffoldComponent {
    @Input() loading = false;
    @Input() showPromotionalText = false;

    constructor(private sanity: SanityService) {
    }

    get landingPage$() {
        return this.sanity.landingPage;
    }
}
