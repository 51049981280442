/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { renderCentsAsUSD } from "../../util";

@Pipe({
    name: "currency",
    standalone: true,
})
export class CurrencyPipe implements PipeTransform {
    transform(cents: number): string {
        return renderCentsAsUSD(cents);
    }
}
