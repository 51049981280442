<tp-login-scaffold>
  <section class="page-not-found-section">
    <div class="light-banner">404</div>
    <div class="light-banner-subtitle">Page not found</div>
    <p>We're sorry, the page you requested could not be found.</p>
    <br/>
    <a routerLink="/">
      <tp-button buttonStyle="primary-outline green stroke" buttonId="goHome">Go to home page</tp-button>
    </a>
  </section>
</tp-login-scaffold>
