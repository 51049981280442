<h3 class="deletion">{{ title }}</h3>

<div class="dr-body">
  <p class="text deletion">{{ bodyText }}</p>
  <tp-button
    buttonStyle="primary-outline red stroke" [height]="40" (click)="onButtonClick()" [enabled]="isEnabled()"
    [matTooltip]="isEnabled() ? '' : bodyText" buttonId="deleteResource"
  >
    @if (buttonIcon) {<fa-icon [icon]="buttonIcon"/>}
    {{ buttonText }}
  </tp-button>
</div>
