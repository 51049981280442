/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/application.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/authentication-controller";
import * as dependency_2 from "./../../protocol/common";
import * as dependency_3 from "./deployment-controller";
import * as dependency_4 from "./organization-controller";
import * as dependency_5 from "./project-controller";
import * as dependency_6 from "./subscription-controller";
import * as dependency_7 from "./team-controller";
import * as dependency_8 from "./user-controller";
import * as pb_1 from "google-protobuf";
export class ApplicationProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ApplicationProto {
        const message = new ApplicationProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProto {
        return ApplicationProto.deserialize(bytes);
    }
}
export class ApplicationProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[4, 5]];
    constructor(data?: any[] | ({
        uuid?: Uint8Array;
    } & (({
        public?: ApplicationProtoPublicReq;
        private?: never;
    } | {
        public?: never;
        private?: ApplicationProtoPrivateReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("public" in data && data.public != undefined) {
                this.public = data.public;
            }
            if ("private" in data && data.private != undefined) {
                this.private = data.private;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get public() {
        return pb_1.Message.getWrapperField(this, ApplicationProtoPublicReq, 4) as ApplicationProtoPublicReq;
    }
    set public(value: ApplicationProtoPublicReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasPublic() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get private() {
        return pb_1.Message.getWrapperField(this, ApplicationProtoPrivateReq, 5) as ApplicationProtoPrivateReq;
    }
    set private(value: ApplicationProtoPrivateReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasPrivate() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "public" | "private";
        } = {
            0: "none",
            4: "public",
            5: "private"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        public?: ReturnType<typeof ApplicationProtoPublicReq.prototype.toObject>;
        private?: ReturnType<typeof ApplicationProtoPrivateReq.prototype.toObject>;
    }): ApplicationProtoReq {
        const message = new ApplicationProtoReq({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.public != null) {
            message.public = ApplicationProtoPublicReq.fromObject(data.public);
        }
        if (data.private != null) {
            message.private = ApplicationProtoPrivateReq.fromObject(data.private);
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            public?: ReturnType<typeof ApplicationProtoPublicReq.prototype.toObject>;
            private?: ReturnType<typeof ApplicationProtoPrivateReq.prototype.toObject>;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.public != null) {
            data.public = this.public.toObject();
        }
        if (this.private != null) {
            data.private = this.private.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.uuid.length)
            writer.writeBytes(1, this.uuid);
        if (this.hasPublic)
            writer.writeMessage(4, this.public, () => this.public.serialize(writer));
        if (this.hasPrivate)
            writer.writeMessage(5, this.private, () => this.private.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 4:
                    reader.readMessage(message.public, () => message.public = ApplicationProtoPublicReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.private, () => message.private = ApplicationProtoPrivateReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoReq {
        return ApplicationProtoReq.deserialize(bytes);
    }
}
export class ApplicationProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3], [4, 5]];
    constructor(data?: any[] | ({
        uuid?: Uint8Array;
    } & (({
        success?: dependency_2.StatusProtoSuccess;
        error?: never;
    } | {
        success?: never;
        error?: dependency_2.StatusProtoError;
    }) | ({
        public?: ApplicationProtoPublicRes;
        private?: never;
    } | {
        public?: never;
        private?: ApplicationProtoPrivateRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uuid" in data && data.uuid != undefined) {
                this.uuid = data.uuid;
            }
            if ("success" in data && data.success != undefined) {
                this.success = data.success;
            }
            if ("error" in data && data.error != undefined) {
                this.error = data.error;
            }
            if ("public" in data && data.public != undefined) {
                this.public = data.public;
            }
            if ("private" in data && data.private != undefined) {
                this.private = data.private;
            }
        }
    }
    get uuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set uuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get success() {
        return pb_1.Message.getWrapperField(this, dependency_2.StatusProtoSuccess, 2) as dependency_2.StatusProtoSuccess;
    }
    set success(value: dependency_2.StatusProtoSuccess) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasSuccess() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get error() {
        return pb_1.Message.getWrapperField(this, dependency_2.StatusProtoError, 3) as dependency_2.StatusProtoError;
    }
    set error(value: dependency_2.StatusProtoError) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasError() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get public() {
        return pb_1.Message.getWrapperField(this, ApplicationProtoPublicRes, 4) as ApplicationProtoPublicRes;
    }
    set public(value: ApplicationProtoPublicRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[1], value);
    }
    get hasPublic() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get private() {
        return pb_1.Message.getWrapperField(this, ApplicationProtoPrivateRes, 5) as ApplicationProtoPrivateRes;
    }
    set private(value: ApplicationProtoPrivateRes) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[1], value);
    }
    get hasPrivate() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get status() {
        const cases: {
            [index: number]: "none" | "success" | "error";
        } = {
            0: "none",
            2: "success",
            3: "error"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3])];
    }
    get res() {
        const cases: {
            [index: number]: "none" | "public" | "private";
        } = {
            0: "none",
            4: "public",
            5: "private"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5])];
    }
    static fromObject(data: {
        uuid?: Uint8Array;
        success?: ReturnType<typeof dependency_2.StatusProtoSuccess.prototype.toObject>;
        error?: ReturnType<typeof dependency_2.StatusProtoError.prototype.toObject>;
        public?: ReturnType<typeof ApplicationProtoPublicRes.prototype.toObject>;
        private?: ReturnType<typeof ApplicationProtoPrivateRes.prototype.toObject>;
    }): ApplicationProtoRes {
        const message = new ApplicationProtoRes({});
        if (data.uuid != null) {
            message.uuid = data.uuid;
        }
        if (data.success != null) {
            message.success = dependency_2.StatusProtoSuccess.fromObject(data.success);
        }
        if (data.error != null) {
            message.error = dependency_2.StatusProtoError.fromObject(data.error);
        }
        if (data.public != null) {
            message.public = ApplicationProtoPublicRes.fromObject(data.public);
        }
        if (data.private != null) {
            message.private = ApplicationProtoPrivateRes.fromObject(data.private);
        }
        return message;
    }
    toObject() {
        const data: {
            uuid?: Uint8Array;
            success?: ReturnType<typeof dependency_2.StatusProtoSuccess.prototype.toObject>;
            error?: ReturnType<typeof dependency_2.StatusProtoError.prototype.toObject>;
            public?: ReturnType<typeof ApplicationProtoPublicRes.prototype.toObject>;
            private?: ReturnType<typeof ApplicationProtoPrivateRes.prototype.toObject>;
        } = {};
        if (this.uuid != null) {
            data.uuid = this.uuid;
        }
        if (this.success != null) {
            data.success = this.success.toObject();
        }
        if (this.error != null) {
            data.error = this.error.toObject();
        }
        if (this.public != null) {
            data.public = this.public.toObject();
        }
        if (this.private != null) {
            data.private = this.private.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.uuid.length)
            writer.writeBytes(1, this.uuid);
        if (this.hasSuccess)
            writer.writeMessage(2, this.success, () => this.success.serialize(writer));
        if (this.hasError)
            writer.writeMessage(3, this.error, () => this.error.serialize(writer));
        if (this.hasPublic)
            writer.writeMessage(4, this.public, () => this.public.serialize(writer));
        if (this.hasPrivate)
            writer.writeMessage(5, this.private, () => this.private.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.success, () => message.success = dependency_2.StatusProtoSuccess.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.error, () => message.error = dependency_2.StatusProtoError.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.public, () => message.public = ApplicationProtoPublicRes.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.private, () => message.private = ApplicationProtoPrivateRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoRes {
        return ApplicationProtoRes.deserialize(bytes);
    }
}
export class ApplicationProtoPublic extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ApplicationProtoPublic {
        const message = new ApplicationProtoPublic({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoPublic {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoPublic();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoPublic {
        return ApplicationProtoPublic.deserialize(bytes);
    }
}
export class ApplicationProtoPublicReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        authCtl?: dependency_1.AuthenticationCtlProtoPublicReq;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("authCtl" in data && data.authCtl != undefined) {
                this.authCtl = data.authCtl;
            }
        }
    }
    get authCtl() {
        return pb_1.Message.getWrapperField(this, dependency_1.AuthenticationCtlProtoPublicReq, 1) as dependency_1.AuthenticationCtlProtoPublicReq;
    }
    set authCtl(value: dependency_1.AuthenticationCtlProtoPublicReq) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasAuthCtl() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        authCtl?: ReturnType<typeof dependency_1.AuthenticationCtlProtoPublicReq.prototype.toObject>;
    }): ApplicationProtoPublicReq {
        const message = new ApplicationProtoPublicReq({});
        if (data.authCtl != null) {
            message.authCtl = dependency_1.AuthenticationCtlProtoPublicReq.fromObject(data.authCtl);
        }
        return message;
    }
    toObject() {
        const data: {
            authCtl?: ReturnType<typeof dependency_1.AuthenticationCtlProtoPublicReq.prototype.toObject>;
        } = {};
        if (this.authCtl != null) {
            data.authCtl = this.authCtl.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAuthCtl)
            writer.writeMessage(1, this.authCtl, () => this.authCtl.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoPublicReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoPublicReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.authCtl, () => message.authCtl = dependency_1.AuthenticationCtlProtoPublicReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoPublicReq {
        return ApplicationProtoPublicReq.deserialize(bytes);
    }
}
export class ApplicationProtoPublicRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        authCtl?: dependency_1.AuthenticationCtlProtoPublicRes;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("authCtl" in data && data.authCtl != undefined) {
                this.authCtl = data.authCtl;
            }
        }
    }
    get authCtl() {
        return pb_1.Message.getWrapperField(this, dependency_1.AuthenticationCtlProtoPublicRes, 1) as dependency_1.AuthenticationCtlProtoPublicRes;
    }
    set authCtl(value: dependency_1.AuthenticationCtlProtoPublicRes) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasAuthCtl() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        authCtl?: ReturnType<typeof dependency_1.AuthenticationCtlProtoPublicRes.prototype.toObject>;
    }): ApplicationProtoPublicRes {
        const message = new ApplicationProtoPublicRes({});
        if (data.authCtl != null) {
            message.authCtl = dependency_1.AuthenticationCtlProtoPublicRes.fromObject(data.authCtl);
        }
        return message;
    }
    toObject() {
        const data: {
            authCtl?: ReturnType<typeof dependency_1.AuthenticationCtlProtoPublicRes.prototype.toObject>;
        } = {};
        if (this.authCtl != null) {
            data.authCtl = this.authCtl.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAuthCtl)
            writer.writeMessage(1, this.authCtl, () => this.authCtl.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoPublicRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoPublicRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.authCtl, () => message.authCtl = dependency_1.AuthenticationCtlProtoPublicRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoPublicRes {
        return ApplicationProtoPublicRes.deserialize(bytes);
    }
}
export class ApplicationProtoPrivate extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ApplicationProtoPrivate {
        const message = new ApplicationProtoPrivate({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoPrivate {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoPrivate();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoPrivate {
        return ApplicationProtoPrivate.deserialize(bytes);
    }
}
export class ApplicationProtoPrivateReq extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4, 5, 6, 7, 8, 9]];
    constructor(data?: any[] | ({} & (({
        authCtl?: dependency_1.AuthenticationCtlProtoPrivateReq;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
        unsub?: never;
    } | {
        authCtl?: never;
        userCtl?: dependency_8.UserCtlProtoReq;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
        unsub?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: dependency_4.OrganizationCtlProtoReq;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
        unsub?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: dependency_5.ProjectCtlProtoReq;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
        unsub?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: dependency_7.TeamCtlProtoReq;
        subscriptionCtl?: never;
        deploymentCtl?: never;
        unsub?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: dependency_6.SubscriptionCtlProtoReq;
        deploymentCtl?: never;
        unsub?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: dependency_3.DeploymentCtlProtoReq;
        unsub?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
        unsub?: ApplicationProtoPrivateUnsubscribeReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("authCtl" in data && data.authCtl != undefined) {
                this.authCtl = data.authCtl;
            }
            if ("userCtl" in data && data.userCtl != undefined) {
                this.userCtl = data.userCtl;
            }
            if ("orgCtl" in data && data.orgCtl != undefined) {
                this.orgCtl = data.orgCtl;
            }
            if ("projectCtl" in data && data.projectCtl != undefined) {
                this.projectCtl = data.projectCtl;
            }
            if ("teamCtl" in data && data.teamCtl != undefined) {
                this.teamCtl = data.teamCtl;
            }
            if ("subscriptionCtl" in data && data.subscriptionCtl != undefined) {
                this.subscriptionCtl = data.subscriptionCtl;
            }
            if ("deploymentCtl" in data && data.deploymentCtl != undefined) {
                this.deploymentCtl = data.deploymentCtl;
            }
            if ("unsub" in data && data.unsub != undefined) {
                this.unsub = data.unsub;
            }
        }
    }
    get authCtl() {
        return pb_1.Message.getWrapperField(this, dependency_1.AuthenticationCtlProtoPrivateReq, 2) as dependency_1.AuthenticationCtlProtoPrivateReq;
    }
    set authCtl(value: dependency_1.AuthenticationCtlProtoPrivateReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAuthCtl() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get userCtl() {
        return pb_1.Message.getWrapperField(this, dependency_8.UserCtlProtoReq, 3) as dependency_8.UserCtlProtoReq;
    }
    set userCtl(value: dependency_8.UserCtlProtoReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasUserCtl() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get orgCtl() {
        return pb_1.Message.getWrapperField(this, dependency_4.OrganizationCtlProtoReq, 4) as dependency_4.OrganizationCtlProtoReq;
    }
    set orgCtl(value: dependency_4.OrganizationCtlProtoReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasOrgCtl() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get projectCtl() {
        return pb_1.Message.getWrapperField(this, dependency_5.ProjectCtlProtoReq, 5) as dependency_5.ProjectCtlProtoReq;
    }
    set projectCtl(value: dependency_5.ProjectCtlProtoReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasProjectCtl() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get teamCtl() {
        return pb_1.Message.getWrapperField(this, dependency_7.TeamCtlProtoReq, 6) as dependency_7.TeamCtlProtoReq;
    }
    set teamCtl(value: dependency_7.TeamCtlProtoReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasTeamCtl() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get subscriptionCtl() {
        return pb_1.Message.getWrapperField(this, dependency_6.SubscriptionCtlProtoReq, 7) as dependency_6.SubscriptionCtlProtoReq;
    }
    set subscriptionCtl(value: dependency_6.SubscriptionCtlProtoReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasSubscriptionCtl() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get deploymentCtl() {
        return pb_1.Message.getWrapperField(this, dependency_3.DeploymentCtlProtoReq, 8) as dependency_3.DeploymentCtlProtoReq;
    }
    set deploymentCtl(value: dependency_3.DeploymentCtlProtoReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasDeploymentCtl() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get unsub() {
        return pb_1.Message.getWrapperField(this, ApplicationProtoPrivateUnsubscribeReq, 9) as ApplicationProtoPrivateUnsubscribeReq;
    }
    set unsub(value: ApplicationProtoPrivateUnsubscribeReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasUnsub() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "authCtl" | "userCtl" | "orgCtl" | "projectCtl" | "teamCtl" | "subscriptionCtl" | "deploymentCtl" | "unsub";
        } = {
            0: "none",
            2: "authCtl",
            3: "userCtl",
            4: "orgCtl",
            5: "projectCtl",
            6: "teamCtl",
            7: "subscriptionCtl",
            8: "deploymentCtl",
            9: "unsub"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4, 5, 6, 7, 8, 9])];
    }
    static fromObject(data: {
        authCtl?: ReturnType<typeof dependency_1.AuthenticationCtlProtoPrivateReq.prototype.toObject>;
        userCtl?: ReturnType<typeof dependency_8.UserCtlProtoReq.prototype.toObject>;
        orgCtl?: ReturnType<typeof dependency_4.OrganizationCtlProtoReq.prototype.toObject>;
        projectCtl?: ReturnType<typeof dependency_5.ProjectCtlProtoReq.prototype.toObject>;
        teamCtl?: ReturnType<typeof dependency_7.TeamCtlProtoReq.prototype.toObject>;
        subscriptionCtl?: ReturnType<typeof dependency_6.SubscriptionCtlProtoReq.prototype.toObject>;
        deploymentCtl?: ReturnType<typeof dependency_3.DeploymentCtlProtoReq.prototype.toObject>;
        unsub?: ReturnType<typeof ApplicationProtoPrivateUnsubscribeReq.prototype.toObject>;
    }): ApplicationProtoPrivateReq {
        const message = new ApplicationProtoPrivateReq({});
        if (data.authCtl != null) {
            message.authCtl = dependency_1.AuthenticationCtlProtoPrivateReq.fromObject(data.authCtl);
        }
        if (data.userCtl != null) {
            message.userCtl = dependency_8.UserCtlProtoReq.fromObject(data.userCtl);
        }
        if (data.orgCtl != null) {
            message.orgCtl = dependency_4.OrganizationCtlProtoReq.fromObject(data.orgCtl);
        }
        if (data.projectCtl != null) {
            message.projectCtl = dependency_5.ProjectCtlProtoReq.fromObject(data.projectCtl);
        }
        if (data.teamCtl != null) {
            message.teamCtl = dependency_7.TeamCtlProtoReq.fromObject(data.teamCtl);
        }
        if (data.subscriptionCtl != null) {
            message.subscriptionCtl = dependency_6.SubscriptionCtlProtoReq.fromObject(data.subscriptionCtl);
        }
        if (data.deploymentCtl != null) {
            message.deploymentCtl = dependency_3.DeploymentCtlProtoReq.fromObject(data.deploymentCtl);
        }
        if (data.unsub != null) {
            message.unsub = ApplicationProtoPrivateUnsubscribeReq.fromObject(data.unsub);
        }
        return message;
    }
    toObject() {
        const data: {
            authCtl?: ReturnType<typeof dependency_1.AuthenticationCtlProtoPrivateReq.prototype.toObject>;
            userCtl?: ReturnType<typeof dependency_8.UserCtlProtoReq.prototype.toObject>;
            orgCtl?: ReturnType<typeof dependency_4.OrganizationCtlProtoReq.prototype.toObject>;
            projectCtl?: ReturnType<typeof dependency_5.ProjectCtlProtoReq.prototype.toObject>;
            teamCtl?: ReturnType<typeof dependency_7.TeamCtlProtoReq.prototype.toObject>;
            subscriptionCtl?: ReturnType<typeof dependency_6.SubscriptionCtlProtoReq.prototype.toObject>;
            deploymentCtl?: ReturnType<typeof dependency_3.DeploymentCtlProtoReq.prototype.toObject>;
            unsub?: ReturnType<typeof ApplicationProtoPrivateUnsubscribeReq.prototype.toObject>;
        } = {};
        if (this.authCtl != null) {
            data.authCtl = this.authCtl.toObject();
        }
        if (this.userCtl != null) {
            data.userCtl = this.userCtl.toObject();
        }
        if (this.orgCtl != null) {
            data.orgCtl = this.orgCtl.toObject();
        }
        if (this.projectCtl != null) {
            data.projectCtl = this.projectCtl.toObject();
        }
        if (this.teamCtl != null) {
            data.teamCtl = this.teamCtl.toObject();
        }
        if (this.subscriptionCtl != null) {
            data.subscriptionCtl = this.subscriptionCtl.toObject();
        }
        if (this.deploymentCtl != null) {
            data.deploymentCtl = this.deploymentCtl.toObject();
        }
        if (this.unsub != null) {
            data.unsub = this.unsub.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAuthCtl)
            writer.writeMessage(2, this.authCtl, () => this.authCtl.serialize(writer));
        if (this.hasUserCtl)
            writer.writeMessage(3, this.userCtl, () => this.userCtl.serialize(writer));
        if (this.hasOrgCtl)
            writer.writeMessage(4, this.orgCtl, () => this.orgCtl.serialize(writer));
        if (this.hasProjectCtl)
            writer.writeMessage(5, this.projectCtl, () => this.projectCtl.serialize(writer));
        if (this.hasTeamCtl)
            writer.writeMessage(6, this.teamCtl, () => this.teamCtl.serialize(writer));
        if (this.hasSubscriptionCtl)
            writer.writeMessage(7, this.subscriptionCtl, () => this.subscriptionCtl.serialize(writer));
        if (this.hasDeploymentCtl)
            writer.writeMessage(8, this.deploymentCtl, () => this.deploymentCtl.serialize(writer));
        if (this.hasUnsub)
            writer.writeMessage(9, this.unsub, () => this.unsub.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoPrivateReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoPrivateReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    reader.readMessage(message.authCtl, () => message.authCtl = dependency_1.AuthenticationCtlProtoPrivateReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.userCtl, () => message.userCtl = dependency_8.UserCtlProtoReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.orgCtl, () => message.orgCtl = dependency_4.OrganizationCtlProtoReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.projectCtl, () => message.projectCtl = dependency_5.ProjectCtlProtoReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.teamCtl, () => message.teamCtl = dependency_7.TeamCtlProtoReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.subscriptionCtl, () => message.subscriptionCtl = dependency_6.SubscriptionCtlProtoReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.deploymentCtl, () => message.deploymentCtl = dependency_3.DeploymentCtlProtoReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.unsub, () => message.unsub = ApplicationProtoPrivateUnsubscribeReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoPrivateReq {
        return ApplicationProtoPrivateReq.deserialize(bytes);
    }
}
export class ApplicationProtoPrivateRes extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4, 5, 6, 7, 8]];
    constructor(data?: any[] | ({
        type?: ApplicationProtoPrivateResType;
    } & (({
        authCtl?: dependency_1.AuthenticationCtlProtoPrivateRes;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
    } | {
        authCtl?: never;
        userCtl?: dependency_8.UserCtlProtoRes;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: dependency_4.OrganizationCtlProtoRes;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: dependency_5.ProjectCtlProtoRes;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: dependency_7.TeamCtlProtoRes;
        subscriptionCtl?: never;
        deploymentCtl?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: dependency_6.SubscriptionCtlProtoRes;
        deploymentCtl?: never;
    } | {
        authCtl?: never;
        userCtl?: never;
        orgCtl?: never;
        projectCtl?: never;
        teamCtl?: never;
        subscriptionCtl?: never;
        deploymentCtl?: dependency_3.DeploymentCtlProtoRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("type" in data && data.type != undefined) {
                this.type = data.type;
            }
            if ("authCtl" in data && data.authCtl != undefined) {
                this.authCtl = data.authCtl;
            }
            if ("userCtl" in data && data.userCtl != undefined) {
                this.userCtl = data.userCtl;
            }
            if ("orgCtl" in data && data.orgCtl != undefined) {
                this.orgCtl = data.orgCtl;
            }
            if ("projectCtl" in data && data.projectCtl != undefined) {
                this.projectCtl = data.projectCtl;
            }
            if ("teamCtl" in data && data.teamCtl != undefined) {
                this.teamCtl = data.teamCtl;
            }
            if ("subscriptionCtl" in data && data.subscriptionCtl != undefined) {
                this.subscriptionCtl = data.subscriptionCtl;
            }
            if ("deploymentCtl" in data && data.deploymentCtl != undefined) {
                this.deploymentCtl = data.deploymentCtl;
            }
        }
    }
    get type() {
        return pb_1.Message.getFieldWithDefault(this, 1, ApplicationProtoPrivateResType.INITIAL) as ApplicationProtoPrivateResType;
    }
    set type(value: ApplicationProtoPrivateResType) {
        pb_1.Message.setField(this, 1, value);
    }
    get authCtl() {
        return pb_1.Message.getWrapperField(this, dependency_1.AuthenticationCtlProtoPrivateRes, 2) as dependency_1.AuthenticationCtlProtoPrivateRes;
    }
    set authCtl(value: dependency_1.AuthenticationCtlProtoPrivateRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAuthCtl() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get userCtl() {
        return pb_1.Message.getWrapperField(this, dependency_8.UserCtlProtoRes, 3) as dependency_8.UserCtlProtoRes;
    }
    set userCtl(value: dependency_8.UserCtlProtoRes) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasUserCtl() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get orgCtl() {
        return pb_1.Message.getWrapperField(this, dependency_4.OrganizationCtlProtoRes, 4) as dependency_4.OrganizationCtlProtoRes;
    }
    set orgCtl(value: dependency_4.OrganizationCtlProtoRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasOrgCtl() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get projectCtl() {
        return pb_1.Message.getWrapperField(this, dependency_5.ProjectCtlProtoRes, 5) as dependency_5.ProjectCtlProtoRes;
    }
    set projectCtl(value: dependency_5.ProjectCtlProtoRes) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasProjectCtl() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get teamCtl() {
        return pb_1.Message.getWrapperField(this, dependency_7.TeamCtlProtoRes, 6) as dependency_7.TeamCtlProtoRes;
    }
    set teamCtl(value: dependency_7.TeamCtlProtoRes) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasTeamCtl() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get subscriptionCtl() {
        return pb_1.Message.getWrapperField(this, dependency_6.SubscriptionCtlProtoRes, 7) as dependency_6.SubscriptionCtlProtoRes;
    }
    set subscriptionCtl(value: dependency_6.SubscriptionCtlProtoRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasSubscriptionCtl() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get deploymentCtl() {
        return pb_1.Message.getWrapperField(this, dependency_3.DeploymentCtlProtoRes, 8) as dependency_3.DeploymentCtlProtoRes;
    }
    set deploymentCtl(value: dependency_3.DeploymentCtlProtoRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasDeploymentCtl() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "authCtl" | "userCtl" | "orgCtl" | "projectCtl" | "teamCtl" | "subscriptionCtl" | "deploymentCtl";
        } = {
            0: "none",
            2: "authCtl",
            3: "userCtl",
            4: "orgCtl",
            5: "projectCtl",
            6: "teamCtl",
            7: "subscriptionCtl",
            8: "deploymentCtl"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4, 5, 6, 7, 8])];
    }
    static fromObject(data: {
        type?: ApplicationProtoPrivateResType;
        authCtl?: ReturnType<typeof dependency_1.AuthenticationCtlProtoPrivateRes.prototype.toObject>;
        userCtl?: ReturnType<typeof dependency_8.UserCtlProtoRes.prototype.toObject>;
        orgCtl?: ReturnType<typeof dependency_4.OrganizationCtlProtoRes.prototype.toObject>;
        projectCtl?: ReturnType<typeof dependency_5.ProjectCtlProtoRes.prototype.toObject>;
        teamCtl?: ReturnType<typeof dependency_7.TeamCtlProtoRes.prototype.toObject>;
        subscriptionCtl?: ReturnType<typeof dependency_6.SubscriptionCtlProtoRes.prototype.toObject>;
        deploymentCtl?: ReturnType<typeof dependency_3.DeploymentCtlProtoRes.prototype.toObject>;
    }): ApplicationProtoPrivateRes {
        const message = new ApplicationProtoPrivateRes({});
        if (data.type != null) {
            message.type = data.type;
        }
        if (data.authCtl != null) {
            message.authCtl = dependency_1.AuthenticationCtlProtoPrivateRes.fromObject(data.authCtl);
        }
        if (data.userCtl != null) {
            message.userCtl = dependency_8.UserCtlProtoRes.fromObject(data.userCtl);
        }
        if (data.orgCtl != null) {
            message.orgCtl = dependency_4.OrganizationCtlProtoRes.fromObject(data.orgCtl);
        }
        if (data.projectCtl != null) {
            message.projectCtl = dependency_5.ProjectCtlProtoRes.fromObject(data.projectCtl);
        }
        if (data.teamCtl != null) {
            message.teamCtl = dependency_7.TeamCtlProtoRes.fromObject(data.teamCtl);
        }
        if (data.subscriptionCtl != null) {
            message.subscriptionCtl = dependency_6.SubscriptionCtlProtoRes.fromObject(data.subscriptionCtl);
        }
        if (data.deploymentCtl != null) {
            message.deploymentCtl = dependency_3.DeploymentCtlProtoRes.fromObject(data.deploymentCtl);
        }
        return message;
    }
    toObject() {
        const data: {
            type?: ApplicationProtoPrivateResType;
            authCtl?: ReturnType<typeof dependency_1.AuthenticationCtlProtoPrivateRes.prototype.toObject>;
            userCtl?: ReturnType<typeof dependency_8.UserCtlProtoRes.prototype.toObject>;
            orgCtl?: ReturnType<typeof dependency_4.OrganizationCtlProtoRes.prototype.toObject>;
            projectCtl?: ReturnType<typeof dependency_5.ProjectCtlProtoRes.prototype.toObject>;
            teamCtl?: ReturnType<typeof dependency_7.TeamCtlProtoRes.prototype.toObject>;
            subscriptionCtl?: ReturnType<typeof dependency_6.SubscriptionCtlProtoRes.prototype.toObject>;
            deploymentCtl?: ReturnType<typeof dependency_3.DeploymentCtlProtoRes.prototype.toObject>;
        } = {};
        if (this.type != null) {
            data.type = this.type;
        }
        if (this.authCtl != null) {
            data.authCtl = this.authCtl.toObject();
        }
        if (this.userCtl != null) {
            data.userCtl = this.userCtl.toObject();
        }
        if (this.orgCtl != null) {
            data.orgCtl = this.orgCtl.toObject();
        }
        if (this.projectCtl != null) {
            data.projectCtl = this.projectCtl.toObject();
        }
        if (this.teamCtl != null) {
            data.teamCtl = this.teamCtl.toObject();
        }
        if (this.subscriptionCtl != null) {
            data.subscriptionCtl = this.subscriptionCtl.toObject();
        }
        if (this.deploymentCtl != null) {
            data.deploymentCtl = this.deploymentCtl.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.type != ApplicationProtoPrivateResType.INITIAL)
            writer.writeEnum(1, this.type);
        if (this.hasAuthCtl)
            writer.writeMessage(2, this.authCtl, () => this.authCtl.serialize(writer));
        if (this.hasUserCtl)
            writer.writeMessage(3, this.userCtl, () => this.userCtl.serialize(writer));
        if (this.hasOrgCtl)
            writer.writeMessage(4, this.orgCtl, () => this.orgCtl.serialize(writer));
        if (this.hasProjectCtl)
            writer.writeMessage(5, this.projectCtl, () => this.projectCtl.serialize(writer));
        if (this.hasTeamCtl)
            writer.writeMessage(6, this.teamCtl, () => this.teamCtl.serialize(writer));
        if (this.hasSubscriptionCtl)
            writer.writeMessage(7, this.subscriptionCtl, () => this.subscriptionCtl.serialize(writer));
        if (this.hasDeploymentCtl)
            writer.writeMessage(8, this.deploymentCtl, () => this.deploymentCtl.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoPrivateRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoPrivateRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.type = reader.readEnum();
                    break;
                case 2:
                    reader.readMessage(message.authCtl, () => message.authCtl = dependency_1.AuthenticationCtlProtoPrivateRes.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.userCtl, () => message.userCtl = dependency_8.UserCtlProtoRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.orgCtl, () => message.orgCtl = dependency_4.OrganizationCtlProtoRes.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.projectCtl, () => message.projectCtl = dependency_5.ProjectCtlProtoRes.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.teamCtl, () => message.teamCtl = dependency_7.TeamCtlProtoRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.subscriptionCtl, () => message.subscriptionCtl = dependency_6.SubscriptionCtlProtoRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.deploymentCtl, () => message.deploymentCtl = dependency_3.DeploymentCtlProtoRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoPrivateRes {
        return ApplicationProtoPrivateRes.deserialize(bytes);
    }
}
export enum ApplicationProtoPrivateResType {
    INITIAL = 0,
    ADDITION = 1,
    UPDATE = 2,
    DELETE = 3
}
export class ApplicationProtoPrivateUnsubscribe extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ApplicationProtoPrivateUnsubscribe {
        const message = new ApplicationProtoPrivateUnsubscribe({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoPrivateUnsubscribe {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoPrivateUnsubscribe();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoPrivateUnsubscribe {
        return ApplicationProtoPrivateUnsubscribe.deserialize(bytes);
    }
}
export class ApplicationProtoPrivateUnsubscribeReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        reqUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("reqUuid" in data && data.reqUuid != undefined) {
                this.reqUuid = data.reqUuid;
            }
        }
    }
    get reqUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set reqUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        reqUuid?: Uint8Array;
    }): ApplicationProtoPrivateUnsubscribeReq {
        const message = new ApplicationProtoPrivateUnsubscribeReq({});
        if (data.reqUuid != null) {
            message.reqUuid = data.reqUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            reqUuid?: Uint8Array;
        } = {};
        if (this.reqUuid != null) {
            data.reqUuid = this.reqUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.reqUuid.length)
            writer.writeBytes(1, this.reqUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationProtoPrivateUnsubscribeReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationProtoPrivateUnsubscribeReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.reqUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ApplicationProtoPrivateUnsubscribeReq {
        return ApplicationProtoPrivateUnsubscribeReq.deserialize(bytes);
    }
}
