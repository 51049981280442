/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map, shareReplay } from "rxjs";
import { SANITY_CDN_QUERY_URL, SANITY_TOKEN, platformUiBannerQuery, SiteBannerData, SANITY_QUERY_URL, TopbarData, topbarQuery } from "typedb-web-common/lib";
import { CloudOnboarding, cloudOnboardingSchemaName, ParagraphWithHighlights, PortableText, ProviderRegionData, providerRegionsQuery, SanitySurvey, Survey } from "typedb-web-schema/lib";
import { environment } from "../environments/environment";

type SanityLandingPageData = {
    title: PortableText;
    body: PortableText;
};

type LandingPageData = {
    title: ParagraphWithHighlights,
    body: ParagraphWithHighlights,
};

const landingPageQuery = `*[(_id match 'cloudPage')][0].introSection`;
const onboardingQuery = `*[(_id match '${cloudOnboardingSchemaName}')][0]`;
const projectInfoSurveyQuery = `*[_id == 'd0a63973-a2f0-4b74-b252-d3c62485a59e'][0]`;
const orgInfoSurveyQuery = `*[_id == '8189aaf9-5144-48c4-850e-ce241ce0d362'][0]`;

@Injectable({
    providedIn: "root",
})
export class SanityService {
    readonly siteTopbar: Observable<TopbarData>;
    readonly appBanner: Observable<SiteBannerData>;
    readonly landingPage: Observable<LandingPageData>;
    readonly projectInfoSurvey: Observable<Survey>;
    readonly orgInfoSurvey: Observable<Survey>;
    readonly providerRegionData: Observable<ProviderRegionData>;
    readonly onboarding: Observable<CloudOnboarding>;

    constructor(private http: HttpClient) {
        this.siteTopbar = this.getSanityResult<TopbarData>(topbarQuery).pipe(shareReplay(1));
        this.appBanner = this.getSanityResult<{ platformUiBanner: SiteBannerData }>(platformUiBannerQuery).pipe(
            map(x => x.platformUiBanner),
            shareReplay(1),
        );
        this.landingPage = this.getSanityResult<SanityLandingPageData>(landingPageQuery).pipe(
            map(data => ({
                title: ParagraphWithHighlights.fromSanity(data.title),
                body: ParagraphWithHighlights.fromSanity(data.body),
            })),
            shareReplay(1),
        );
        this.projectInfoSurvey = this.getSanityResult<SanitySurvey>(projectInfoSurveyQuery).pipe(
            map(data => Survey.fromSanity(data)),
            shareReplay(1),
        );
        this.orgInfoSurvey = this.getSanityResult<SanitySurvey>(orgInfoSurveyQuery).pipe(
            map(data => Survey.fromSanity(data)),
            shareReplay(1),
        )
        this.providerRegionData = this.getSanityResult<ProviderRegionData>(providerRegionsQuery).pipe(shareReplay(1));
        this.onboarding = this.getSanityResult<CloudOnboarding>(onboardingQuery).pipe(shareReplay(1));

        [
            this.siteTopbar,
            this.appBanner,
            this.landingPage,
            this.projectInfoSurvey,
            this.orgInfoSurvey,
            this.providerRegionData,
            this.onboarding
        ].forEach((x: Observable<any>) => x.subscribe());
    }

    private getSanityResult<T>(query: string) {
        return this.http.get<{ result: T }>(
            environment.env === "production" ? SANITY_CDN_QUERY_URL : SANITY_QUERY_URL,
            environment.env === "production"
                ? { params: { query, perspective: "published" } }
                : { params: { query, perspective: "previewDrafts" }, headers: { Authorization: `Bearer ${SANITY_TOKEN}` } },
        ).pipe(map(({ result }) => result));
    }
}
