/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { FirebaseUser, GoogleIdentityBackend } from "./google-identity-backend.service";

@Injectable({
    providedIn: "root",
})
export class IdentityAuthService {
    readonly currentUserStateChanges = this.identity.currentUserStateChanges;
    readonly currentUserEmail$ = this.currentUserStateChanges.pipe(map((user) => user?.email));
    readonly providerUserID$ = this.identity.providerUserID$;
    readonly providerFirstName$ = this.identity.providerFirstName$;
    readonly providerLastName$ = this.identity.providerLastName$;

    constructor(private identity: GoogleIdentityBackend) {
        this.currentUserStateChanges.subscribe((user) => console.debug(`current user:`, user));
    }

    signUp(email: string, password: string): Observable<void> {
        return this.identity.signUp(email, password);
    }

    verify(code: string): Observable<void> {
        return this.identity.verify(code);
    }

    signIn(email: string, password: string): Observable<void> {
        return this.identity.signIn(email, password);
    }

    initiatePasswordReset(code: string, submission: string): Observable<void> {
        return this.identity.initiatePasswordReset(code, submission);
    }

    verifyPasswordReset(code: string): Observable<void> {
        return this.identity.verifyPasswordReset(code);
    }

    get currentUser(): FirebaseUser | null {
        return this.identity.currentUser;
    }

    getIdToken() {
        return this.identity.getIdToken();
    }

    signOut(): Observable<void> {
        return this.identity.signOut();
    }

    microsoftLogin() {
        return this.identity.microsoftLogin();
    }

    googleLogin() {
        return this.identity.googleLogin();
    }

    githubLogin() {
        return this.identity.githubLogin();
    }
}
