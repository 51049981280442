<tp-modal dialogTitle="Create new organization" [isBusy]="isSubmitting$ | async">
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <tp-form-input type="text" [form]="form" field="id" label="Organization ID" />
    <tp-form-input type="text" [form]="form" field="name" label="Organization Name" />
    <tp-form-actions
      [cancellable]="true" submitText="Create" [isSubmitting]="isSubmitting$ | async"
      buttonIdPrefix="createOrgModal" (cancel)="close()"
    />
  </tp-form>
</tp-modal>
