/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

export class ServiceError extends Error {
    constructor(public override message: string, public code: string) {
        super(`[${code}]: ${message}`);
        const actualPrototype = new.target.prototype;
        Object.setPrototypeOf(this, actualPrototype);
    }
}

const DEFAULT_MESSAGE = "An unexpected error occurred";

export function getErrorMessage(err: unknown, defaultValue = DEFAULT_MESSAGE): string {
    if (err instanceof Error) return err.message;
    else if (typeof err === "string") return err;
    else return defaultValue;
}

export const UNAUTHENTICATED_REQUEST_CODE = "UNAUTHENTICATED_REQUEST";
export const WS_CLOSE_REASON_API_VERSION_MISMATCH = "[API_VERSION_MISMATCH]";
