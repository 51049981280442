/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { ApiListResponse } from "../../concept/api-response";
import { Parameters } from "../../concept/common";
import { Deployment, DeploymentColumn, deploymentPropertiesList } from "../../concept/deployment";
import { Organization } from "../../concept/organization";
import { Project } from "../../concept/project";
import { Team, TeamColumn } from "../../concept/team";
import { User, UserColumn } from "../../concept/user";
import { ApplicationState } from "../application-state.service";
import { AuthorizationService } from "../authorization.service";
import { OrgApi } from "../org/org-api.service";
import { OrgDeploymentsTable, OrgTeamsTable, OrgMembersTable } from "../org/org-controller.service";
import { Observable, Subject } from "rxjs";
import { ResourceTable } from "../resource-table.service";
import { ProjectApi } from "./project-api.service";

@Injectable({
    providedIn: "root",
})
export class ProjectController {
    constructor(private api: ProjectApi, private orgApi: OrgApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    deploymentsTable(project: Project, unsub$: Subject<void>): ResourceTable<Deployment, DeploymentColumn> {
        return new ProjectDeploymentsTable(this.app.requireCurrentOrg(), this.orgApi, project, this.api, this.authorization, unsub$);
    }

    membersTable(project: Project, unsub$: Subject<void>): ResourceTable<User, UserColumn> {
        return new ProjectUsersTable(this.app.requireCurrentOrg(), this.orgApi, project, this.api, this.authorization, unsub$);
    }

    teamsTable(project: Project, unsub$: Subject<void>): ResourceTable<Team, TeamColumn> {
        return new ProjectTeamsTable(this.app.requireCurrentOrg(), this.orgApi, project, this.api, this.authorization, unsub$);
    }
}

class ProjectDeploymentsTable extends OrgDeploymentsTable {
    override readonly properties = deploymentPropertiesList.filter(x => x.id !== "project");
    override displayedProperties = [...this.properties.filter(x => !["storageType", "storageSize", "createdAt", "typeDBVersion"].includes(x.id))];

    constructor(org: Organization, orgApi: OrgApi, private project: Project, private projectApi: ProjectApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, orgApi, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Deployment>> {
        return this.projectApi.listDeployments(this.project.uuid, params, this.unsub$);
    }

    override get displayedColumns(): DeploymentColumn[] {
        return [...this.displayedProperties.map(x => x.id)] as DeploymentColumn[];
    }
}

class ProjectUsersTable extends OrgMembersTable {
    constructor(org: Organization, orgApi: OrgApi, private project: Project, private projectApi: ProjectApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, orgApi, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<User>> {
        return this.projectApi.listUsers(this.project.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (UserColumn | "actions")[] {
        return ["avatar", ...this.displayedProperties.map(x => x.id), "actions"] as (UserColumn | "actions")[];
    }
}

class ProjectTeamsTable extends OrgTeamsTable {
    constructor(org: Organization, orgApi: OrgApi, private project: Project, private projectApi: ProjectApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, orgApi, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Team>> {
        return this.projectApi.listTeams(this.project.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (TeamColumn | "actions")[] {
        return [...this.displayedProperties.map(x => x.id), "actions"] as (TeamColumn | "actions")[];
    }
}
