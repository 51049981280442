/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ModalComponent, PropertiesTableComponent, PropertiesTableRowComponent } from 'typedb-platform-framework';

export type ScopeData = string | { name: string, scope: string }[]

@Component({
    selector: 'tp-scope-modal',
    standalone: true,
    imports: [ModalComponent, PropertiesTableComponent, PropertiesTableRowComponent],
    templateUrl: './scope-modal.component.html',
})
export class ScopeModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string, scope: ScopeData }) {}

    get scopeString(): string | undefined {
        return typeof this.data.scope === "string" ? this.data.scope : undefined
    }

    get scopeObjects() {
        return typeof this.data.scope !== "string" ? this.data.scope : undefined
    }
}
