/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    AddressProto,
    CreditCardProto,
    CreditCardProtoType,
    CreditsProto,
    DiscountProto,
    GCPAccountProto,
    PaymentMethodStatusProto,
    InvoiceProto,
    InvoiceProtoPayment,
    InvoiceProtoProduct,
    InvoiceProtoStatus,
    AzureAccountProto, AWSAccountProto
} from "../../protocol/concept";
import { bytesToString, stripUndefinedValues, toSentenceCase } from "../util";
import { BaseConcept, PartialWithUuid, Property } from "./base";
import { ProviderOptions, providerOptionsOf } from "./deployment-options";

export interface AddCreditCardResponse {
    token: string;
    apiKey: string;
}

export interface CreditCard {
    id: string;
    brand: string;
    name: string;
    address: CreditCardAddress;
    lastFourDigits: string;
    expiryMonth: number;
    expiryYear: number;
    type: CreditCardType;
}

export interface CreditCardAddress {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postalCode: string;
    state: string;
}

export type CreditCardType = "unknown" | "credit" | "debit" | "prepaid";

const creditCardTypeFromProtoMap: Record<CreditCardProtoType, CreditCardType> = {
    [CreditCardProtoType.UNKNOWN]: "unknown",
    [CreditCardProtoType.CREDIT]: "credit",
    [CreditCardProtoType.DEBIT]: "debit",
    [CreditCardProtoType.PREPAID]: "prepaid",
};

export function creditCardTypeString(card: CreditCard) {
    return [card.brand, card.type].filter(f => !!f).join(" / ");
}

export function creditCardExpiryDateString(card: CreditCard) {
    return `${card.expiryMonth.toString().padStart(2, "0")}/${card.expiryYear}`;
}

export function creditCardAddressString(card: CreditCard) {
    return [card.address.line1, card.address.line2, card.address.city, card.address.state, card.address.postalCode, card.address.country]
        .filter(f => !!f).join(", ");
}

export type PaymentMethodStatus = "deactivated" | "suspended" | "active"

const paymentMethodStatusFromProtoMap: Record<PaymentMethodStatusProto, PaymentMethodStatus> = {
    [PaymentMethodStatusProto.DEACTIVATED]: "deactivated",
    [PaymentMethodStatusProto.SUSPENDED]: "suspended",
    [PaymentMethodStatusProto.ACTIVE]: "active"
}

export function paymentMethodStatusToString(status: PaymentMethodStatus) {
    return toSentenceCase(status)
}

export interface GCPAccount {
    orderNumber?: string;
    id: string;
    status: PaymentMethodStatus
}

export interface AzureAccount {
    name: string;
    status: PaymentMethodStatus;
}

export interface AWSAccount {
    id: string;
    status: PaymentMethodStatus;
}

export interface Credits extends BaseConcept {
    name: string;
    amount: number;
    originalAmount: number;
    startTime: Date;
    endTime: Date;
    validFor: ProviderOptions[];
}

export interface Discount extends BaseConcept {
    name: string;
    percentage: number;
    startTime: Date;
    endTime: Date;
}

export interface Invoice extends BaseConcept {
    startTime: Date;
    endTime: Date;
    total: number;
    products: InvoiceProduct[];
    payments: InvoicePayment[];
}

export interface InvoiceProduct {
    name: string;
    description: string;
    startTime: Date;
    endTime: Date;
    totalCost: number;
}

export interface InvoicePayment {
    amount: number;
    taxAmount: number;
    taxPercentage: number;
    description: string;
    status: InvoiceStatus;
}

export type InvoiceStatus = "unpaid" | "paid";

const invoiceStatusFromProtoMap: Record<InvoiceProtoStatus, InvoiceStatus> = {
    [InvoiceProtoStatus.UNPAID]: "unpaid",
    [InvoiceProtoStatus.PAID]: "paid",
};

export function creditCardOf(data: CreditCardProto): CreditCard {
    return {
        id: data.id,
        brand: data.brand,
        name: data.name,
        address: partialCreditCardAddressOf(data.address) as CreditCardAddress,
        lastFourDigits: data.lastFourDigits,
        expiryMonth: data.expiryMonth,
        expiryYear: data.expiryYear,
        type: creditCardTypeFromProtoMap[data.type],
    };
}

export function partialCreditCardAddressOf(data: AddressProto): Partial<CreditCardAddress> {
    const creditCardAddress: Partial<CreditCardAddress> = {
        city: data.city,
        country: data.country,
        line1: data.line1,
        line2: data.line2,
        postalCode: data.postalCode,
        state: data.state,
    };
    return stripUndefinedValues(creditCardAddress) as Partial<CreditCardAddress>;
}

export function gcpAccountOf(data: GCPAccountProto): GCPAccount {
    return {
        orderNumber: data.hasOrderNumber ? data.orderNumber : undefined,
        id: data.id,
        status: paymentMethodStatusFromProtoMap[data.status]
    }
}

export function azureAccountOf(data: AzureAccountProto): AzureAccount {
    return {
        name: data.name,
        status: paymentMethodStatusFromProtoMap[data.status]
    }
}

export function awsAccountOf(data: AWSAccountProto): AWSAccount {
    return {
        id: data.id,
        status: paymentMethodStatusFromProtoMap[data.status]
    }
}

export function creditsOf(data: CreditsProto): Credits {
    return {
        uuid: bytesToString(data.uuid),
        name: data.name,
        startTime: new Date(data.startTime),
        endTime: new Date(data.endTime),
        amount: data.currentAmount,
        originalAmount: data.originalAmount,
        validFor: data.validFor.map(providerOptionsOf),
    };
}

export type CreditsColumn = "name" | "status" | "amount" | "originalAmount" | "scope" | "period"

export const creditsProperties: Record<CreditsColumn, Property> = {
    name: { id: "name", name: "Name", attributeType: "name" },
    status: { id: "status", name: "Status", attributeType: "" },
    amount: { id: "amount", name: "Remaining Value", attributeType: "current-amount" },
    originalAmount: { id: "originalAmount", name: "Original Value", attributeType: "original-amount" },
    period: { id: "period", name: "Period", attributeType: "start-time" },
    scope: { id: "scope", name: "Scope", attributeType: "" },
}

export const creditsPropertiesList = [creditsProperties.name, creditsProperties.status, creditsProperties.amount, creditsProperties.originalAmount, creditsProperties.period, creditsProperties.scope]

export function discountOf(data: DiscountProto): Discount {
    return {
        uuid: bytesToString(data.uuid),
        name: data.name,
        percentage: data.percentage,
        startTime: new Date(data.startTime),
        endTime: new Date(data.endTime)
    }
}

export type DiscountColumn = "name" | "percentage" | "period" | "scope"

export const discountProperties: Record<DiscountColumn, Property> = {
    name: { id: "name", name: "Name", attributeType: "name" },
    percentage: { id: "percentage", name: "Percentage", attributeType: "percentage" },
    period: { id: "period", name: "Period", attributeType: "start-time" },
    scope: { id: "scope", name: "Scope", attributeType: "" }
}

export const discountPropertiesList = [discountProperties.name, discountProperties.percentage, discountProperties.period, discountProperties.scope]

export function partialInvoiceOf(data: InvoiceProto): PartialWithUuid<Invoice> {
    const invoice: PartialWithUuid<Invoice> = {
        uuid: bytesToString(data.uuid),
        startTime: new Date(data.startTime),
        endTime: new Date(data.endTime),
        total: data.total,
        products: data.products.map(partialInvoiceProductOf) as InvoiceProduct[],
        payments: data.payments.map(partialInvoicePaymentOf) as InvoicePayment[],
    };
    return stripUndefinedValues(invoice) as PartialWithUuid<Invoice>;
}

export function partialInvoiceProductOf(data: InvoiceProtoProduct): Partial<InvoiceProduct> {
    const invoiceProduct: Partial<InvoiceProduct> = {
        name: data.name,
        description: data.description,
        startTime: new Date(data.startTime),
        endTime: new Date(data.endTime),
        totalCost: data.totalCost,
    };
    return stripUndefinedValues(invoiceProduct) as Partial<InvoiceProduct>;
}

export function partialInvoicePaymentOf(data: InvoiceProtoPayment): Partial<InvoicePayment> {
    const invoicePayment = {
        amount: data.amount,
        taxAmount: data.taxAmount,
        taxPercentage: data.taxPercentage,
        description: data.description,
        status: invoiceStatusFromProtoMap[data.status],
    };
    return stripUndefinedValues(invoicePayment) as Partial<InvoicePayment>;
}

export function invoiceStatusOf(invoice: Invoice): InvoiceStatus {
    return invoice.payments && invoice.payments.length > 0 && invoice.payments.every((payment) => payment.status === "paid") ?
        "paid" :
        "unpaid";
}

export function invoiceStatusToString(status: InvoiceStatus) {
    switch (status) {
        case "paid": return "Paid";
        case "unpaid": return "Unpaid";
    }
}

export type InvoiceColumn = "period" | "totalCost" | "invoiceStatus";

export const invoiceProperties: Record<InvoiceColumn, Property> = {
    period: { id: "period", name: "Period", attributeType: "start-time" },
    totalCost: { id: "totalCost", name: "Total Cost", attributeType: "number" },
    invoiceStatus: { id: "invoiceStatus", name: "Status", attributeType: "payment-status" },
}

export const invoicePropertiesList = [invoiceProperties.period, invoiceProperties.totalCost, invoiceProperties.invoiceStatus];

export interface MarketplaceAddresses {
    gcp: string
    azure: string
}
