@if (table.ready$ | async) {
  @if (deploymentOptions$ | async; as deploymentOptions) {
    <table mat-table [dataSource]="table.items$">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ table.columns.name.name }}</th>
        <td mat-cell *matCellDef="let credits">{{ credits.name }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ table.columns.status.name }}</th>
        <td mat-cell *matCellDef="let credits">{{ credits | creditsStatus }}</td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{ table.columns.amount.name }}</th>
        <td mat-cell *matCellDef="let credits">{{ credits.amount | currency }}</td>
      </ng-container>
      <ng-container matColumnDef="originalAmount">
        <th mat-header-cell *matHeaderCellDef>{{ table.columns.originalAmount.name }}</th>
        <td mat-cell *matCellDef="let credits">{{ credits.originalAmount | currency }}</td>
      </ng-container>
      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef>{{ table.columns.period.name }}</th>
        <td mat-cell *matCellDef="let credits">{{ credits.startTime | date }} - {{ credits.endTime | date }}</td>
      </ng-container>
      <ng-container matColumnDef="scope">
        <th mat-header-cell *matHeaderCellDef>{{ table.columns.scope.name }}</th>
        <td mat-cell *matCellDef="let credits">
          @if ((credits.validFor | providerOptionsScope: deploymentOptions.providers); as result) {
            @if (result.openInPopup) {
              <span class="open-scope-modal" (click)="openScopeModal(credits.name, result.scope)">Certain Deployments</span>
            } @else {
              {{ result.scope }}
            }
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
      <tr mat-row *matRowDef="let credits; columns: table.displayedColumns"></tr>
    </table>
  } @else {
    <tp-spinner/>
  }
} @else {
  <tp-spinner/>
}
