/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { BaseResource } from "../concept/base";

export function strongConfirmationStringOf<RESX extends BaseResource>(selected: RESX[]) {
    return selected.length === 1 ? selected[0].id : 'destroy ' + selected.length;
}

export function confirmationMessage<RESX extends BaseResource>(action: string, selected: RESX[], type: string) {
    return selected.length === 1
        ? `Are you sure you would like to ${action} '${selected[0].id}'?`
        : `Are you sure you would like to ${action} the following ${type}s? ${selected.map(x => x.id).join(", ")}`;
}

export type DialogResult = "ok" | "cancelled" | { error: string };

@Injectable({
    providedIn: "root",
})
export class DialogService {

}
