/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { InvitationProto, OrganizationProto } from "../../protocol/concept";
import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseConcept, BaseResource, PartialWithUuid, Property } from "./base";
import { AccessLevel, accessLevelOf } from "./iam";

export interface Organization extends BaseResource {
    name: string;
    createdAt: Date;
}

export function partialOrgOf(data: OrganizationProto): PartialWithUuid<Organization> {
    const org: PartialWithUuid<Organization> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        name: data.hasName ? data.name : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
    };
    return stripUndefinedValues(org) as PartialWithUuid<Organization>;
}

export function orgOf(org: OrganizationProto): Organization {
    return partialOrgOf(org) as Organization;
}

export function orgProtoOf(org: Partial<Organization>): OrganizationProto {
    return new OrganizationProto({
        uuid: org.uuid ? stringToBytes(org.uuid) : undefined,
        id: org.id,
        name: org.name,
        createdAt: org.createdAt?.getTime(),
    });
}

export interface Invitation extends BaseConcept {
    userEmail: string;
    createdAt: Date;
    org: PartialWithUuid<Organization>;
    accessLevel: AccessLevel;
}

export function partialInvitationOf(data: InvitationProto): PartialWithUuid<Invitation> {
    const invitation: PartialWithUuid<Invitation> = {
        uuid: bytesToString(data.uuid),
        userEmail: data.hasUserEmail ? data.userEmail : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
        org: data.hasOrg ? partialOrgOf(data.org) : undefined,
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
    };
    return stripUndefinedValues(invitation) as PartialWithUuid<Invitation>;
}

export type OrgColumn = "id" | "name" | "createdAt";

export const orgProperties: Record<OrgColumn, Property> = {
    id: { id: "id", name: "Organization ID", attributeType: "id" },
    name: { id: "name", name: "Organization Name", attributeType: "name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const orgPropertiesList = [orgProperties.id, orgProperties.name, orgProperties.createdAt];

export type InvitationColumn = "orgId" | "orgName" | "userEmail" | "createdAt";

export const invitationProperties: Record<InvitationColumn, Property> = {
    orgId: { id: "orgId", name: "Organization ID", attributeType: "id", ownerType: "organization" },
    orgName: { id: "orgName", name: "Organization Name", attributeType: "name", ownerType: "organization" },
    userEmail: { id: "userEmail", name: "User Email", attributeType: "email" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
}

export const invitationPropertiesList = [
    invitationProperties.orgId, invitationProperties.orgName,
    invitationProperties.userEmail, invitationProperties.createdAt
];
