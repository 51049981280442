/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { Team } from "../../../concept/team";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { teamDetailsPath } from "../../../routing/resource-paths";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "../../../util";
import { ApplicationState } from "../../../service/application-state.service";
import { Router } from "@angular/router";
import { DialogResult } from "../../../service/dialog.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { TeamApi } from "../../../service/team/team-api.service";

export type TeamUpdateDialogData = { team: Team };

@Component({
    selector: "tp-team-update-dialog",
    templateUrl: "./team-update-dialog.component.html",
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule,
        FormComponent, MatFormFieldModule, MatInputModule
    ],
})
export class TeamUpdateDialogComponent {
    readonly org = this.app.requireCurrentOrg();
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: [this.data.team.id, [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: [this.data.team.name, [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private dialogRef: MatDialogRef<TeamUpdateDialogComponent, DialogResult>, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: TeamUpdateDialogData,
        private teamApi: TeamApi, private app: ApplicationState, private router: Router,
        private snackbar: SnackbarService,
    ) {
    }

    submit() {
        const form = this.form.value;
        const [id, name] = [form.id, form.name];
        this.teamApi.updateTeam({
            uuid: this.data.team.uuid,
            id: id !== this.data.team.id ? id : undefined,
            name: name !== this.data.team.name ? name : undefined
        }).subscribe({
            next: () => {
                this.close("ok");
                if (id !== this.data.team.id) {
                    this.router.navigate([teamDetailsPath({ id: id! }, this.org)], { replaceUrl: true });
                }
                this.snackbar.success(`Team updated successfully`);
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
