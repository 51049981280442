@if (loaded) {
  <div class="integrations-row">
    @if (gcpMarketplaceAddress$ | async; as address) {
      <tp-integration-card
        title="Google Cloud Marketplace" [imageIcon]="{src: '/assets/logo/gcp-icon.svg', alt: 'GCP'}"
        [chip]="gcpAccountPresent ? 'active' : undefined"
        [buttonLinkText]="!gcpAccountPresent ? 'Connect' : 'Disconnect'" [enabled]="!gcpAccountPresent || canDisconnect"
        [link]="gcpAccountPresent ? 'https://console.cloud.google.com/marketplace/orders' : address"
        (cardClick)="cardClick.emit('gcp')"
      >
        <p>
          Pay for deployments hosted on GCP
          with an existing Google Cloud Billing Account.
        </p>
      </tp-integration-card>
    }
    <tp-integration-card
      title="AWS Marketplace" [imageIcon]="{src: '/assets/logo/aws.svg', alt: 'AWS'}"
      chip="coming-soon" [enabled]="false" buttonLinkText="Connect" (cardClick)="cardClick.emit('aws')"
    >
      <p>
        Pay for deployments hosted on AWS
        with an existing AWS Billing Account
      </p>
    </tp-integration-card>
    @if (azureMarketplaceAddress$ | async; as address) {
      <tp-integration-card
        title="Azure Marketplace"
        [imageIcon]="{src: '/assets/logo/azure.svg', alt: 'Azure'}"
        [chip]="azureAccountPresent ? 'active' : undefined"
        [buttonLinkText]="!azureAccountPresent ? 'Connect' : 'Disconnect'"
        [enabled]="!azureAccountPresent || canDisconnect"
        [link]="azureAccountPresent ? 'https://portal.azure.com/#browse/Microsoft.SaaS%2Fresources' : address"
        (cardClick)="cardClick.emit('azure')"
      >
        <p>
          Pay for deployments hosted on Azure
          with an existing Azure Billing Account
        </p>
      </tp-integration-card>
    }
  </div>
} @else {
  <tp-spinner/>
}
