/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component } from "@angular/core";
import { MarketplaceCardsComponent } from "../../org/marketplace-cards/marketplace-cards.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ActivatedRoute } from "@angular/router";
import { RETURN_TO } from "../../../framework/url-params";

@Component({
    selector: "tp-setup-marketplace",
    standalone: true,
    imports: [MarketplaceCardsComponent, FontAwesomeModule],
    templateUrl: "./setup-marketplace.component.html",
    styleUrl: "./setup-marketplace.component.scss"
})
export class SetupMarketplaceComponent {
    loading = true;
    continueLink = "/";
    cardClicked = false;

    constructor(private route: ActivatedRoute) {
        this.route.queryParamMap.subscribe((params) => {
            const returnToUrl = params.get(RETURN_TO);
            if (returnToUrl) this.continueLink = returnToUrl;
        });
    }

    get continueText(): string {
        return this.cardClicked ? "Continue" : "Skip";
    }

    onCardClick() {
        this.cardClicked = true;
    }
}
