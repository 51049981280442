/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { teamDetailsPath } from "../../../routing/resource-paths";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "../../../util";
import { ApplicationState } from "../../../service/application-state.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { TeamApi } from "../../../service/team/team-api.service";

@Component({
    selector: "tp-team-creation-dialog",
    templateUrl: "./team-creation-dialog.component.html",
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent],
})
export class TeamCreationDialogComponent {
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: ["", [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private app: ApplicationState, private dialogRef: MatDialogRef<TeamCreationDialogComponent>,
        private router: Router, private formBuilder: FormBuilder, private teamApi: TeamApi, private snackbar: SnackbarService,
    ) {
    }

    submit() {
        const org = this.app.requireCurrentOrg();
        const id = this.form.value.id!;
        const name = this.form.value.name!;
        this.teamApi.createTeam({ team: { id, name }, orgUuid: org.uuid }).subscribe({
            next: () => {
                this.router.navigate([teamDetailsPath({ id }, org)]);
                this.close();
                this.snackbar.success("Team created successfully");
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close() {
        this.dialogRef.close();
    }
}
