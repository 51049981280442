/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { MatSortModule } from "@angular/material/sort";
import { MatButtonModule } from "@angular/material/button";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DatetimePipe, MenuItem, SpinnerComponent } from "typedb-platform-framework";
import { Team, TeamColumn } from "../../../concept/team";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { TableSelectionCellComponent, TableSelectionHeaderCellComponent } from "../../../framework/table";

@Component({
    selector: "tp-teams-table",
    templateUrl: "./teams-table.component.html",
    standalone: true,
    imports: [
        AsyncPipe, DatetimePipe, MatTableModule, MatSortModule, MatMenuModule, MatButtonModule,
        TableSelectionHeaderCellComponent, TableSelectionCellComponent, SpinnerComponent, FontAwesomeModule,
    ],
})
export class TeamsTableComponent {
    @Input({ required: true}) table!: ResourceTable<Team, TeamColumn>;
    @Input() rowPopupMenuItems?: ((row: Team) => MenuItem[]) | null;

    constructor(public tableService: ResourceTableService) {}
}
