/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Router } from "@angular/router";
import { mergeChanges, mergeListChanges } from "../concept/api-response";
import { Ok, ok } from "../concept/base";
import { hasAdminAccess, hasWriteAccess } from "../concept/iam";
import { Organization } from "../concept/organization";
import { Injectable } from "@angular/core";
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, of, ReplaySubject, shareReplay, Subject, switchMap, tap } from "rxjs";
import { User, UserAuth } from "../concept/user";
import { AnalyticsService } from "./analytics.service";
import { PlatformApiBackend } from "./backend/platform-api-backend.service";
import { PlatformAuthService } from "./authentication/platform-auth.service";
import { IdentityAuthService } from "./authentication/identity-auth.service";
import { OrgApi } from "./org/org-api.service";
import { SnackbarService } from "./snackbar.service";
import { UserApi } from "./user/user-api.service";

type SignOutOptions = {
    callDeauthUser: boolean;
    redirectToLoginPage: boolean;
};

const DEFAULT_SIGNOUT_OPTIONS: SignOutOptions = {
    callDeauthUser: true,
    redirectToLoginPage: true,
};

const ORG_ID = "org_id";

@Injectable({
    providedIn: "root",
})
export class ApplicationState {
    readonly userAuth$ = new ReplaySubject<UserAuth>(1);
    readonly currentUser$ = new BehaviorSubject<User | null>(null);
    readonly currentUserOrgs$ = new BehaviorSubject<Organization[] | null>(null);
    readonly currentOrg$ = new BehaviorSubject<Organization | null>(null);
    private readonly orgAccess$ = this.currentOrg$.pipe(
        filter(org => !!org), map(org => org!),
        switchMap(org => this.orgApi.getAccessLevels([org.uuid]).pipe(map(x => x[0]))),
        shareReplay(1),
    );
    readonly hasOrgWriteAccess$ = this.orgAccess$.pipe(map(x => hasWriteAccess(x)));
    readonly hasOrgAdminAccess$ = this.orgAccess$.pipe(map(x => hasAdminAccess(x)));
    readonly unsubOrgs$ = new Subject<void>();

    constructor(
        private router: Router, private userApi: UserApi, private snackbarService: SnackbarService,
        private identityAuth: IdentityAuthService, private platformAuth: PlatformAuthService, private orgApi: OrgApi,
        private apiBackend: PlatformApiBackend, private analytics: AnalyticsService,
    ) {
        this.updateUserAuthOnIdentityAuthStateChanges();
        this.reauthOnConnectionRecovered();
        this.setPosthogAliasOnIdentityAuthStateChanges();
        this.initCurrentUserSubOnUserAuthChanges();
        this.initCurrentUserOrgsSub();
        this.initLastUsedOrgIdSub();
        (window as any)["app"] = this;
    }

    private updateUserAuthOnIdentityAuthStateChanges() {
        this.identityAuth.currentUserStateChanges.pipe(
            switchMap((firebaseUser) => {
                if (!firebaseUser) return of(null);
                else return this.identityAuth.getIdToken();
            }),
            switchMap((token) => {
                if (!token) return of<UserAuth>({ status: "logged_out" });
                else return this.platformAuth.authUserToken(token);
            }),
            distinctUntilChanged(),
        ).subscribe((userAuth) => {
            this.userAuth$.next(userAuth);
        });
    }

    private reauthOnConnectionRecovered() {
        this.apiBackend.onReconnect$.pipe(
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap(() => console.debug("Reauthenticated after connection recovery")),
        ).subscribe();
    }

    initCurrentUserSubOnUserAuthChanges() {
        const unsub$ = new Subject<void>();
        this.userAuth$.pipe(
            map((userAuth) => userAuth.user?.id ?? null),
            tap(() => { unsub$.next(); }),
            switchMap((userId) => userId ? this.userApi.getUser(userId, unsub$) : of(null)),
        ).subscribe((res) => {
            if (!res) {
                this.currentUser$.next(null);
                return;
            }
            const mergeResult = mergeChanges(this.currentUser$.value, res);
            if (mergeResult.result === "shouldDelete") {
                unsub$.next();
                // TODO: ???
            } else if (mergeResult.result === "shouldResync") {
                unsub$.next();
                this.initCurrentUserSubOnUserAuthChanges();
            } else {
                this.currentUser$.next(mergeResult.data);
            }
        });
    }

    initCurrentUserOrgsSub() {
        this.currentUser$.pipe(
            tap(() => this.unsubOrgs$.next()),
            switchMap((user) => {
                if (!user) return of(null);
                return this.userApi.listOrgs({ pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } }, this.unsubOrgs$);
            }),
        ).subscribe((res) => {
            if (res == null) {
                this.currentUserOrgs$.next(null);
                return;
            }
            const mergeResult = mergeListChanges(this.currentUserOrgs$.value, res);
            if (mergeResult.result === "shouldResync") {
                this.initCurrentUserOrgsSub();
                return;
            }
            this.currentUserOrgs$.next(mergeResult.data);
        });
    }

    private initLastUsedOrgIdSub() {
        this.currentOrg$.pipe(filter(x => !!x), map(x => x!)).subscribe((org) => {
            this.setLastUsedOrgId(org.id);
        });
    }

    private setPosthogAliasOnIdentityAuthStateChanges() {
        this.identityAuth.currentUserStateChanges.pipe(
            map(x => x?.email),
            distinctUntilChanged(),
            filter(email => !!email), map(email => email!),
        ).subscribe((email) => {
            this.analytics.posthog.alias(email);
            this.analytics.posthog.set({ email });
        });
    }

    requireCurrentOrg(): Organization {
        const org = this.currentOrg$.value;
        if (org) return org;
        else {
            this.snackbarService.errorPersistent("Unexpected internal error: no organization selected");
            throw "No organization selected";
        }
    }

    requireCurrentUser(): User {
        const user = this.currentUser$.value;
        if (user) return user;
        else {
            this.signOut({ callDeauthUser: false, redirectToLoginPage: true }).subscribe(() => {
                this.snackbarService.errorPersistent("You have been logged out; please log back in to continue.");
            });
            throw "Unauthenticated";
        }
    }

    setLastUsedOrgId(orgId: string) {
        try {
            localStorage.setItem(ORG_ID, orgId);
        } catch (e) {
            // do nothing - maybe local storage is unwritable by browser security policy
        }
    }

    lastUsedOrgId() {
        return localStorage.getItem(ORG_ID);
    }

    setCurrentOrg(org: Organization) {
        this.currentOrg$.next(org);
    }

    unsetCurrentOrg() {
        this.currentOrg$.next(null);
    }

    signOut(options: Partial<SignOutOptions> = {}) {
        const { callDeauthUser, redirectToLoginPage } = Object.assign({}, DEFAULT_SIGNOUT_OPTIONS, options);
        const maybeDeauthUser: Observable<Ok> = callDeauthUser && this.currentUser$.value ? this.platformAuth.deauthUser() : of(ok);
        return maybeDeauthUser.pipe(
            tap(() => {
                this.userAuth$.next({ status: "logged_out" });
                this.analytics.posthog.reset();
            }),
            switchMap(() => this.identityAuth.signOut()),
            tap(() => {
                if (redirectToLoginPage) {
                    window.location.href = window.location.origin;
                }
            }),
        );
    }
}
