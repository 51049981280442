/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, DestroyRef, OnDestroy, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, filter, first, interval, map, Observable, of, shareReplay, startWith, Subject, switchMap, tap } from "rxjs";
import { isInDestroyableState, isInResumableState, isInSuspendableState, Deployment, DeploymentStatus, Server } from "../../../concept/deployment";
import { hasAdminAccess, hasWriteAccess } from "../../../concept/iam";
import { LOADING } from "../../../framework/strings";
import { deploymentsPath, serverDetailsPath } from "../../../routing/resource-paths";
import { DeploymentApi } from "../../../service/deployment/deployment-api.service";
import {
    ButtonComponent, BreadcrumbComponent, PropertiesTableComponent, ModalComponent, DeleteResourceSectionComponent,
    ConfirmationModalComponent, LinkPanelsComponent, LinkPanelComponent, DatetimePipe, SpinnerComponent,
    StrongConfirmationModalComponent, StrongConfirmationModalData, ConfirmationModalData, PropertiesTableRowComponent
} from "typedb-platform-framework";
import { DialogResult } from "../../../service/dialog.service";

import { ProjectApi } from "../../../service/project/project-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { ResourceService } from "../../../service/resource.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { isBlank } from "../../../util";
import { ProjectDetailsPathPipe } from "../../project/project-details-link.pipe";
import { ResourceAvailability, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { DeploymentStatusPipe } from "../deployment-status.pipe";
import { MachineTypePipe } from "../machine-type.pipe";
import { RegionPipe } from "../region.pipe";
import { StorageSizePipe } from "../storage-size.pipe";
import { StorageTypePipe } from "../storage-type.pipe";
import { DeploymentUpdateDialogComponent, DeploymentEditDialogData } from "../update/deployment-update-dialog.component";
import { ServerStatusPipe } from "../server-status.pipe";
import { MatTableModule } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { StatusIcon, TableCopyableTextCellComponent, TableSelectionCellComponent, TableSelectionHeaderCellComponent, TableStatusCellComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: "tp-deployment-details-page",
    templateUrl: "./deployment-details-page.component.html",
    styleUrls: ["./deployment-details-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        PropertiesTableRowComponent, DeleteResourceSectionComponent, AsyncPipe, ConfirmationModalComponent, RouterLink,
        LinkPanelsComponent, LinkPanelComponent, DatetimePipe, TableSelectionCellComponent, TableStatusCellComponent,
        TableSelectionHeaderCellComponent, TableCopyableTextCellComponent, ServerStatusPipe, MatTableModule,
        SpinnerComponent, PropertiesTableComponent, ProjectDetailsPathPipe, RegionPipe, MachineTypePipe,
        StorageSizePipe, StorageTypePipe, DeploymentStatusPipe, FontAwesomeModule, MatTooltipModule,
    ],
})
export class DeploymentDetailsPageComponent implements OnInit, OnDestroy {
    readonly deployment$ = new BehaviorSubject<Deployment | null>(null);
    readonly projectAccess$ = this.deployment$.pipe(
        switchMap(deployment => {
            if (deployment) return this.projectApi.getAccessLevels([deployment.project.uuid]).pipe(map(x => x[0]));
            else return of("none" as const);
        }),
        shareReplay(1),
    );
    readonly hasWriteAccess$ = this.projectAccess$.pipe(map(x => hasWriteAccess(x)));
    readonly hasAdminAccess$ = this.projectAccess$.pipe(map(x => hasAdminAccess(x)));
    readonly servers$ = new BehaviorSubject<Server[]>([]);
    readonly serversReady$ = new BehaviorSubject(false);
    readonly breadcrumb$ = this.deployment$.pipe(
        map((deployment) => {
            if (!deployment) return null;
            else return { items: [deployment.project.id, deployment.id], url: deploymentsPath(this.app.requireCurrentOrg()) };
        }),
    );
    readonly runtime$ = this.servers$.pipe(
        map((servers) => {
            const totalServers = servers.length;
            const availableServerCount = servers.filter(x => x.status === "running").length;
            return { totalServers, availableServerCount };
        }),
    );
    readonly availabilityText$ = combineLatest([this.deployment$, this.runtime$]).pipe(
        map(([deployment, runtime]) => {
            if (!deployment) return ``;
            const meaningfulStatuses: DeploymentStatus[] = ["starting", "running", "resuming"];
            if (meaningfulStatuses.includes(deployment.status)) {
                return `${runtime.availableServerCount} / ${runtime.totalServers}`;
            } else return new DeploymentStatusPipe().transform(deployment.status);
        }),
    );
    readonly cannotSuspendReason$ = combineLatest([this.deployment$, this.hasWriteAccess$]).pipe(map(([deployment, canWrite]) => {
        if (!deployment) return LOADING;
        if (!isInSuspendableState(deployment)) return "Deployment is not running";
        if (!canWrite) return `You don't have write access to the project '${deployment.project.id}'`;
        return null;
    }), startWith(LOADING));
    readonly cannotResumeReason$ = combineLatest([this.deployment$, this.hasWriteAccess$]).pipe(map(([deployment, canWrite]) => {
        if (!deployment) return LOADING;
        if (!isInResumableState(deployment)) return "Deployment is not suspended";
        if (!canWrite) return `You don't have write access to the project '${deployment.project.id}'`;
        return null;
    }), startWith(LOADING));
    readonly cannotDestroyReason$ = combineLatest([this.deployment$, this.hasAdminAccess$]).pipe(map(([deployment, isAdmin]) => {
        if (!deployment) return LOADING;
        if (!isInDestroyableState(deployment)) return "Deployment is not in a destroyable state";
        if (!isAdmin) return `You don't have admin access to the project '${deployment.project.id}'`;
        return null;
    }), startWith(LOADING));
    readonly suspendEnabled$ = this.cannotSuspendReason$.pipe(map(x => !x));
    readonly resumeEnabled$ = this.cannotResumeReason$.pipe(map(x => !x));
    readonly destroyEnabled$ = this.cannotDestroyReason$.pipe(map(x => !x));
    readonly latestTypeDBVersion$ = this.deploymentApi.getLatestTypeDBVersion().pipe(shareReplay(1));
    readonly typeDBUpgradeAvailable$ = combineLatest([this.deployment$, this.latestTypeDBVersion$]).pipe(
        map(([dep, latestVersion]) => dep ? dep.typeDBVersion !== latestVersion : false)
    );

    readonly serverColumns = ["address", "serverStatus", "status"];

    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.deployment$.pipe(map(x => x ? "ready" : "loading"), catchError(() => of("failed" as const)));

    constructor(
        private app: ApplicationState, private route: ActivatedRoute, private deploymentApi: DeploymentApi,
        private router: Router, private snackbar: SnackbarService, private dialog: MatDialog,
        private projectApi: ProjectApi, private destroyRef: DestroyRef, private resourceService: ResourceService,
    ) {
        this.deployment$.pipe(
            takeUntilDestroyed(),
            filter(deployment => !!deployment),
            map(deployment => deployment!),
            switchMap((deployment) => interval(5000).pipe(
                takeUntilDestroyed(this.destroyRef),
                startWith(-1),
                switchMap(() => this.deploymentApi.listServers(deployment.uuid))
            )),
        ).subscribe((servers) => {
            this.servers$.next(servers);
            this.serversReady$.next(true);
        });
    }

    ngOnInit() {
        this.initDataLoader();
    }

    ngOnDestroy() {
        this.unsub$.next();
    }

    initDataLoader() {
        this.route.params.pipe(
            filter((params) => params["project-id"] && params["deployment-id"]),
            map((params) => ({ projectId: params["project-id"] as string, deploymentId: params["deployment-id"] as string })),
            distinctUntilChanged((a, b) => a.deploymentId === b.deploymentId && a.projectId === b.projectId),
            tap(() => { this.unsub$.next(); }),
            switchMap(({ projectId, deploymentId }) => {
                return this.projectApi.getProject({ projectId, orgUuid: this.app.requireCurrentOrg().uuid }, of(undefined)).pipe(
                    first(),
                    map((project) => ({ project: project.initial!, deploymentId })),
                );
            }),
            switchMap(({ project, deploymentId }) => {
                return this.deploymentApi.getDeployment({ deploymentId, projectUuid: project.uuid }, this.unsub$);
            }),
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.deployment$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.deployment$.error(err);
            }
        });
    }

    openEditModal() {
        this.dialog.open<DeploymentUpdateDialogComponent, DeploymentEditDialogData, DialogResult>(DeploymentUpdateDialogComponent, {
            data: { deployment: this.deployment$.value! }
        }).beforeClosed().subscribe((result) => {
            if (result === "ok") this.initDataLoader();
        });
    }

    openSuspendResumeModal(action: "resume" | "suspend") {
        const deployment = this.deployment$.value!;
        const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
            data: {
                title: action === "resume" ? "Resume deployment" : "Suspend deployment",
                body: `Are you sure you would like to ${action} '${deployment.id}'?`,
                confirmText: action === "resume" ? "Resume deployment" : "Suspend deployment",
                confirmButtonStyle: action === "resume" ? "primary-solid green" : "primary-solid red",
            },
        }).componentInstance;
        modal.confirmed.pipe(
            switchMap(() => {
                switch (action) {
                    case "suspend": return this.deploymentApi.suspendDeployment(deployment.uuid);
                    case "resume": return this.deploymentApi.resumeDeployment(deployment.uuid);
                }
            }),
        ).subscribe({
            next: () => {
                modal.close();
                switch (action) {
                    case "suspend": this.snackbar.success(`'${deployment.id}' is now being suspended.`); break;
                    case "resume": this.snackbar.success(`'${deployment.id}' is now being resumed.`); break;
                }
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openDestroyModal() {
        const deployment = this.deployment$.value!;
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Destroy deployment",
                body: `Are you sure you would like to destroy '${deployment.id}'? All of its data will be permanently erased. This action cannot be undone!`,
                confirmText: "Destroy deployment",
                strongConfirmationString: deployment.id,
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.deploymentApi.destroyDeployment(deployment.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`'${deployment.id}' is now being destroyed.`);
                this.router.navigate([deploymentsPath(this.app.requireCurrentOrg())]);
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openUpdateTypeDBModal() {
        this.latestTypeDBVersion$.pipe(first()).subscribe((latestTypeDBVersion) => {
            const deployment = this.deployment$.value!;
            const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
                data: {
                    title: "Update TypeDB",
                    body: `Are you sure you would like to update '${deployment.id}' to TypeDB ${latestTypeDBVersion}?`,
                    confirmText: `Update to ${latestTypeDBVersion}`,
                    confirmButtonStyle: "primary-solid green",
                },
            }).componentInstance;
            modal.confirmed.pipe(
                switchMap(() => this.deploymentApi.updateToLatestTypeDB(deployment.uuid)),
            ).subscribe({
                next: () => {
                    modal.close();
                    this.snackbar.success(`'${deployment.id}' is now being updated to TypeDB ${latestTypeDBVersion}.`);
                },
                error: () => {
                    modal.isSubmitting$.next(false);
                },
            });
        });
    }

    serverIcon(server: Server): StatusIcon {
        switch (server.status) {
            case "running":
                return { color: "ok" };
            case "pending":
                return { loading: true };
            case "unknown":
            case "error":
                return { color: "error" };
        }
    }

    handleServerTableRowClick(server: Server) {
        if (!isBlank(window.getSelection()?.toString())) return; // handle case where user wants to select the address
        this.router.navigate([serverDetailsPath(server, this.deployment$.value!, this.app.requireCurrentOrg())]);
    }
}
