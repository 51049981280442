/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { Subject, combineLatest, map, startWith, switchMap } from "rxjs";
import {
    ButtonComponent, ConfirmationModalComponent, ModalComponent, StrongConfirmationModalComponent,
    StrongConfirmationModalData
} from "typedb-platform-framework";
import { LOADING, NO_ITEMS_SELECTED } from "../../../framework/strings";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { TeamApi } from "../../../service/team/team-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { resourceAvailabilityStreamOf, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { AsyncPipe } from "@angular/common";
import { TeamCreationDialogComponent } from "../create/team-creation-dialog.component";
import { TeamsTableComponent } from "../table/teams-table.component";
import { MatDialog } from "@angular/material/dialog";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: "tp-teams-page",
    templateUrl: "./teams-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, ButtonComponent, ModalComponent, ConfirmationModalComponent, FontAwesomeModule,
        AsyncPipe, TableToolbarComponent, TeamsTableComponent, TablePaginatorComponent, MatTooltipModule,
    ],
})
export class TeamsPageComponent implements OnDestroy {
    private readonly unsub$ = new Subject<void>();
    readonly teams = this.orgCtl.teamsTable(this.unsub$);
    readonly availability$ = resourceAvailabilityStreamOf(this.teams);

    readonly cannotCreateReason$ = this.app.hasOrgWriteAccess$.pipe(map(canWrite => {
        if (!canWrite) return "Only users with write permissions to the organization can create teams";
        return null;
    }), startWith(LOADING));
    readonly cannotDeleteReason$ = this.teams.hasAdminAccessToSelected$.pipe(map(isAdmin => {
        if (!this.teams.isAnySelected()) return NO_ITEMS_SELECTED;
        if (!isAdmin) return "You don't have admin access to one or more of the selected team(s)";
        return null;
    }), startWith(LOADING));
    readonly createEnabled$ = this.cannotCreateReason$.pipe(map(x => !x));
    readonly deleteEnabled$ = this.cannotDeleteReason$.pipe(map(x => !x));

    constructor(
        private app: ApplicationState, private snackbar: SnackbarService, private teamApi: TeamApi,
        private orgCtl: OrgController, private dialog: MatDialog,
    ) {}

    ngOnDestroy() {
        this.unsub$.next();
    }

    openCreateModal() {
        this.dialog.open(TeamCreationDialogComponent);
    }

    openDeleteModal() {
        const selected = [...this.teams.selected];
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: selected.length === 1 ? "Delete team" : "Delete teams",
                body: confirmationMessage("delete", selected, "team") + ` This action cannot be undone.`,
                confirmText: selected.length === 1 ? "Delete team" : "Delete teams",
                strongConfirmationString: strongConfirmationStringOf(selected),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => combineLatest(selected.map(x => this.teamApi.deleteTeam(x.uuid))))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Team(s) deleted successfully.`);
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
