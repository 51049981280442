<tp-page-scaffold>
  <tp-form class="dc-form" [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <div class="dc-root">
      <div class="dc-form-container">
        <div class="form">
          <div class="title">
            <span style="width: inherit">Create Deployment</span>
          </div>
          @if (deploymentOptions$ | async) {
            @if (firstSectionReady) {
              <div class="form-input-row">
                <div class="form-input-row-description"><p>Your deployment's ID. Must be unique within a project.</p></div>
                <tp-form-input [form]="form" field="id"/>
              </div>
              <div class="form-input-row">
                <div class="form-input-row-description"><p>The project for this deployment.</p></div>
                <tp-form-select [form]="form" field="project" [options]="(projectOptions$ | async)!"/>
              </div>
            } @else {
              <tp-spinner class="first-section-placeholder"/>
            }

            <div class="subtitle"><span>Cloud Configuration</span></div>
            @if (providerSectionReady) {
              <div class="providers-row">
                @for (provider of providers$ | async; track provider) {
                  <button type="button" class="provider-card" [ngClass]="providerCardClasses(provider)" (click)="selectProvider(provider)" [disabled]="!form.enabled">
                    <img [src]="providerImageUrl(provider)" [alt]="provider.name" />
                  </button>
                }
              </div>
              <div class="form-input-row">
                <div class="form-input-row-description"><p>The physical location that your deployment will be placed in.</p></div>
                <mat-form-field [title]="regionFieldTitle">
                  <mat-select class="region-mat-select" [formControl]="form.controls.region">
                    <mat-select-trigger class="region-mat-select-trigger" [class.region-free-available]="form.value.region?.isFreeAvailable">
                      @if (form.value.region; as region) {
                        <img [ngSrc]="regionFlagUrl(region)" width="20" height="15" alt="" />&nbsp;<span>{{ region | region }}</span>
                      }
                    </mat-select-trigger>
                    @for (regionGroup of regionGroups; track regionGroup) {
                      <mat-optgroup [label]="regionGroup.name">
                        @for (region of regionGroup.options; track region) {
                          <mat-option [value]="region.value" [class.region-free-available]="region.value.isFreeAvailable">
                            <img [ngSrc]="regionFlagUrl(region.value)" width="20" height="15" alt="" />&nbsp;<span>{{ region.viewValue }}</span>
                          </mat-option>
                        }
                      </mat-optgroup>
                    }
                  </mat-select>
                  @if (form.controls.region.getError("errorText")) {
                    <mat-error>{{ form.controls.region.getError("errorText") }}</mat-error>
                  }
                </mat-form-field>
              </div>
            } @else {
              <tp-spinner/>
            }

            <div class="subtitle"><span>Server Configuration</span></div>
            <div class="form-input-row">
              <div class="form-input-row-description"><p>The specs of your deployment's machines.</p></div>
              <mat-form-field [title]="machineTypeFieldTitle">
                <mat-select class="machine-type-mat-select" [formControl]="form.controls.machineType">
                  <mat-select-trigger class="machine-type-mat-select-trigger" [class.machine-type-free]="form.value.machineType?.isFree">
                    @if (form.value.machineType; as machineType) {
                      <span>{{ machineType | machineType }}</span>
                    }
                  </mat-select-trigger>
                  @for (machineType of machineTypes; track machineType) {
                    <mat-option [value]="machineType.value" [class.machine-type-free]="machineType.value.isFree">
                      <span>{{ machineType.viewValue }}</span>
                    </mat-option>
                  }
                </mat-select>
                @if (form.controls.machineType.getError("errorText")) {
                  <mat-error>{{ form.controls.machineType.getError("errorText") }}</mat-error>
                }
              </mat-form-field>
            </div>
            <div class="form-input-row form-toggle-group-row">
              <div class="form-input-row-description"><p>The number of machines in your deployment.</p></div>
              <tp-form-toggle-group
                [form]="form" field="serverCount" [options]="(serverCounts$ | async)!" [matTooltip]="(serverCountTooltip$ | async)!"
                [disabled]="serverCountLocked"
              />
            </div>
            <div class="form-input-row">
              <div class="form-input-row-description"><p>The specs of your deployment's storage device.</p></div>
              <tp-form-select [form]="form" field="storageType" [options]="storageTypes"/>
            </div>
            <div class="form-input-row form-slider-row">
              <div class="form-input-row-description"><p>Storage in gigabytes.</p></div>
              <div class="form-slider-field" [matTooltip]="(storageSliderTooltip$ | async)!">
                <mat-form-field class="form-slider-field-text-box">
                  <input
                    matInput type="text" readonly
                    [value]="storageSliderIndicatorText"
                  />
                </mat-form-field>
                <mat-slider min="0" max="9" [showTickMarks]="true" [displayWith]="storageSliderValueToString" [disabled]="storageSliderLocked">
                  <input [formControl]="form.controls.storageSlider" matSliderThumb>
                </mat-slider>
              </div>
            </div>
            <div class="form-input-row">
              <div class="form-input-row-description"><p>The TypeDB version your deployment will use.</p></div>
              <tp-form-select [form]="form" field="typeDBVersion" [options]="(typeDBVersions$ | async)!"/>
            </div>
          } @else {
            <tp-spinner/>
          }
        </div>
      </div>

      <div class="dc-basket-bar">
        <div class="subtitle subtitle-first-of-type"><span>Cloud Configuration</span></div>
        <table>
          <tr>
            <th>Provider</th>
            <td>{{ form.value.provider?.name || "--" }}</td>
          </tr>
          <tr>
            <th>Location</th>
            <td>{{ form.value.region ? form.value.region.locationName : "--" }}</td>
          </tr>
          <tr>
            <th>Region</th>
            <td>{{ form.value.region ? form.value.region.vendorId : "--" }}</td>
          </tr>
        </table>

        <div class="subtitle"><span>Server Configuration</span></div>
        <table>
          <tr>
            <th>Compute</th>
            <td>{{ form.value.machineType ? (form.value.machineType | machineType:"compute") : "--" }}</td>
          </tr>
          <tr>
            <th>Machine Type</th>
            <td>{{ form.value.machineType ? (form.value.machineType.vendorId) : "--" }}</td>
          </tr>
          <tr>
            <th>Cluster Size</th>
            <td>{{ form.value.serverCount || "--" }}</td>
          </tr>
          <tr>
            <th>Storage</th>
            <td>{{ storageSliderCurrentValueToString }}</td>
          </tr>
          <tr>
            <th>Storage Speed</th>
            <td>{{ form.value.storageType ? (form.value.storageType.iops) : "--" }}</td>
          </tr>
          <tr>
            <th>Storage Type</th>
            <td>{{ form.value.storageType ? (form.value.storageType.vendorId) : "--" }}</td>
          </tr>
          <tr>
            <th>TypeDB Version</th>
            <td>{{ form.value.typeDBVersion }}</td>
          </tr>
        </table>

        <div class="subtitle"><span>Cost</span></div>
        <table>
          <tr>
            <th>Per hour</th>
            <td>
              @if (pricePerHourString$ | async; as pricePerHourString) {
                {{ pricePerHourString }}
              } @else {
                <tp-spinner [size]="16"/>
              }
            </td>
          </tr>
          <tr>
            <th>Per 30 days</th>
            <td>
              @if (pricePerMonthString$ | async; as pricePerMonthString) {
                {{ pricePerMonthString }}
              } @else {
                <tp-spinner [size]="16"/>
              }
            </td>
          </tr>
        </table>

        <tp-button
          type="submit" [enabled]="(canSubmit$ | async) || false" buttonId="deploy"
          buttonStyle="primary-solid green" [matTooltip]="(submitButtonTooltip$ | async)!"
        >
          @if (isSubmitting$ | async) {<tp-spinner [size]="20"/>} @else {Deploy}
        </tp-button>

        <p class="terms-privacy-additional-info">
          By creating a deployment, you are accepting the
          <a href="https://typedb.com/legal/terms-of-service" target="_blank" rel="noopener">Terms of Service</a>
          and the
          <a href="https://typedb.com/legal/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>.
        </p>

        <div class="form-additional-info">
          @if (mustVerifyEmail$ | async) {
            <div class="info-box status-info">
              Please
              <a href="javascript:void(0);" (click)="resendVerificationEmail()">
                verify your email address.
              </a>
            </div>
          }
          @if (showAddPaymentMethodPrompt$ | async) {
            <div class="info-box status-info">
              Please
              <a [href]="'/org/' + org.id + '/settings'" target="_blank">
                add a payment method
              </a>
              to create this deployment.
            </div>
          }
          @if (showAskAdminForPaymentMethodPrompt$ | async) {
            <div class="info-box status-info">
              Please ask an organization administrator to add a payment method, then
              <a href="javascript:void(0);" (click)="fetchPayableProviders(true)">click here</a> to
              refresh payment methods.
            </div>
          }
          @if (cannotCreateSecondFreeDeployment$ | async) {
            <div class="info-box status-error">
              Limited to 1 free deployment per organization. Please select a paid provider, region, or machine tier.
            </div>
          }
          @if (isBelowRecommendedNodeCount) {
            <div class="info-box status-info">
              Consider upgrading to 3 machines for high availability and scalability.
            </div>
          }
          @if (showFreeTierSuspensionWarning$ | async) {
            <div class="info-box status-warn">
              Free deployments are suspended after 14 full days of inactivity. You can resume them at any time.
            </div>
          }
        </div>

        <div class="flex-spacer"></div>

        <tp-button
          type="button" [enabled]="true" buttonStyle="primary-outline green stroke"
          (click)="openCreditApplicationDialog()" buttonId="applyForCreditsOrDiscounts"
        >
          Apply for free credits / discounts
        </tp-button>
      </div>
    </div>
  </tp-form>
</tp-page-scaffold>
