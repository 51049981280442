/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

export class MenuItem {
    constructor(
        public label: string,
        public action: () => void,
        public icon: { size: number; name: string } | undefined = undefined,
        public checkbox: boolean = false,
        public enabled: boolean = true
    ) {}
}
