<tp-modal dialogTitle="Apply for free credits / discounts">
  <p>
    Users affiliated with academic institutions, research projects, Open Source projects and startups may be eligible
    for free or discounted TypeDB Cloud credits.
  </p>
  <p>
    Include the following information in your application:
  </p>
  <ul>
    <li>Detailed information about your institution and/or project.</li>
    <li>A rough estimate of your expected monthly TypeDB Cloud usage.</li>
    <li>Contact details that we can use to get in touch with you about your project.</li>
  </ul>
  <a href="mailto:cloud@typedb.com?subject=TypeDB Cloud Free / Discounted Credits Application" target="_blank" (click)="dialogRef.close()">
    <tp-button buttonStyle="primary-solid green" buttonId="creditApplicationModal_submit">Apply for free credits / discounts</tp-button>
  </a>
</tp-modal>
