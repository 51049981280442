/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { ApplicationProtoPrivateResType } from "../../application/protocol/application";
import { BaseConcept, PartialWithUuid } from "./base";

export interface ApiResponse<ENTITY extends BaseConcept> {
    initial?: ENTITY;
    update?: PartialWithUuid<ENTITY>;
    delete?: { uuid: string };
}

export interface ApiListResponse<ENTITY extends BaseConcept> {
    initial?: ENTITY[];
    add?: ENTITY[];
    update?: PartialWithUuid<ENTITY>[];
    delete?: { uuids: string[] };
}

export function responseOf<ENTITY extends BaseConcept>(type: ApplicationProtoPrivateResType, concept: PartialWithUuid<ENTITY>): ApiResponse<ENTITY> {
    switch (type) {
        case ApplicationProtoPrivateResType.INITIAL: return { initial: concept as ENTITY };
        case ApplicationProtoPrivateResType.ADDITION: throw "Unexpected response type ADD for a single entity instance";
        case ApplicationProtoPrivateResType.UPDATE: return { update: concept };
        case ApplicationProtoPrivateResType.DELETE: return { delete: { uuid: concept.uuid } };
    }
}

export function responseListOf<ENTITY extends BaseConcept>(type: ApplicationProtoPrivateResType, concepts: PartialWithUuid<ENTITY>[]): ApiListResponse<ENTITY> {
    switch (type) {
        case ApplicationProtoPrivateResType.INITIAL: return { initial: concepts as ENTITY[] };
        case ApplicationProtoPrivateResType.ADDITION: return { add: concepts as ENTITY[] };
        case ApplicationProtoPrivateResType.UPDATE: return { update: concepts };
        case ApplicationProtoPrivateResType.DELETE: return { delete: { uuids: concepts.map(x => x.uuid) } };
    }
}

export function mergeChanges<ENTITY extends BaseConcept>(current: ENTITY | null, res: ApiResponse<ENTITY>): { result: "ok" | "shouldDelete" | "shouldResync", data: ENTITY | null } {
    if (res.initial) {
        if (current) Object.assign(current, res.initial);
        return { result: "ok", data: res.initial };
    } else if (res.update) {
        if (!current || current.uuid !== res.update.uuid) return { result: "shouldResync", data: null };
        else {
            Object.assign(current, res.update);
            return { result: "ok", data: current };
        }
    } else if (res.delete) {
        if (!current || current.uuid !== res.delete.uuid) return { result: "shouldResync", data: null };
        return { result: "shouldDelete", data: current };
    } else throw `Invalid ApiResponse object: ${res}`;
}

export function mergeListChanges<ENTITY extends BaseConcept>(current: ENTITY[] | null, res: ApiListResponse<ENTITY>): { result: "ok" | "shouldResync", data: ENTITY[] | null } {
    if (!res.initial && !current) return { result: "shouldResync", data: null };
    if (res.initial) {
        if (current) {
            current.length = 0;
            current.push(...res.initial);
        }
        return { result: "ok", data: res.initial };
    } else if (res.add) {
        for (const item of res.add) {
            const existing = current!.find(x => x.uuid === item.uuid);
            if (existing) return { result: "shouldResync", data: null };
            current!.push(item);
        }
        return { result: "ok", data: current };
    } else if (res.update) {
        for (const item of res.update) {
            const target = current!.find(x => x.uuid === item.uuid);
            if (!target) return { result: "shouldResync", data: null };
            Object.assign(target, item);
        }
        return { result: "ok", data: current };
    } else if (res.delete) {
        for (const uuid of res.delete.uuids) {
            const target = current!.find(x => x.uuid === uuid);
            if (!target) return { result: "shouldResync", data: null };
            current!.remove(target);
        }
        return { result: "ok", data: current };
    } else throw `Invalid ApiListResponse object: ${res}`;
}
