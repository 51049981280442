/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { Region, regionToString } from "../../concept/deployment-options";

@Pipe({
    name: "region",
    standalone: true,
})
export class RegionPipe implements PipeTransform {
    transform(value: Region, form: "short" | "long" = "long"): string {
        return regionToString(value, form);
    }
}
