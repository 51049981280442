/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { ProjectProto } from "../../protocol/concept";
import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";

export interface Project extends BaseResource {
    name: string;
    createdAt: Date;
}

export function partialProjectOf(data: ProjectProto): PartialWithUuid<Project> {
    const project: PartialWithUuid<Project> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        name: data.hasName ? data.name: undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
    };
    return stripUndefinedValues(project) as PartialWithUuid<Project>;
}

export function projectProtoOf(project: Partial<Project>): ProjectProto {
    return new ProjectProto({
        uuid: project.uuid ? stringToBytes(project.uuid) : undefined,
        id: project.id,
        name: project.name,
        createdAt: project.createdAt?.getTime(),
    });
}

export type ProjectColumn = "id" | "name" | "createdAt";

export const projectProperties: Record<ProjectColumn, Property> = {
    id: { id: "id", name: "Project ID", attributeType: "id" },
    name: { id: "name", name: "Project Name", attributeType: "name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const projectPropertiesList = [projectProperties.id, projectProperties.name, projectProperties.createdAt];
