@if (table.ready$ | async) {
  <table mat-table [dataSource]="table.items$">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ table.columns.name.name }}</th>
      <td mat-cell *matCellDef="let discount">{{ discount.name }}</td>
    </ng-container>
    <ng-container matColumnDef="percentage">
      <th mat-header-cell *matHeaderCellDef>{{ table.columns.percentage.name }}</th>
      <td mat-cell *matCellDef="let discount">{{ discount.percentage }}%</td>
    </ng-container>
    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef>{{ table.columns.period.name }}</th>
      <td mat-cell *matCellDef="let discount">{{ discount.startTime | date }} - {{ discount.endTime | date }}</td>
    </ng-container>
    <ng-container matColumnDef="scope">
      <th mat-header-cell *matHeaderCellDef>{{ table.columns.scope.name }}</th>
      <td mat-cell *matCellDef="let discount">All Deployments</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
    <tr mat-row *matRowDef="let discount; columns: table.displayedColumns"></tr>
  </table>
} @else {
  <tp-spinner/>
}
