/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, filter, first, map, Observable, of, Subject, switchMap, tap } from "rxjs";
import {
    BreadcrumbComponent, ButtonComponent, ConfirmationModalComponent,
    DatetimePipe, DeleteResourceSectionComponent, MenuItem, ModalComponent, PropertiesTableComponent,
    PropertiesTableRowComponent, StrongConfirmationModalComponent, StrongConfirmationModalData
} from "typedb-platform-framework";
import { AccessLevel, hasWriteAccess } from "../../../concept/iam";
import { Project, ProjectColumn } from "../../../concept/project";
import { Team, TeamColumn } from "../../../concept/team";
import { User, UserColumn } from "../../../concept/user";
import { PROJECT_ID } from "../../../framework/url-params";
import { projectsPath } from "../../../routing/resource-paths";
import { DialogResult } from "../../../service/dialog.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { ProjectApi } from "../../../service/project/project-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { ProjectController } from "../../../service/project/project-controller.service";
import { ResourceTable } from "../../../service/resource-table.service";
import { ResourceService } from "../../../service/resource.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { DeploymentsTableComponent } from "../../deployment/table/deployments-table.component";
import { ResourceAvailability, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { TeamsTableComponent } from "../../team/table/teams-table.component";
import { UsersTableComponent } from "../../member/table/users-table.component";
import { ProjectAssignTeamDialogComponent, ProjectAssignTeamDialogData } from "../assign-team/project-assign-team-dialog.component";
import { ProjectAssignUserDialogComponent, ProjectAddUserDialogData } from "../assign-user/project-assign-user-dialog.component";
import { Deployment, DeploymentColumn } from "../../../concept/deployment";
import { ProjectUpdateDialogComponent, ProjectUpdateDialogData } from "../update/project-update-dialog.component";
import { TablePaginatorComponent, TableToolbarAction, TableToolbarComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "tp-project-details-page",
    templateUrl: "./project-details-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        DeleteResourceSectionComponent, ConfirmationModalComponent, AsyncPipe, TableToolbarComponent,
        DeploymentsTableComponent, TablePaginatorComponent, TeamsTableComponent, UsersTableComponent,
        PropertiesTableRowComponent, DatetimePipe, FontAwesomeModule,
    ],
})
export class ProjectDetailsPageComponent implements OnInit, OnDestroy {
    readonly project$ = new BehaviorSubject<Project | null>(null);
    readonly projectAccess$ = this.project$.pipe(switchMap(project => {
        if (project) return this.projectApi.getAccessLevels([project.uuid]).pipe(map(x => x[0]));
        else return of("none" as AccessLevel);
    }));
    readonly hasWriteAccess$ = this.projectAccess$.pipe(map(x => hasWriteAccess(x)));
    readonly breadcrumb$ = this.project$.pipe(
        map((project) => {return !project ? null : { items: [project.id], url: projectsPath(this.app.requireCurrentOrg()) };}),
    );

    cannotDeleteReason: string | null = null;

    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.project$.pipe(map(x => x ? "ready" : "loading"), catchError(() => of("failed" as const)));

    private readonly unsubDeployments$ = new Subject<void>();
    deployments?: ResourceTable<Deployment, DeploymentColumn>;
    readonly createDeploymentButton: TableToolbarAction = {
        text: "Create deployment",
        enabled: false,
        onClick: () => { this.navigateToCreateDeployment(); },
    };

    private readonly unsubMembers$ = new Subject<void>();
    members?: ResourceTable<User, UserColumn>;
    readonly assignUserButton: TableToolbarAction = {
        text: "Assign user",
        enabled: false,
        onClick: () => { this.openAssignUserDialog(); },
    };

    private readonly unsubTeams$ = new Subject<void>();
    teams?: ResourceTable<Team, TeamColumn>;
    readonly assignTeamButton: TableToolbarAction = {
        text: "Assign team",
        enabled: false,
        onClick: () => { this.openAssignTeamDialog(); },
    };

    private readonly unsubOrgProjects$ = new Subject<void>();
    private readonly orgProjects: ResourceTable<Project, ProjectColumn> = this.orgCtl.projectsTable(this.unsubOrgProjects$);

    membersTableRowPopupMenuItems: (user: User) => MenuItem[] = () => [];
    teamsTableRowPopupMenuItems: (team: Team) => MenuItem[] = () => [];

    constructor(
        private app: ApplicationState, private router: Router, private route: ActivatedRoute,
        private orgCtl: OrgController, private orgService: OrgApi, private projectApi: ProjectApi,
        private dialog: MatDialog, private snackbar: SnackbarService, private projectCtl: ProjectController,
        private resourceService: ResourceService,
    ) {}

    ngOnInit() {
        this.initDataLoader();
    }

    ngOnDestroy() {
        this.unsub$.next();
        this.unsubDeployments$.next();
        this.unsubMembers$.next();
        this.unsubTeams$.next();
        this.unsubOrgProjects$.next();
    }

    initDataLoader() {
        const org = this.app.requireCurrentOrg();

        this.project$.pipe(first(x => !!x), map(x => x!)).subscribe(project => {
            this.deployments = this.projectCtl.deploymentsTable(project, this.unsubDeployments$);
            this.members = this.projectCtl.membersTable(project, this.unsubMembers$);
            this.teams = this.projectCtl.teamsTable(project, this.unsubTeams$);
            this.projectAccess$.subscribe((accessLevel) => {
                this.membersTableRowPopupMenuItems = (user: User) => {
                    return hasWriteAccess(accessLevel) ? [new MenuItem("Remove from project", () => {
                        this.projectApi.unassignUser({ projectUuid: this.project$.value!.uuid, userUuid: user.uuid })
                            .subscribe(() => this.members!.refresh());
                    })] : [];
                }
                this.teamsTableRowPopupMenuItems = (team: Team) => {
                    return hasWriteAccess(accessLevel) ? [new MenuItem("Remove from project", () => {
                        this.projectApi.unassignTeam({ projectUuid: this.project$.value!.uuid, teamUuid: team.uuid })
                            .subscribe(() => this.teams!.refresh());
                    })] : [];
                }
            });
            combineLatest([this.orgProjects.items$, this.deployments.items$]).subscribe(([projects, deployments]) => {
                if (!deployments || !projects) {
                    this.cannotDeleteReason = "";
                } else if (deployments.length > 0) {
                    this.cannotDeleteReason = "This project cannot be deleted because it has at least one deployment";
                } else if (projects.length === 1) {
                    this.cannotDeleteReason = "This project cannot be deleted because it is the only project in the organization";
                } else {
                    this.cannotDeleteReason = null;
                }
            });
        });

        this.route.params.pipe(
            filter((params) => params["project-id"]),
            map((params) => params["project-id"] as string),
            distinctUntilChanged(),
            tap(() => { this.unsub$.next(); }),
            switchMap((projectId) => {
                return this.projectApi.getProject({ projectId, orgUuid: org.uuid }, of(undefined));
            })
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.project$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.project$.error(err);
            }
        });

        this.hasWriteAccess$.subscribe(canWrite => {
            this.assignUserButton.enabled = canWrite;
            this.assignTeamButton.enabled = canWrite;
            this.createDeploymentButton.enabled = canWrite;
        });
    }

    openEditModal() {
        this.dialog.open<ProjectUpdateDialogComponent, ProjectUpdateDialogData, DialogResult>(ProjectUpdateDialogComponent, {
            data: { project: this.project$.value! }
        }).beforeClosed().subscribe((result) => {
            if (result === "ok") this.initDataLoader();
        });
    }

    get projectCanBeDeleted(): boolean {
        return this.cannotDeleteReason == null;
    }

    get deleteProjectDisabledText(): string {
        return this.cannotDeleteReason || "";
    }

    openDeleteModal() {
        const project = this.project$.value!;
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Delete project",
                body: `Are you sure you would like to delete '${project.name}'? This action cannot be undone.`,
                confirmText: "Delete project",
                strongConfirmationString: project.id,
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.projectApi.deleteProject(project.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Project '${project.id}' is now deleted.`);
                this.router.navigate(["../../projects"], { relativeTo: this.route });
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    private navigateToCreateDeployment() {
        const currentProject = this.project$.value;
        if (!currentProject) {
            return;
        }
        this.router.navigate(["../../deployments/create"], { relativeTo: this.route, queryParams: { [PROJECT_ID]: currentProject.id }, });
    }

    private openAssignTeamDialog() {
        this.orgService.listTeams(this.app.requireCurrentOrg().uuid, this.createDefaultParams(), of(undefined)).pipe(
            switchMap((res) => {
                const allTeams = res.initial!;
                return this.dialog.open<ProjectAssignTeamDialogComponent, ProjectAssignTeamDialogData, DialogResult>(
                    ProjectAssignTeamDialogComponent, { data: { allTeams, project: this.project$.value! } }
                ).beforeClosed();
            }),
        ).subscribe((result) => {
            if (result === "ok") this.teams!.refresh();
        });
    }

    private openAssignUserDialog() {
        return this.orgService.listMembers(this.app.requireCurrentOrg().uuid, this.createDefaultParams(), of(undefined)).pipe(
            switchMap((res) => {
                const orgMembers = res.initial!;
                return this.dialog.open<ProjectAssignUserDialogComponent, ProjectAddUserDialogData, DialogResult>(
                    ProjectAssignUserDialogComponent, { data: { orgMembers, project: this.project$.value! } }
                ).beforeClosed();
            }),
        ).subscribe((result) => {
            if (result === "ok") this.members!.refresh();
        });
    }

    private createDefaultParams() {
        return { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } };
    }
}
