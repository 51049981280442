<mat-form-field class="tp-org-selector">
  <mat-select [value]="org" (selectionChange)="onOrgChange($event)">
    @for (org of app.currentUserOrgs$ | async; track org) {
      <mat-option [value]="org">{{ org.id }}</mat-option>
    }
    <hr/>
    <button type="button" class="selector-button mat-mdc-option mdc-list-item" (click)="onCreateOrgClick()">
      <fa-icon [icon]="['fal', 'plus']"/><span>Create organization</span>
    </button>
  </mat-select>
</mat-form-field>

<div class="links">
  <tp-sidebar-link [link]="'/org/' + org.id + '/deployments'" domain="deployments"><fa-icon [icon]="['fal', 'objects-column']"/><span>Deployments</span></tp-sidebar-link>
  <tp-sidebar-link [link]="'/org/' + org.id + '/projects'" domain="projects"><fa-icon [icon]="['fal', 'folders']"/><span>Projects</span></tp-sidebar-link>
  <tp-sidebar-link [link]="'/org/' + org.id + '/teams'" domain="teams"><fa-icon [icon]="['fal', 'users']"/><span>Teams</span></tp-sidebar-link>
  <tp-sidebar-link [link]="'/org/' + org.id + '/members'" domain="members"><fa-icon [icon]="['fal', 'user-group']"/><span>Members</span></tp-sidebar-link>
  @if (app.hasOrgAdminAccess$ | async) {
    <tp-sidebar-link [link]="'/org/' + org.id + '/settings'" domain="settings"><fa-icon [icon]="['fal', 'gear']"/><span>Settings</span></tp-sidebar-link>
  }
  <div class="flex-spacer"></div>
  <tp-sidebar-link link="mailto:support@typedb.com" [external]="true"><fa-icon [icon]="['fal', 'headset']"/>Support</tp-sidebar-link>
</div>
