/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { Subject, combineLatest, first, map, skip, startWith, switchMap } from "rxjs";
import {
    ConfirmationModalComponent, ConfirmationModalData, StrongConfirmationModalComponent, StrongConfirmationModalData,
    ButtonComponent,
} from "typedb-platform-framework";
import { isInDestroyableState, isInResumableState, isInSuspendableState } from "../../../concept/deployment";
import { LOADING, NO_ITEMS_SELECTED } from "../../../framework/strings";
import { DeploymentApi } from "../../../service/deployment/deployment-api.service";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { AsyncPipe } from "@angular/common";
import { resourceAvailabilityStreamOf, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { ApplicationState } from "../../../service/application-state.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DeploymentsTableComponent } from "../table/deployments-table.component";
import { MatDialog } from "@angular/material/dialog";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: "tp-deployments-page",
    templateUrl: "./deployments-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, AsyncPipe, ConfirmationModalComponent, RouterLink, TableToolbarComponent,
        DeploymentsTableComponent, TablePaginatorComponent, ButtonComponent, FontAwesomeModule, MatTooltipModule,
    ],
})
export class DeploymentsPageComponent implements OnDestroy {
    readonly org = this.app.requireCurrentOrg();

    private readonly unsub$ = new Subject<void>();
    readonly deployments = this.orgCtl.deploymentsTable(this.unsub$);
    readonly availability$ = resourceAvailabilityStreamOf(this.deployments);

    readonly writableProjects$ = this.orgCtl.listWritableProjectsSnapshot();
    readonly cannotCreateReason$ = this.writableProjects$.pipe(map(writableProjects => {
        if (!writableProjects.length) return "You need write access to at least one project to create a new deployment";
        return null;
    }), startWith(LOADING));
    readonly cannotSuspendReason$ = this.deployments.hasWriteAccessToSelected$.pipe(map(canWrite => {
        if (!this.deployments.isAnySelected()) return NO_ITEMS_SELECTED;
        if (!canWrite) return "You don't have write access to one or more of the selected deployment(s)";
        if (this.deployments.selected.some(x => !isInSuspendableState(x))) return "A selected deployment is not running";
        return null;
    }), startWith(LOADING));
    readonly cannotResumeReason$ = this.deployments.hasWriteAccessToSelected$.pipe(map(canWrite => {
        if (!this.deployments.isAnySelected()) return NO_ITEMS_SELECTED;
        if (!canWrite) return "You don't have write access to one or more of the selected deployment(s)";
        if (this.deployments.selected.some(x => !isInResumableState(x))) return "A selected deployment is not suspended";
        return null;
    }), startWith(LOADING));
    readonly cannotDestroyReason$ = this.deployments.hasAdminAccessToSelected$.pipe(map(isAdmin => {
        if (!this.deployments.isAnySelected()) return NO_ITEMS_SELECTED;
        if (!isAdmin) return "You don't have admin access to one or more of the selected deployment(s)";
        if (this.deployments.selected.some(x => !isInDestroyableState(x))) return "A selected deployment is not in a destroyable state";
        return null;
    }), startWith(LOADING));
    readonly createEnabled$ = this.cannotCreateReason$.pipe(map(x => !x));
    readonly suspendEnabled$ = this.cannotSuspendReason$.pipe(map(x => !x));
    readonly resumeEnabled$ = this.cannotResumeReason$.pipe(map(x => !x));
    readonly destroyEnabled$ = this.cannotDestroyReason$.pipe(map(x => !x));

    constructor(
        private deploymentApi: DeploymentApi, public route: ActivatedRoute, private router: Router,
        private snackbar: SnackbarService, private app: ApplicationState, private orgCtl: OrgController,
        private dialog: MatDialog,
    ) {
        combineLatest([this.deployments.items$.pipe(skip(1)), this.writableProjects$]).pipe(
            takeUntilDestroyed(),
            first(),
        ).subscribe(([deployments, writableProjects]) => {
            if (!deployments.length && writableProjects.length) {
                this.router.navigate(["create"], { relativeTo: this.route });
            }
        });
    }

    ngOnDestroy() {
        this.unsub$.next();
    }

    openSuspendResumeModal(action: "suspend" | "resume") {
        const selected = [...this.deployments.selected];
        const title = action === "resume"
            ? selected.length === 1 ? "Resume deployment" : "Resume deployments"
            : selected.length === 1 ? "Suspend deployment" : "Suspend deployments";
        const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
            data: {
                title: title,
                body: confirmationMessage(action, selected, "deployment"),
                confirmText: title,
                confirmButtonStyle: action === "resume" ? "primary-solid green" : "primary-solid red",
            },
        }).componentInstance;
        modal.confirmed.pipe(
            switchMap(() => {
                switch (action) {
                    case "suspend": return combineLatest(selected.map(x => this.deploymentApi.suspendDeployment(x.uuid)));
                    case "resume": return combineLatest(selected.map(x => this.deploymentApi.resumeDeployment(x.uuid)));
                }
            }),
        ).subscribe({
            next: () => {
                modal.close();
                switch (action) {
                    case "suspend": this.snackbar.success(`Deployment(s) now being suspended.`); break;
                    case "resume": this.snackbar.success(`Deployment(s) now being resumed.`); break;
                }
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openDestroyModal() {
        const selected = [...this.deployments.selected];
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: selected.length === 1 ? "Destroy deployment" : "Destroy deployments",
                body: selected.length === 1
                    ? confirmationMessage("delete", selected, "deployment") + ` All of its data will be permanently erased. This action cannot be undone!`
                    : confirmationMessage("delete", selected, "deployment") + ` All of their data will be permanently erased. This action cannot be undone!`,
                confirmText: selected.length === 1 ? "Destroy deployment" : "Destroy deployments",
                strongConfirmationString: strongConfirmationStringOf(selected),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => combineLatest(selected.map(x => this.deploymentApi.destroyDeployment(x.uuid))))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Deployment(s) now being destroyed.`);
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
