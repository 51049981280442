/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, catchError, distinctUntilChanged, first, map, Observable, of, Subject, switchMap, tap } from "rxjs";
import {
    BreadcrumbComponent, ButtonComponent, ConfirmationModalComponent, DatetimePipe, DeleteResourceSectionComponent,
    MenuItem, ModalComponent, PropertiesTableComponent, PropertiesTableRowComponent,
    StrongConfirmationModalComponent, StrongConfirmationModalData
} from "typedb-platform-framework";
import { Parameters } from "../../../concept/common";
import { AccessLevel, hasWriteAccess } from "../../../concept/iam";
import { Project, ProjectColumn } from "../../../concept/project";
import { Team } from "../../../concept/team";
import { User, UserColumn } from "../../../concept/user";
import { teamsPath } from "../../../routing/resource-paths";
import { DialogResult } from "../../../service/dialog.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { ResourceTable } from "../../../service/resource-table.service";
import { ResourceService } from "../../../service/resource.service";
import { TeamApi } from "../../../service/team/team-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { TeamController } from "../../../service/team/team-controller.service";
import { ProjectsTableComponent } from "../../project/table/projects-table.component";
import { ResourceAvailability, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { UsersTableComponent } from "../../member/table/users-table.component";
import { TeamAddUserDialogComponent, TeamAddUserDialogData } from "../add-user/team-add-user-dialog.component";
import { TeamUpdateDialogComponent, TeamUpdateDialogData } from "../update/team-update-dialog.component";
import { TablePaginatorComponent, TableToolbarAction, TableToolbarComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "tp-team-details-page",
    templateUrl: "./team-details-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        DeleteResourceSectionComponent, TablePaginatorComponent, ProjectsTableComponent, ConfirmationModalComponent,
        AsyncPipe, TableToolbarComponent, UsersTableComponent, PropertiesTableRowComponent, DatetimePipe,
        FontAwesomeModule,
    ],
})
export class TeamDetailsPageComponent implements OnInit, OnDestroy {
    readonly team$ = new BehaviorSubject<Team | null>(null);
    readonly teamAccess$ = this.team$.pipe(switchMap(team => {
        if (team) return this.teamApi.getAccessLevels([team.uuid]).pipe(map(x => x[0]));
        else return of("none" as AccessLevel);
    }));
    readonly hasWriteAccess$ = this.teamAccess$.pipe(map(x => hasWriteAccess(x)));
    readonly breadcrumb$ = this.team$.pipe(
        map((team) => {return !team ? null : { items: [team.id], url: teamsPath(this.app.requireCurrentOrg()) };}),
    );

    readonly addUserButton: TableToolbarAction = {
        text: "Add user",
        enabled: false,
        onClick: () => { this.showAddUserDialog(); }
    };
    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.team$.pipe(map(x => x ? "ready" : "loading"), catchError(() => of("failed" as const)));

    private readonly unsubMembers$ = new Subject<void>();
    members?: ResourceTable<User, UserColumn>;
    private readonly unsubProjects$ = new Subject<void>();
    projects?: ResourceTable<Project, ProjectColumn>;

    membersTableRowPopupMenuItems: (user: User) => MenuItem[] = () => [];

    constructor(
        private app: ApplicationState, private router: Router, private route: ActivatedRoute,
        private teamApi: TeamApi, private teamCtl: TeamController, private orgApi: OrgApi,
        private snackbar: SnackbarService, private dialog: MatDialog, private resourceService: ResourceService,
    ) {}

    ngOnInit() {
        this.initDataLoader();
    }

    ngOnDestroy() {
        this.unsub$.next();
        this.unsubMembers$.next();
        this.unsubProjects$.next();
    }

    initDataLoader() {
        this.team$.pipe(first(x => !!x), map(x => x!)).subscribe(team => {
            this.members = this.teamCtl.membersTable(team, this.unsubMembers$);
            this.projects = this.teamCtl.projectsTable(team, this.unsubProjects$);
            this.teamAccess$.subscribe(accessLevel => {
                this.membersTableRowPopupMenuItems = (user: User) => {
                    return hasWriteAccess(accessLevel) ? [new MenuItem("Remove from team", () => {
                        console.log("flyout menu item clicked")
                        this.teamApi.removeMember({ teamUuid: team.uuid, userUuid: user.uuid }).subscribe(() => this.members!.refresh())
                    })] : [];
                }
            });
        });

        this.route.params.pipe(
            map((params) => params["team-id"] as string),
            distinctUntilChanged(),
            tap(() => { this.unsub$.next(); }),
            switchMap((teamId) => this.teamApi.getTeam({ teamId, orgUuid: this.app.requireCurrentOrg().uuid }, of(undefined))),
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.team$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.team$.error(err);
            }
        });

        this.hasWriteAccess$.subscribe(canWrite => {
            this.addUserButton.enabled = canWrite;
        });
    }

    openEditModal() {
        this.dialog.open<TeamUpdateDialogComponent, TeamUpdateDialogData, DialogResult>(TeamUpdateDialogComponent, {
            data: { team: this.team$.value! }
        }).beforeClosed().subscribe((result) => {
            if (result === "ok") this.initDataLoader();
        });
    }

    openDeleteModal() {
        const team = this.team$.value!;
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Delete team",
                body: `Are you sure you would like to delete '${team.name}'? This action cannot be undone.`,
                confirmText: "Delete team",
                strongConfirmationString: team.id,
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.teamApi.deleteTeam(team.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Team '${team.id}' is now deleted.`);
                this.router.navigate(["../../teams"], { relativeTo: this.route });
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    showAddUserDialog() {
        const params: Parameters = { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } }

        this.orgApi.listMembers(this.app.requireCurrentOrg().uuid, params, of(undefined)).pipe(
            switchMap((res) => {
                const orgMembers = res.initial!;
                return this.dialog.open<TeamAddUserDialogComponent, TeamAddUserDialogData, DialogResult>(
                    TeamAddUserDialogComponent, { data: { orgMembers, team: this.team$.value! } }
                ).beforeClosed();
            })
        ).subscribe((result) => {
            if (result === "ok") this.initDataLoader();
        });
    }
}
