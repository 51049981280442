/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ButtonComponent, ModalComponent } from "typedb-platform-framework";

@Component({
    selector: "tp-credit-application-dialog",
    templateUrl: "./credit-application-modal.component.html",
    styleUrls: ["./credit-application-modal.component.scss"],
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormsModule, ReactiveFormsModule, ButtonComponent],
})
export class CreditApplicationModalComponent {
    constructor(public dialogRef: MatDialogRef<CreditApplicationModalComponent>) {}
}
