/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import {
    applyActionCode, Auth, User, signOut, confirmPasswordReset, verifyPasswordResetCode, createUserWithEmailAndPassword,
    signInWithEmailAndPassword, user, getIdToken, OAuthProvider, UserCredential, signInWithPopup, GoogleAuthProvider,
    getAdditionalUserInfo, GithubAuthProvider,
} from "@angular/fire/auth";
import { distinctUntilChanged, map, Observable, ReplaySubject, shareReplay, tap } from "rxjs";
import { fromPromise } from "rxjs/internal/observable/innerFrom";

export interface FirebaseUser {
    email: string | null;
}

export interface AbstractIdentityBackend {
    currentUser: FirebaseUser | null;
    currentUserStateChanges: Observable<FirebaseUser | null>;
    signUp(email: string, password: string): Observable<void>;
    signIn(email: string, password: string): Observable<void>;
    signOut(): Observable<void>;
    verify(code: string): Observable<void>;
    initiatePasswordReset(code: string, submission: string): Observable<void>;
    verifyPasswordReset(code: string): Observable<void>;
    getIdToken(): Observable<string>;
}

@Injectable({
    providedIn: "root",
})
export class GoogleIdentityBackend implements AbstractIdentityBackend {
    currentUserStateChanges = user(this.auth).pipe(
        distinctUntilChanged((previousUser, newUser) => previousUser?.uid == newUser?.uid),
        shareReplay(1),
    );
    providerUserID$ = new ReplaySubject<string>();
    providerFirstName$ = new ReplaySubject<string>();
    providerLastName$ = new ReplaySubject<string>();

    private microsoftProvider = new OAuthProvider("microsoft.com");
    private googleProvider = new GoogleAuthProvider();
    private githubProvider = new GithubAuthProvider();

    constructor(private auth: Auth) {
        this.microsoftProvider.setCustomParameters({
            prompt: "select_account"
        });
    }

    get currentUser(): User | null {
        return this.auth.currentUser;
    }

    signUp(email: string, password: string): Observable<void> {
        return fromPromise(createUserWithEmailAndPassword(this.auth, email, password)).pipe(map(() => {}));
    }

    signIn(email: string, password: string): Observable<void> {
        return fromPromise(signInWithEmailAndPassword(this.auth, email, password)).pipe(map(() => {}));
    }

    signOut(): Observable<void> {
        return fromPromise(signOut(this.auth));
    }

    verify(code: string): Observable<void> {
        return fromPromise(applyActionCode(this.auth, code));
    }

    initiatePasswordReset(code: string, submission: string): Observable<void> {
        return fromPromise(confirmPasswordReset(this.auth, code, submission));
    }

    verifyPasswordReset(code: string): Observable<void> {
        return fromPromise(verifyPasswordResetCode(this.auth, code)).pipe(map(() => {}));
    }

    getIdToken(): Observable<string> {
        if (!this.currentUser) throw "Not logged in";
        return fromPromise(getIdToken(this.currentUser, true));
    }

    microsoftLogin(): Observable<UserCredential> {
        return fromPromise(signInWithPopup(this.auth, this.microsoftProvider)).pipe(
            tap((userCredential) => {
                this.updateUserProviderNames(userCredential, "givenName", "surname");
            })
        );
    }

    googleLogin(): Observable<UserCredential> {
        return fromPromise(signInWithPopup(this.auth, this.googleProvider)).pipe(
            tap((userCredential) => {
                this.updateUserProviderNames(userCredential, "given_name", "family_name");
            })
        );
    }

    githubLogin(): Observable<UserCredential> {
        let nameKey = "name";
        let loginKey = "login";
        return fromPromise(signInWithPopup(this.auth, this.githubProvider)).pipe(
            tap((userCredential) => {
                this.updateUserProviderInfo(
                    userCredential,
                    (profile) => this.getAsStringOrUndefined(profile, nameKey)?.split(" ")?.[0],
                    (profile) => {
                        let splitName = this.getAsStringOrUndefined(profile, nameKey)?.split(" ");
                        if (splitName == undefined || splitName.length <= 1) return undefined;
                        return splitName.slice(1).join(" ");
                    },
                    (profile) => this.getAsStringOrUndefined(profile, loginKey)
                );
            })
        );
    }

    updateUserProviderNames(userCredential: UserCredential, firstNameKey: string, lastNameKey: string) {
        this.updateUserProviderInfo(
            userCredential,
            (profile) => this.getAsStringOrUndefined(profile, firstNameKey),
            (profile) => this.getAsStringOrUndefined(profile, lastNameKey),
        );
    }

    updateUserProviderInfo(
        userCredential: UserCredential,
        getFirstName: (profile: Record<string, unknown>) => string | undefined = () => undefined,
        getLastName: (profile: Record<string, unknown>) => string | undefined = () => undefined,
        getUserID: (profile: Record<string, unknown>) => string | undefined = () => undefined,
    ) {
        let credential = OAuthProvider.credentialFromResult(userCredential);
        if (!credential) return;

        let userInfo = getAdditionalUserInfo(userCredential);
        let profile = userInfo?.profile;
        if (!profile) return;

        let firstName = getFirstName(profile);
        let lastName = getLastName(profile);
        let userID = getUserID(profile);

        if (firstName) this.providerFirstName$.next(firstName);
        if (lastName) this.providerLastName$.next(lastName);
        if (userID) this.providerUserID$.next(userID);
    }

    private getAsStringOrUndefined(record: Record<string, unknown>, key: string): string | undefined {
        if (typeof record[key] == "string") return record[key] as string;
        else return undefined;
    }
}
