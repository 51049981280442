/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

export interface BaseConcept {
    uuid: string;
}

export interface BaseResource extends BaseConcept {
    id: string;
}

export interface Property {
    readonly id: string;
    readonly name: string;
    readonly attributeType: string;
    readonly ownerType?: string;
}

export type PartialWithUuid<CONCEPT extends BaseConcept> = { uuid: string } & Partial<CONCEPT>;

export type PartialWithId<CONCEPT extends BaseConcept> = { id: string } & Partial<CONCEPT>;

export type Ok = "ok";

export const ok: Ok = "ok";
