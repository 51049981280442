/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { ApiResponse, mergeChanges } from "../concept/api-response";
import { BaseConcept } from "../concept/base";
import { SnackbarService } from "./snackbar.service";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ResourceService {

    constructor(private snackbar: SnackbarService) {
    }

    processResponse<ENTITY extends BaseConcept>(props: { resource$: BehaviorSubject<ENTITY | null>, res: ApiResponse<ENTITY>, unsub$: Subject<void>, onResync: () => any }) {
        const mergeResult = mergeChanges(props.resource$.value, props.res);
        if (mergeResult.result === "shouldDelete") {
            props.unsub$.next();
            this.snackbar.warnPersistent(`This resource has been deleted.`);
        }
        else if (mergeResult.result === "shouldResync") {
            console.warn(`Received update is not valid in the current state; forcing a full resync`);
            props.unsub$.next();
            props.onResync();
            return;
        }
        props.resource$.next(mergeResult.data);
    }
}
