/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AccessLevelProto } from "../../protocol/concept";

export type AccessLevel = "none" | "read" | "write" | "admin";

const accessLevelFromProtoMap: Record<AccessLevelProto, AccessLevel> = {
    [AccessLevelProto.NONE]: "none",
    [AccessLevelProto.READ]: "read",
    [AccessLevelProto.WRITE]: "write",
    [AccessLevelProto.ADMIN]: "admin"
};

const accessLevelToProtoMap = Object.fromEntries(
    Object.entries(accessLevelFromProtoMap).map(([proto, access]) => [access, proto])
) as unknown as Record<AccessLevel, AccessLevelProto>;

export function accessLevelOf(access: AccessLevelProto): AccessLevel {
    return accessLevelFromProtoMap[access];
}

export function accessLevelProtoOf(access: AccessLevel): AccessLevelProto {
    return accessLevelToProtoMap[access];
}

export function hasReadAccess(access: AccessLevel) {
    return ["read", "write", "admin"].includes(access);
}

export function hasWriteAccess(access: AccessLevel) {
    return ["write", "admin"].includes(access);
}

export function hasAdminAccess(access: AccessLevel) {
    return access === "admin";
}
