/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, Output } from "@angular/core";
import { AsyncPipe } from "@angular/common";
import { MatSelectModule } from "@angular/material/select";
import { MatDividerModule } from "@angular/material/divider";
import { ModalComponent } from "typedb-platform-framework";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { map } from "rxjs";
import { userFullName } from "../../../../concept/user";
import { ApplicationState } from "../../../../service/application-state.service";
import { UserAvatarComponent } from "../../../user/avatar/user-avatar.component";
import { SidebarExternalLinksComponent } from "../external-links/sidebar-external-links.component";
import { SidebarLinkComponent } from "../link/sidebar-link.component";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "tp-right-sidebar",
    templateUrl: "./right-sidebar.component.html",
    styleUrls: ["./right-sidebar.component.scss"],
    standalone: true,
    imports: [
        SidebarLinkComponent, ModalComponent, AsyncPipe, SidebarExternalLinksComponent, MatSelectModule,
        MatDividerModule, FontAwesomeModule, UserAvatarComponent, MatButtonModule,
    ],
})
export class RightSidebarComponent {
    @Output() closeClick = new EventEmitter();
    readonly userId$ = this.app.currentUser$.pipe(map((user) => user?.id));
    readonly userEmail$ = this.app.currentUser$.pipe(map((user) => user?.email));
    readonly userFullName$ = this.app.currentUser$.pipe(map((user) => user ? userFullName(user) : ""));
    readonly showAccountButton$ = this.app.userAuth$.pipe(map((user) => user?.status === "profile_completed"));

    constructor(public app: ApplicationState) {}

    signOut() {
        this.app.signOut({ callDeauthUser: true, redirectToLoginPage: true }).subscribe();
    }
}
