/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerComponent } from "typedb-platform-framework";
import { UserApi } from "../../../service/user/user-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { LoginScaffoldComponent } from "../scaffold/login-scaffold.component";
import { first, map, switchMap, tap } from "rxjs";
import { FirebaseError } from "@angular/fire/app";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "tp-email-verification-page",
    templateUrl: "email-verification-page.component.html",
    standalone: true,
    imports: [LoginScaffoldComponent, SpinnerComponent, FontAwesomeModule],
})
export class EmailVerificationPageComponent implements OnInit {
    state: { status: "verifying" | "resendingEmail" } | { status: "emailSent" | "emailSendFailed", email: string } = { status: "verifying" };

    constructor(
        private router: Router, public identityAuth: IdentityAuthService, private route: ActivatedRoute,
        private snackbar: SnackbarService, private app: ApplicationState, private userApi: UserApi
    ) {}

    ngOnInit() {
        this.route.queryParamMap.pipe(map((params) => params.get("code"))).subscribe((code) => {
            if (code) {
                this.handleCode(code);
            } else {
                this.router.navigate(["/"]);
            }
        });
    }

    private handleCode(code: string) {
        this.app.userAuth$.pipe(first()).subscribe((userAuth) => {
            switch (userAuth.status) {
                case "logged_out":
                    this.router.navigate(["/"]);
                    break;
                case "invalid":
                    throw "An error has occurred";
                default:
                    this.tryVerify(code);
            }
        });
    }

    private tryVerify(code: string) {
        this.identityAuth.verify(code).pipe(
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.userApi.updateVerificationStatus(token).pipe(map(() => token))),
            tap(() => this.app.initCurrentUserSubOnUserAuthChanges())
        ).subscribe({
            next: () => {
                this.router.navigate(["/"]);
                this.snackbar.success("Your email address is now verified");
            },
            error: (err) => {
                if (err instanceof FirebaseError) {
                    if (err.code === "auth/invalid-action-code") {
                        this.snackbar.errorPersistent("This email verification link is not valid. It may have expired or already been used.");
                    } else {
                        this.snackbar.errorPersistent(err.message);
                    }
                } else {
                    this.snackbar.errorPersistent("An unexpected error occurred while verifying your email address, please try again.");
                }
                this.router.navigate(["/"]);
            }
        });
    }

    resendVerificationEmail() {
        // TODO: prevent multiple emails being sent in quick succession
        this.state = { status: "resendingEmail" };
        this.userApi.sendVerifyEmail(false).subscribe({
            next: () => {
                const email = this.identityAuth.currentUser!.email!;
                this.state = { status: "emailSent", email };
                this.snackbar.success(`A new link has been sent to ${email}.`);
            },
            error: () => {
                const email = this.identityAuth.currentUser!.email!;
                this.state = { status: "emailSendFailed", email };
            },
        });
    }

    signOut() {
        this.app.signOut({ callDeauthUser: true, redirectToLoginPage: true });
    }
}
