/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { CloudRegion } from "typedb-web-schema/lib";
import { FormOption, FormOptionGroup } from "typedb-platform-framework";
import { UserApi } from "../../../../../service/user/user-api.service";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { RegionPipe } from "../../../../deployment/region.pipe";
import { CloudRegionQuestionInstance } from "../../survey-instance";

@Component({
    selector: "tp-cloud-region-question",
    templateUrl: "./cloud-region-question.component.html",
    styleUrls: ["./cloud-region-question.component.scss"],
    standalone: true,
    imports: [MatInputModule, FormsModule, NgClass, MatIconModule, RegionPipe, NgOptimizedImage, MatSelectModule],
})
export class CloudRegionQuestionComponent {
    @Input({ required: true }) question!: CloudRegionQuestionInstance;
    @Input({ required: true }) optionGroups?: FormOptionGroup<CloudRegion>[];
    @Input({ required: true }) isFreeText = false;
    @Output() answerChanged = new EventEmitter();

    selectedOption?: FormOption<CloudRegion>;
    freeTextValueRaw = "";

    constructor(private userApi: UserApi) {}

    get selectTitle(): string {
        return this.selectedOption?.viewValue ?? "Select an option";
    }

    regionFlagUrl(region: CloudRegion): string {
        return `/assets/flags/${region.country.code.toLowerCase()}.svg`;
    }

    onSelectionChange(change: MatSelectChange) {
        this.selectedOption = this.optionGroups!.flatMap(x => x.options)
            .find(x => x.value.vendorId === (change.value as CloudRegion).vendorId);
        const answerText = this.selectedOption!.viewValue!;
        this.question.answer = answerText;
        this.userApi.setProperty(this.question.spec.customId, answerText).subscribe();
        this.answerChanged.emit();
    }

    get freeTextValue() {
        return this.freeTextValueRaw.trim() || null;
    }

    onFreeTextFieldBlur() {
        if (this.freeTextValue != null) {
            this.selectedOption = undefined;
            this.question.answer = this.freeTextValue;
            this.userApi.setProperty(this.question.spec.customId, this.freeTextValue).subscribe();
        }
    }
}
