<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    @if (user$ | async; as user) {
      <tp-breadcrumb [items]="[user.id]" url="/"/>
    }
  </div>

  <article>
    <section>
      <div class="section-title-row">
        <h2>Account Settings</h2>
        <tp-button (click)="openEditDialog()" buttonStyle="secondary stroke" [height]="40" style="float: right" buttonId="editProfile">
          <fa-icon [icon]="['fal', 'pen-to-square']"/> Edit
        </tp-button>
      </div>

      @if (user$ | async; as user) {
        <tp-properties-table>
          <tp-properties-table-row key="User ID">{{ user.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Email">{{ user.email }}</tp-properties-table-row>
          <tp-properties-table-row key="First Name">{{ user.firstName }}</tp-properties-table-row>
          <tp-properties-table-row key="Last Name">{{ user.lastName }}</tp-properties-table-row>
        </tp-properties-table>
      } @else {
        <tp-spinner/>
      }
    </section>

    <section>
      <div class="section-title-row">
        <h3>Profile Picture</h3>
      </div>

      <aside>Profile pictures are managed through <a href="https://gravatar.com" target="_blank">Gravatar</a>.</aside>
    </section>

    <section>
      <tp-table-toolbar [table]="orgs" filterGroupId="orgs" tableTitle="Organizations" [actions]="[orgCreationButton]"/>
      <tp-orgs-table [table]="orgs"/>
      <tp-table-paginator [table]="orgs"/>
    </section>

    <section>
      <tp-table-toolbar [table]="invitations" filterGroupId="invitations" tableTitle="Invitations"/>
      <tp-invitations-table [table]="invitations" [rowPopupMenuItems]="invitationsTableRowPopupMenuItems"/>
      <tp-table-paginator [table]="invitations"/>
    </section>

    <tp-delete-resource-section
      title="Delete account"
      [display]="{ text: 'Once you delete your account, there is no going back. Please be certain.' }"
      buttonText="Delete account"
      (buttonPressed)="showContactSupportDialog()"
      [buttonIcon]="['fal', 'trash-can-xmark']"
    />
  </article>
</tp-page-scaffold>
