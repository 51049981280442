<p>{{ question.spec.body }}</p>
@if (isFreeText) {
  <mat-form-field class="form-field-dense">
    <input matInput [(ngModel)]="freeTextValueRaw" (blur)="onFreeTextFieldBlur()" placeholder="Enter a value">
  </mat-form-field>
} @else {
  <mat-form-field class="tp-region-picker form-field-dense" [title]="selectTitle">
    <mat-select
      placeholder="Select an option" [disabled]="!optionGroups"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-select-trigger class="region-mat-select-trigger">
        @if (selectedOption) {
          <img [ngSrc]="regionFlagUrl(selectedOption.value)" width="20" height="15" alt="" />&nbsp;<span>{{ selectedOption.viewValue }}</span>
        }
      </mat-select-trigger>
      @for (regionGroup of optionGroups; track regionGroup) {
        <mat-optgroup [label]="regionGroup.name">
          @for (region of regionGroup.options; track region) {
            <mat-option [value]="region.value">
              <img [ngSrc]="regionFlagUrl(region.value)" width="20" height="15" alt="" />&nbsp;<span>{{ region.viewValue }}</span>
            </mat-option>
          }
        </mat-optgroup>
      }
    </mat-select>
  </mat-form-field>
}
