/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { ServerStatus, serverStatusToString } from "../../concept/deployment";

@Pipe({
    name: "serverStatus",
    standalone: true,
})
export class ServerStatusPipe implements PipeTransform {
    transform(value: ServerStatus): string {
        return serverStatusToString(value);
    }
}
