/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    ParametersProto, ParametersProtoFilter, ParametersProtoFilterOperator,
    ParametersProtoFilterValue, ParametersProtoPagination, ParametersProtoSorting, ParametersProtoSortingDirection,
} from "../../protocol/common";
import { FilterSpec } from "../framework/table";
import { Property } from "./base";

export interface Parameters {
    pagination: Pagination;
    sorting: Sorting;
    filters?: Filter[];
}

export function parametersProtoOf(parameters: Parameters): ParametersProto {
    return new ParametersProto({
        pagination: paginationProtoOf(parameters.pagination),
        sorting: sortingProtoOf(parameters.sorting),
        filters: parameters.filters?.map(filterProtoOf) || [],
    });
}

export interface Pagination {
    offset: number;
    limit: number;
}

export function paginationProtoOf(pagination: Pagination): ParametersProtoPagination {
    return new ParametersProtoPagination({
        offset: pagination.offset,
        limit: pagination.limit,
    });
}

export interface Sorting {
    readonly attributeType: string;
    readonly ownerType?: string;
    direction?: SortDirection;
}

export type SortDirection = "asc" | "desc";

export function sortingProtoOf(sorting: Sorting): ParametersProtoSorting {
    return new ParametersProtoSorting({
        attributeType: sorting.attributeType,
        ownerType: sorting.ownerType,
        direction: sortDirectionProtoOf(sorting.direction || "asc"),
    });
}

function sortDirectionProtoOf(direction: SortDirection): ParametersProtoSortingDirection {
    switch (direction) {
        case "asc": return ParametersProtoSortingDirection.ASC;
        case "desc": return ParametersProtoSortingDirection.DESC;
    }
}

export interface Filter {
    property: Property;
    operator: FilterOperator;
    value: FilterValue;
}

export type FilterOperator = "eq" | "neq" | "lt" | "lte" | "gt" | "gte" | "contains" | "regex";

export type FilterValue =
    | { boolean: boolean }
    | { long: number }
    | { double: number }
    | { string: string }
    | { date: Date }

export type RawFilterValue = string | number | boolean | Date;

export function filterOf<RAW_VALUE extends RawFilterValue>(spec: FilterSpec<RAW_VALUE>, operator: typeof spec["operators"][number], value: RAW_VALUE): Filter {
    const partialFilter = { property: spec.property, operator: operator } as const;
    switch (spec.valueType) {
        case "boolean":
            return { ...partialFilter, value: { boolean: value as boolean } };
        case "long":
            return { ...partialFilter, value: { long: value as number } };
        case "double":
            return { ...partialFilter, value: { double: value as number } };
        case "string":
            return { ...partialFilter, value: { string: value as string } };
        case "date":
            return { ...partialFilter, value: { date: value as Date } };
    }
}

const filterOperatorToStringMap: Record<FilterOperator, string> = {
    contains: "contains",
    eq: "=",
    gt: ">",
    gte: ">=",
    lt: "<",
    lte: "<=",
    neq: "!=",
    regex: "matches regex",
};

const filterOperatorFromStringMap = Object.fromEntries(
    Object.entries(filterOperatorToStringMap).map(([proto, status]) => [status, proto])
) as unknown as Record<string, FilterOperator>;

export function filterOperatorToString(operator: FilterOperator): string {
    return filterOperatorToStringMap[operator];
}

export function filterOperatorFromString(value: string): FilterOperator | null {
    return filterOperatorFromStringMap[value] || null;
}

export function filterProtoOf(filter: Filter): ParametersProtoFilter {
    return new ParametersProtoFilter({
        attributeType: filter.property.attributeType,
        ownerType: filter.property.ownerType,
        operator: filterOperatorProtoOf(filter.operator),
        value: filterValueProtoOf(filter.value),
    });
}

function filterOperatorProtoOf(operator: FilterOperator): ParametersProtoFilterOperator {
    switch (operator) {
        case "eq": return ParametersProtoFilterOperator.EQ;
        case "neq": return ParametersProtoFilterOperator.NEQ;
        case "lt": return ParametersProtoFilterOperator.LT;
        case "lte": return ParametersProtoFilterOperator.LTE;
        case "gt": return ParametersProtoFilterOperator.GT;
        case "gte": return ParametersProtoFilterOperator.GTE;
        case "contains": return ParametersProtoFilterOperator.CONTAINS;
        case "regex": return ParametersProtoFilterOperator.REGEX;
    }
}

function filterValueProtoOf(value: FilterValue): ParametersProtoFilterValue {
    if ("boolean" in value) return new ParametersProtoFilterValue({ boolean: value.boolean });
    else if ("long" in value) return new ParametersProtoFilterValue({ long: value.long });
    else if ("double" in value) return new ParametersProtoFilterValue({ double: value.double });
    else if ("string" in value) return new ParametersProtoFilterValue({ string: value.string });
    else return new ParametersProtoFilterValue({ datetime: value.date.getTime() });
}
