<tp-login-scaffold>
  <div>
    <div class="form-container">
      <p class="box-title">Verify your email</p>
      @switch (state.status) {
        @case ("verifying") {
          <tp-spinner/>
        }
        @case ("resendingEmail") {
          <tp-spinner/>
        }
        @case ("emailSent") {
          <p class="box-subtitle">A link has been sent to {{ state.email }}</p>
          <p class="box-text" style="margin-top: 16px">
            <span>Not {{ state.email }}? </span>
            <a class="box-text-clickable" href="javascript:void(0);" (click)="signOut()">Click here to sign out</a>
          </p>
          <div class="check-email-notification">
            <div class="check-email-text">
              <fa-icon [icon]="['fal', 'envelope']"/>
              Check your email for a verification link.
            </div>
            <div>
              <span class="box-text">Have you not received a link? </span>
              <a class="box-text-clickable" href="javascript:void(0);" (click)="resendVerificationEmail()">Send email again.</a>
            </div>
          </div>
        }
        @case ("emailSendFailed") {
          <p class="box-text" style="margin-top: 16px">
            <span>Not {{ state.email }}? </span>
            <a class="box-text-clickable" href="javascript:void(0);" (click)="signOut()">Click here to sign out</a>
          </p>
          <div class="check-email-notification">
            <div>
              <a class="box-text-clickable" href="javascript:void(0);" (click)="resendVerificationEmail()">Try sending email again</a>
            </div>
          </div>
        }
      }
    </div>
  </div>
</tp-login-scaffold>
