<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    @if (breadcrumb$ | async; as breadcrumb) {
      <tp-breadcrumb [items]="breadcrumb.items" [url]="breadcrumb.url"/>
    }
    <div class="flex-spacer"></div>
  </div>

  @if (project$ | async; as project) {
    <article>
      <section>
        <div class="section-title-row">
          <h2>{{ project.name }} ({{ project.id }})</h2>
          <tp-button
            (click)="openEditModal()" buttonStyle="secondary stroke" [height]="40"
            [enabled]="(hasWriteAccess$ | async) || false" buttonId="editProject"
          >
            <fa-icon [icon]="['fal', 'pen-to-square']"/> Edit
          </tp-button>
        </div>

        <tp-properties-table title="Project Details">
          <tp-properties-table-row key="Project ID">{{ project.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Project Name">{{ project.name }}</tp-properties-table-row>
          <tp-properties-table-row key="Creation Date">{{ project.createdAt | datetime }}</tp-properties-table-row>
        </tp-properties-table>
      </section>

      @if (deployments) {
        <section>
          <tp-table-toolbar [table]="deployments" filterGroupId="deployments" tableTitle="Deployments" [actions]="[createDeploymentButton]"/>
          <tp-deployments-table [table]="deployments"/>
          <tp-table-paginator [table]="deployments"/>
        </section>
      }

      @if (teams) {
        <section>
          <tp-table-toolbar [table]="teams" filterGroupId="teams" tableTitle="Teams" [actions]="[assignTeamButton]"/>
          <tp-teams-table [table]="teams" [rowPopupMenuItems]="teamsTableRowPopupMenuItems"/>
          <tp-table-paginator [table]="teams"/>
        </section>
      }

      @if (members) {
        <section>
          <tp-table-toolbar [table]="members" filterGroupId="users" tableTitle="Users" [actions]="[assignUserButton]"/>
          <tp-users-table [table]="members" [rowPopupMenuItems]="membersTableRowPopupMenuItems"/>
          <tp-table-paginator [table]="members"/>
        </section>
      }

      <tp-delete-resource-section
        title="Delete this project"
        [display]="{
            enabled: projectCanBeDeleted,
            enabledText: 'Delete the project from this organization',
            disabledText: deleteProjectDisabledText,
        }"
        buttonText="Delete"
        [buttonIcon]="['fal', 'trash-can-xmark']"
        (buttonPressed)="openDeleteModal()"
      />
    </article>
  }
</tp-page-scaffold>
