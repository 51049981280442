@if (external) {
  <a [attr.href]="link" target="blank">
    <ng-container *ngTemplateOutlet="linkspan"/>
  </a>
} @else {
  <a [attr.href]="link" target="_self" [routerLink]="link" [ngClass]="{ selected: selected$ | async, internal: true }">
    <ng-container *ngTemplateOutlet="linkspan"/>
  </a>
}

<ng-template #linkspan>
  <span class="linkspan">
    <ng-content/>
    @if (external) {
      <div class="flex-spacer"></div>
      <fa-icon [icon]="['fal', 'arrow-up-right-from-square']"/>
    }
  </span>
</ng-template>
