/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Parameters } from "../../concept/common";
import { Deployment, DeploymentColumn, deploymentProperties, deploymentPropertiesList, deploymentStatusFromProtoMap } from "../../concept/deployment";
import { AccessLevel, hasWriteAccess } from "../../concept/iam";
import { Invitation, InvitationColumn, invitationProperties, invitationPropertiesList, Organization } from "../../concept/organization";
import { Project, projectProperties, ProjectColumn, projectPropertiesList } from "../../concept/project";
import { Team, TeamColumn, teamProperties, teamPropertiesList } from "../../concept/team";
import { User, UserColumn, userProperties, userPropertiesList } from "../../concept/user";
import { longFilterSpec, stringFilterSpec } from "../../framework/table";
import { deploymentDetailsPath, memberDetailsPath, projectDetailsPath, teamDetailsPath } from "../../routing/resource-paths";
import { AuthorizationService } from "../authorization.service";
import { ResourceTable, SpecialColumn } from "../resource-table.service";
import { OrgApi } from "./org-api.service";
import { Observable, Subject, first, map, of, switchMap } from "rxjs";
import { ApiListResponse } from "../../concept/api-response";
import { ApplicationState } from "../application-state.service";
import { Property } from "../../concept/base";

@Injectable({
    providedIn: "root",
})
export class OrgController {

    constructor(private api: OrgApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    deploymentsTable(unsub$: Subject<void>): ResourceTable<Deployment, DeploymentColumn> {
        return new OrgDeploymentsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    projectsTable(unsub$: Subject<void>): ResourceTable<Project, ProjectColumn> {
        return new OrgProjectsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    membersTable(unsub$: Subject<void>): ResourceTable<User, UserColumn> {
        return new OrgMembersTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    invitationsTable(unsub$: Subject<void>): ResourceTable<Invitation, InvitationColumn> {
        return new OrgInvitationsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    teamsTable(unsub$: Subject<void>): ResourceTable<Team, TeamColumn> {
        return new OrgTeamsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    listWritableTeamsSnapshot(): Observable<Team[]> {
        return this.api.listTeams(
            this.app.requireCurrentOrg().uuid,
            { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } },
            of(undefined),
        ).pipe(
            first(res => !!res.initial),
            map(res => res.initial!),
            switchMap((teams) => {
                return this.authorization.teamAccessLevels(teams.map(x => x.uuid)).pipe(
                    map(accessLevels => accessLevels.map((level, idx) => {
                        return hasWriteAccess(level) ? idx : null;
                    })),
                    map(indexes => indexes.filter(x => x != null) as number[]),
                    map(indexes => indexes.map(x => teams[x])),
                )
            })
        )
    }

    listWritableProjectsSnapshot(): Observable<Project[]> {
        return this.api.listProjects(
            this.app.requireCurrentOrg().uuid,
            { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } },
            of(undefined)
        ).pipe(
            first(res => !!res.initial),
            map(res => res.initial!),
            switchMap((projects) => {
                return this.authorization.projectAccessLevels(projects.map(x => x.uuid)).pipe(
                    map(accessLevels => accessLevels.map((level, idx) => {
                        return hasWriteAccess(level) ? idx : null;
                    })),
                    map(indexes => indexes.filter(x => x != null) as number[]),
                    map(indexes => indexes.map(x => projects[x])),
                );
            }),
        );
    }
}

export class OrgDeploymentsTable extends ResourceTable<Deployment, DeploymentColumn> {
    override readonly properties = deploymentPropertiesList;
    override readonly primaryProperty = deploymentProperties.id;
    override displayedProperties = [...this.properties.filter(x => !["storageType", "storageSize", "typeDBVersion", "createdAt"].includes(x.id))];
    override readonly filterSpecs = [
        stringFilterSpec(deploymentProperties.id),
        stringFilterSpec(deploymentProperties.project),
        stringFilterSpec(deploymentProperties.provider),
        stringFilterSpec(deploymentProperties.region),
        stringFilterSpec(deploymentProperties.machineType),
        longFilterSpec(deploymentProperties.clusterSize),
        stringFilterSpec(deploymentProperties.storageType),
        longFilterSpec(deploymentProperties.storageSize),
        stringFilterSpec(deploymentProperties.deploymentStatus, Object.values(deploymentStatusFromProtoMap)),
        stringFilterSpec(deploymentProperties.typeDBVersion),
    ];

    constructor(private org: Organization, private orgApi: OrgApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Deployment>> {
        return this.orgApi.listDeployments(this.org.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Deployment[]): Observable<AccessLevel[]> {
        return this.authorization.deploymentAccessLevels(resources.map(x => x.uuid));
    }

    override get displayedColumns(): ("select" | "actions" | "status" | DeploymentColumn)[] {
        return ["select", ...this.displayedProperties.map(x => x.id as DeploymentColumn), "status"];
    }

    override itemRoute(deployment: Deployment): string {
        return deploymentDetailsPath(deployment, this.org);
    }

    override noAccessMessage(deployment: Deployment): string {
        return `You don't have access to the deployment '${deployment.id}'`;
    }
}

export class OrgProjectsTable extends ResourceTable<Project, ProjectColumn> {
    override readonly properties = projectPropertiesList;
    override readonly primaryProperty = projectProperties.id;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [stringFilterSpec(projectProperties.id), stringFilterSpec(projectProperties.name)];

    constructor(protected org: Organization, private orgApi: OrgApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Project>> {
        return this.orgApi.listProjects(this.org.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Project[]): Observable<AccessLevel[]> {
        return this.authorization.projectAccessLevels(resources.map(x => x.uuid));
    }

    override itemRoute(project: Project): string {
        return projectDetailsPath(project, this.org);
    }

    override noAccessMessage(project: Project): string {
        return `You don't have access to the project '${project.id}'`;
    }
}

export class OrgMembersTable extends ResourceTable<User, UserColumn> {
    override readonly properties = userPropertiesList;
    override readonly primaryProperty = userProperties.id;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(userProperties.id), stringFilterSpec(userProperties.email),
        stringFilterSpec(userProperties.firstName), stringFilterSpec(userProperties.lastName),
    ];

    constructor(private org: Organization, private orgApi: OrgApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
        // TODO
        // this.items$.pipe(
        //     switchMap((members) => {
        //         const orgAccessLevels = this.authorization.
        //     }),
        // ).subscribe();
    }

    override get displayedColumns(): (SpecialColumn | UserColumn)[] {
        return ["select", "avatar", ...this.displayedProperties.map(x => x.id)] as (SpecialColumn | UserColumn)[];
    }

    override getData(params: Parameters): Observable<ApiListResponse<User>> {
        return this.orgApi.listMembers(this.org.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: User[]): Observable<AccessLevel[]> {
        return this.authorization.userAccessLevels(resources.map(x => x.uuid));
    }

    override itemRoute(user: User): string {
        return memberDetailsPath(user, this.org);
    }

    override noAccessMessage(user: User): string {
        return `You don't have permissions to manage user '${user.id}'`;
    }
}

export class OrgTeamsTable extends ResourceTable<Team, TeamColumn> {
    override readonly properties = teamPropertiesList;
    override readonly primaryProperty = teamProperties.id;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [stringFilterSpec(teamProperties.id), stringFilterSpec(teamProperties.name)];

    constructor(protected org: Organization, private orgApi: OrgApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Team>> {
        return this.orgApi.listTeams(this.org.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Team[]): Observable<AccessLevel[]> {
        return this.authorization.teamAccessLevels(resources.map(x => x.uuid));
    }

    override itemRoute(team: Team): string {
        return teamDetailsPath(team, this.org);
    }

    override noAccessMessage(team: Team): string {
        return `You don't have access to the team '${team.id}'`;
    }
}

export class OrgInvitationsTable extends ResourceTable<Invitation, InvitationColumn> {
    override readonly properties;
    override readonly primaryProperty = invitationProperties.userEmail;
    override displayedProperties: Property[];
    override readonly filterSpecs = [stringFilterSpec(invitationProperties.userEmail)];

    constructor(protected org: Organization, private orgApi: OrgApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        const initialParams: Parameters = {
            pagination: { offset: 0, limit: 10 },
            sorting: { attributeType: "created-at", direction: "desc" },
            filters: [],
        };
        super(unsub$, initialParams);
        const properties = [...invitationPropertiesList];
        properties.remove(invitationProperties.orgId);
        properties.remove(invitationProperties.orgName);
        this.displayedProperties = [...properties];
        this.properties = properties;
    }

    override getData(params: Parameters): Observable<ApiListResponse<Invitation>> {
        return this.orgApi.listInvitations(this.org.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Invitation[]): Observable<AccessLevel[]> {
        return this.authorization.orgAccessLevels(resources.map(x => x.org.uuid));
    }

    override get displayedColumns(): ("actions" | InvitationColumn)[] {
        return [...this.displayedProperties.map(x => x.id as InvitationColumn), "actions"];
    }

    override itemRoute(): string {
        return ``;
    }

    override noAccessMessage(): string {
        return ``;
    }
}
