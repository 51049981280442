/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { CustomQuestion, isCustomQuestion, isMultipleChoiceQuestion, MultipleChoiceQuestion, Survey, SurveyQuestion, SurveySection } from "typedb-web-schema/lib";

export class SurveyInstance {
    firstCompletions = new Set<string>();
    sections: SurveySectionInstance[];

    constructor(public data: Survey) {
        this.sections = data.sections.map(x => new SurveySectionInstance(x));
    }

    get isCompleted(): boolean {
        return this.sections.every(x => x.isCompleted);
    }
}

export class SurveySectionInstance {
    readonly questions: QuestionInstance[];

    constructor(public spec: SurveySection) {
        this.questions = spec.questions.map(x => questionInstanceOf(x));
    }

    get isCompleted(): boolean {
        return this.questions.every(x => x.isAnswered);
    }
}

export interface QuestionInstance {
    spec: SurveyQuestion;
    isAnswered: boolean;
}

function questionInstanceOf(data: SurveyQuestion): QuestionInstance {
    if (isMultipleChoiceQuestion(data)) return new MultipleChoiceQuestionInstance(data);
    else if (isCustomQuestion(data)) {
        if (data.customId === CLOUD_REGION_QUESTION_ID) return new CloudRegionQuestionInstance(data);
        else throw `Unexpected custom ID for question: ${data.customId}`;
    } else throw `Unexpected question data: ${JSON.stringify(data)}`;
}

export class MultipleChoiceQuestionInstance implements QuestionInstance {
    answers: string[] = [];

    constructor(public spec: MultipleChoiceQuestion) {}

    get isAnswered() {
        return !!this.answers.length;
    }
}

export const CLOUD_PROVIDER_QUESTION_POSTHOG_PROPERTY = "cloud_provider";
export const CLOUD_REGION_QUESTION_ID = "cloud_region";

export class CloudRegionQuestionInstance implements QuestionInstance {
    answer?: string | null;

    constructor(public spec: CustomQuestion) {}

    get isAnswered() {
        return !!this.answer;
    }
}
