/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { DeploymentProto, DeploymentProtoServer, DeploymentProtoServerLogBatch, DeploymentProtoServerStatus, DeploymentProtoStatus } from "../../protocol/concept";
import { bytesToString, stringToBytesOrUndefined, stripUndefinedValues, toSentenceCase } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";
import { MachineType, machineTypeOf, machineTypeProtoOf, Provider, providerOf, providerProtoOf, Region, regionOf, regionProtoOf, StorageType, storageTypeOf, storageTypeProtoOf } from "./deployment-options";
import { partialProjectOf, Project, projectProtoOf } from "./project";

export interface Deployment extends BaseResource {
    serverCount: number;
    provider: Provider;
    region: Region;
    machineType: MachineType;
    storageType: StorageType;
    storageGB: number;
    typeDBVersion: string;
    status: DeploymentStatus;
    createdAt: Date;
    project: Project;
}

export function partialDeploymentOf(data: DeploymentProto): PartialWithUuid<Deployment> {
    const deployment: PartialWithUuid<Deployment> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        serverCount: data.hasServerCount ? data.serverCount : undefined,
        provider: data.hasProvider ? providerOf(data.provider) : undefined,
        region: data.hasRegion ? regionOf(data.region) : undefined,
        machineType: data.hasMachineType ? machineTypeOf(data.machineType) : undefined,
        storageType: data.hasStorageType ? storageTypeOf(data.storageType) : undefined,
        storageGB: data.hasStorageGb ? data.storageGb : undefined,
        typeDBVersion: data.hasTypedbCloudVersion ? data.typedbCloudVersion : undefined,
        status: data.hasStatus ? deploymentStatusOf(data.status) : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
        project: data.hasProject ? partialProjectOf(data.project) as Project : undefined,
    };
    return stripUndefinedValues(deployment) as PartialWithUuid<Deployment>;
}

export function deploymentProtoOf(data: Partial<Deployment>): DeploymentProto {
    return new DeploymentProto({
        uuid: stringToBytesOrUndefined(data.uuid),
        id: data.id,
        serverCount: data.serverCount,
        provider: data.provider ? providerProtoOf(data.provider) : undefined,
        region: data.region ? regionProtoOf(data.region) : undefined,
        machineType: data.machineType ? machineTypeProtoOf(data.machineType) : undefined,
        storageType: data.storageType ? storageTypeProtoOf(data.storageType) : undefined,
        storageGb: data.storageGB,
        typedbCloudVersion: data.typeDBVersion,
        status: data.status ? deploymentStatusProtoOf(data.status) : undefined,
        createdAt: data.createdAt ? data.createdAt.getTime() : undefined,
        project: data.project ? projectProtoOf(data.project) : undefined,
    });
}

export type DeploymentStatus =
    "starting" | "running" | "suspending" | "suspended" | "resuming" | "destroying" | "destroyed" | "error";

export const deploymentStatusFromProtoMap: Record<DeploymentProtoStatus, DeploymentStatus> = {
    [DeploymentProtoStatus.STARTING]: "starting",
    [DeploymentProtoStatus.RUNNING]: "running",
    [DeploymentProtoStatus.SUSPENDING]: "suspending",
    [DeploymentProtoStatus.SUSPENDED]: "suspended",
    [DeploymentProtoStatus.RESUMING]: "resuming",
    [DeploymentProtoStatus.DESTROYING]: "destroying",
    [DeploymentProtoStatus.DESTROYED]: "destroyed",
    [DeploymentProtoStatus.ERROR]: "error"
};

const deploymentStatusToProtoMap = Object.fromEntries(
    Object.entries(deploymentStatusFromProtoMap).map(([proto, status]) => [status, proto])
) as unknown as Record<DeploymentStatus, DeploymentProtoStatus>;

function deploymentStatusOf(status: DeploymentProtoStatus): DeploymentStatus {
    return deploymentStatusFromProtoMap[status];
}

function deploymentStatusProtoOf(status: DeploymentStatus): DeploymentProtoStatus {
    return deploymentStatusToProtoMap[status];
}

export function deploymentStatusToString(status: DeploymentStatus) {
    return toSentenceCase(status);
}

export function isInResumableState(deployment: Deployment) {
    return deployment.status === "suspended";
}

const canBeSuspendedStatuses: DeploymentStatus[] = ["starting", "running", "resuming"];

export function isInSuspendableState(deployment: Deployment) {
    return canBeSuspendedStatuses.includes(deployment.status);
}

const canBeDestroyedStatuses: DeploymentStatus[] = ["starting", "running", "resuming", "suspending", "suspended"];

export function isInDestroyableState(deployment: Deployment) {
    return canBeDestroyedStatuses.includes(deployment.status);
}

export function storageSizeToString(storageGB: number): string {
    if (storageGB < 1000) return `${storageGB} GB`;
    else return `${storageGB / 1000} TB`;
}

export type DeploymentColumn =
    | "id" | "project" | "provider" | "region" | "machineType" | "clusterSize" | "storageType" | "storageSize"
    | "deploymentStatus" | "typeDBVersion" | "createdAt";

export const deploymentProperties: Record<DeploymentColumn, Property> = {
    id: { id: "id", name: "Deployment ID", attributeType: "id" },
    project: { id: "project", name: "Project", ownerType: "project", attributeType: "id" },
    provider: { id: "provider", name: "Provider", ownerType: "provider", attributeType: "name" },
    region: { id: "region", name: "Region", ownerType: "region", attributeType: "vendor-id" },
    machineType: { id: "machineType", name: "Machine", ownerType: "machine-type", attributeType: "id" },
    clusterSize: { id: "clusterSize", name: "Servers", attributeType: "server-count" },
    storageType: { id: "storageType", name: "Storage Type", ownerType: "storage-type", attributeType: "id" },
    storageSize: { id: "storageSize", name: "Storage (GB)", attributeType: "storage-size-gb" },
    deploymentStatus: { id: "deploymentStatus", name: "Status", attributeType: "dep-status" },
    typeDBVersion: { id: "typeDBVersion", name: "TypeDB Version", ownerType: "typedb-cloud", attributeType: "version" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const deploymentPropertiesList = [
    deploymentProperties.id, deploymentProperties.project, deploymentProperties.provider, deploymentProperties.region,
    deploymentProperties.machineType, deploymentProperties.clusterSize, deploymentProperties.storageType,
    deploymentProperties.storageSize, deploymentProperties.deploymentStatus, deploymentProperties.typeDBVersion,
    deploymentProperties.createdAt
];

export interface Server {
    uuid: string;
    id: number;
    address?: string;
    status: ServerStatus;
}

export function serverOf(server: DeploymentProtoServer): Server {
    return {
        uuid: bytesToString(server.uuid),
        id: server.id,
        address: server.hasAddress ? server.address : undefined,
        status: server.hasStatus ? serverStatusOf(server.status) : "error",
    };
}

function serverProtoOf(server: Server): DeploymentProtoServer {
    return new DeploymentProtoServer({ address: server.address, status: serverStatusProtoOf(server.status) });
}

export type ServerStatus = "unknown" | "pending" | "running" | "error";

const serverStatusFromProtoMap: Record<DeploymentProtoServerStatus, ServerStatus> = {
    [DeploymentProtoServerStatus.UNKNOWN]: "unknown",
    [DeploymentProtoServerStatus.PENDING]: "pending",
    [DeploymentProtoServerStatus.RUNNING]: "running",
    [DeploymentProtoServerStatus.ERROR]: "error",
};

const serverStatusToProtoMap = Object.fromEntries(
    Object.entries(serverStatusFromProtoMap).map(([proto, status]) => [status, proto])
) as unknown as Record<ServerStatus, DeploymentProtoServerStatus>;

function serverStatusOf(status: DeploymentProtoServerStatus): ServerStatus {
    return serverStatusFromProtoMap[status];
}

function serverStatusProtoOf(status: ServerStatus): DeploymentProtoServerStatus {
    return serverStatusToProtoMap[status];
}

export function serverStatusToString(status: ServerStatus) {
    return toSentenceCase(status);
}

export interface ServerLogBatch {
    lines: string[];
    lineNumberStart: number;
    isEndOfFile: boolean;
}

export function serverLogBatchOf(proto: DeploymentProtoServerLogBatch): ServerLogBatch {
    return {
        lines: proto.lines,
        lineNumberStart: proto.lineNumberStart,
        isEndOfFile: proto.isEndOfFile
    }
}
