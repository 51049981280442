/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatChipOption, MatChipSelectionChange, MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { multiSelectOptionPosthogProperty, multiSelectOtherOptionPosthogProperty, QuestionOption } from "typedb-web-schema/lib";
import { UserApi } from "../../../../../service/user/user-api.service";
import { MultipleChoiceQuestionInstance } from "../../survey-instance";

@Component({
    selector: "tp-multiple-choice-question",
    templateUrl: "./multiple-choice-question.component.html",
    styleUrls: ["./multiple-choice-question.component.scss"],
    standalone: true,
    imports: [MatInputModule, MatChipsModule, FormsModule, NgClass, MatIconModule],
})
export class MultipleChoiceQuestionComponent {
    @Input({ required: true }) question!: MultipleChoiceQuestionInstance;
    @Output() answerChanged = new EventEmitter();
    @ViewChildren(MatChipOption) chipOptions!: QueryList<MatChipOption>;

    otherValueRaw = "";
    lastSubmittedOtherValue: string | null = null;
    otherOptionSelected = false;

    constructor(private userApi: UserApi) {}

    get otherValue() {
        return this.otherValueRaw.trim() || null;
    }

    onOptionSelectedChange(option: QuestionOption, change: MatChipSelectionChange) {
        if (change.selected) this.onOptionSelected(option);
        else this.onOptionUnselected(option);
        this.answerChanged.emit();
    }

    private onOptionSelected(option: QuestionOption) {
        if (this.question.spec.isMultiSelect) {
            this.question.answers.push(option.text);
            this.userApi.setProperty(multiSelectOptionPosthogProperty(this.question.spec, option), "true").subscribe();
        } else {
            this.question.answers = [option.text];
            this.userApi.setProperty(this.question.spec.posthogProperty, option.posthogProperty).subscribe();
            this.otherOptionSelected = false;
            this.otherValueRaw = "";
            this.lastSubmittedOtherValue = null;
        }
    }

    private onOptionUnselected(option: QuestionOption) {
        this.question.answers.remove(option.text);
        if (this.question.spec.isMultiSelect) {
            this.userApi.unsetProperty(multiSelectOptionPosthogProperty(this.question.spec, option)).subscribe();
        }
    }

    onOtherFieldBlur() {
        if (this.lastSubmittedOtherValue === this.otherValue) return;

        if (this.lastSubmittedOtherValue != null && this.question.spec.isMultiSelect) {
            this.question.answers.remove(this.lastSubmittedOtherValue);
            this.userApi.unsetProperty(multiSelectOtherOptionPosthogProperty(this.question.spec, this.lastSubmittedOtherValue)).subscribe();
        }

        this.lastSubmittedOtherValue = this.otherValue || null;
        this.otherOptionSelected = this.otherValue != null;

        if (this.otherValue != null) {
            this.question.answers.push(this.otherValue);
            if (this.question.spec.isMultiSelect) {
                this.userApi.setProperty(multiSelectOtherOptionPosthogProperty(this.question.spec, this.otherValue), "true").subscribe();
            } else {
                this.userApi.setProperty(this.question.spec.posthogProperty, this.otherValue).subscribe();
                this.chipOptions.forEach(x => x.deselect());
            }
        }

        this.answerChanged.emit();
    }
}
