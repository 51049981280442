/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SidebarLinkComponent } from "../link/sidebar-link.component";

@Component({
    selector: "tp-sidebar-external-links",
    templateUrl: "./sidebar-external-links.component.html",
    styleUrls: ["./sidebar-external-links.component.scss"],
    standalone: true,
    imports: [SidebarLinkComponent, FontAwesomeModule],
})
export class SidebarExternalLinksComponent {
}
