<mat-form-field>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <textarea #textarea matInput [formControl]="formControl" [attr.autocomplete]="autocomplete"></textarea>
  @if (hint) {
    <mat-hint>{{ hint }}</mat-hint>
  }
  @if (formControl.getError("errorText")) {
    <mat-error>{{ formControl.getError("errorText") }}</mat-error>
  }
</mat-form-field>
