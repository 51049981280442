/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { AsyncPipe } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormBuilder } from "@angular/forms";
import {
    ButtonComponent, FormActionsComponent, FormComponent, FormPasswordInputComponent, SpinnerComponent,
    confirmPasswordValidator, passwordValidator, requiredValidator
} from "typedb-platform-framework";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { LoginScaffoldComponent } from "../scaffold/login-scaffold.component";
import { Subject } from "rxjs";

type PasswordResetPhase = "loading" | "valid" | "success" | "error";

@Component({
    selector: "tp-password-reset-page",
    templateUrl: "password-reset-page.component.html",
    standalone: true,
    imports: [
        SpinnerComponent, ButtonComponent, LoginScaffoldComponent, RouterLink,
        FormActionsComponent, FormComponent, AsyncPipe, FormPasswordInputComponent, MatFormFieldModule
    ],
})
export class PasswordResetPageComponent implements OnInit {
    phase: PasswordResetPhase = "loading";
    resetCode?: string | null;

    form = this.formBuilder.nonNullable.group({
        password: ["", [passwordValidator, requiredValidator]],
        confirmPassword: ["", [requiredValidator]],
    }, { validators: [confirmPasswordValidator] });
    isSubmitting$ = new Subject<boolean>();

    constructor(
        private auth: IdentityAuthService, private route: ActivatedRoute, private formBuilder: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((params) => {
            this.resetCode = params.get("code");
            if (this.resetCode) {
                this.auth
                    .verifyPasswordReset(this.resetCode)
                    .subscribe({
                        next: () => {
                            this.phase = "valid";
                        },
                        error: () => {
                            this.phase = "error";
                        }
                    });
            } else {
                this.phase = "error";
            }
        });
    }

    onSubmit() {
        this.auth.initiatePasswordReset(this.resetCode!, this.form.value.password!).subscribe({
            next: () => {
                this.phase = "success";
            },
            error: () => {
                this.phase = "error";
            }
        });
    }
}
