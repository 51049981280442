/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { Invoice, InvoiceColumn } from "../../../concept/subscription";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { InvoiceStatusPipe } from "../invoice-status.pipe";
import { CurrencyPipe } from "../currency.pipe";
import { DatetimePipe, SpinnerComponent } from "typedb-platform-framework";

@Component({
    selector: "tp-invoices-table",
    templateUrl: "./invoices-table.component.html",
    standalone: true,
    imports: [AsyncPipe, DatetimePipe, MatTableModule, SpinnerComponent, CurrencyPipe, InvoiceStatusPipe],
})
export class InvoicesTableComponent {
    @Input({ required: true}) table!: ResourceTable<Invoice, InvoiceColumn>;

    constructor(public tableService: ResourceTableService) {}
}
