<td-topbar/>
@if (loading) {
  <tp-spinner/>
} @else {
    <section class="ls-title-body">
      @if (showPromotionalText) {
        @if (landingPage$ | async; as landingPage) {
          <tp-heading-with-highlights level="h1" [value]="landingPage.title" />
          <tp-p-with-highlights level="p1" [value]="landingPage.body" />
        }
      }
    </section>
  <section class="ls-content">
    <ng-content/>
  </section>
}
