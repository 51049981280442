<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    @if (breadcrumb$ | async; as breadcrumb) {
      <tp-breadcrumb [items]="breadcrumb.items" [url]="breadcrumb.url"/>
    }
  </div>
  @if (user$ | async; as user) {
    <article>
      <section>
        <h2 class="section-title-row">{{ user.firstName }} {{ user.lastName }} ({{user.email}})</h2>
        <tp-properties-table title="User Details">
          <tp-properties-table-row key="User ID">{{ user.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Email">{{ user.email }}</tp-properties-table-row>
          <tp-properties-table-row key="First Name">{{ user.firstName }}</tp-properties-table-row>
          <tp-properties-table-row key="Last Name">{{ user.lastName }}</tp-properties-table-row>
        </tp-properties-table>
      </section>

      @if (projects) {
        <section>
          <tp-table-toolbar [table]="projects" filterGroupId="projects" tableTitle="Projects"/>
          <tp-projects-table [table]="projects"/>
          <tp-table-paginator [table]="projects"/>
        </section>
      }

      @if (teams) {
        <section>
          <tp-table-toolbar [table]="teams" filterGroupId="teams" tableTitle="Teams"/>
          <tp-teams-table [table]="teams"/>
          <tp-table-paginator [table]="teams"/>
        </section>
      }

      <tp-delete-resource-section
        title="Remove this user" [display]="{ text: 'Remove the user from this organization.' }"
        buttonText="Remove" (buttonPressed)="beginRemoveUser()"
      />
    </article>
  }
</tp-page-scaffold>
