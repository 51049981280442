/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Parameters } from "../../concept/common";
import { AccessLevel } from "../../concept/iam";
import { Organization } from "../../concept/organization";
import {
    Credits, CreditsColumn, creditsProperties, creditsPropertiesList,
    Discount, DiscountColumn, discountProperties, discountPropertiesList,
    Invoice, InvoiceColumn, invoiceProperties, invoicePropertiesList
} from "../../concept/subscription";
import { longFilterSpec, stringFilterSpec } from "../../framework/table";
import { AuthorizationService } from "../authorization.service";
import { ResourceTable } from "../resource-table.service";
import { Observable, Subject, of } from "rxjs";
import { ApiListResponse } from "../../concept/api-response";
import { ApplicationState } from "../application-state.service";
import { SubscriptionApi } from "./subscription-api.service";

@Injectable({
    providedIn: "root",
})
export class SubscriptionController {

    constructor(private api: SubscriptionApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    invoicesTable(unsub$: Subject<void>): ResourceTable<Invoice, InvoiceColumn> {
        return new InvoicesTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    creditsTable(unsub$: Subject<void>): ResourceTable<Credits, CreditsColumn> {
        return new CreditsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    discountsTable(unsub$: Subject<void>): ResourceTable<Discount, DiscountColumn> {
        return new DiscountsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }
}

export class InvoicesTable extends ResourceTable<Invoice, InvoiceColumn> {
    override readonly properties = invoicePropertiesList;
    override readonly primaryProperty = invoiceProperties.period;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [stringFilterSpec(invoiceProperties.invoiceStatus)];

    constructor(protected org: Organization, private api: SubscriptionApi, private _authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(_params: Parameters): Observable<ApiListResponse<Invoice>> {
        return this.api.listPreviousInvoices(this.org.uuid, this.unsub$);
    }

    override getAccessLevels(resources: Invoice[]): Observable<AccessLevel[]> {
        return of(resources.map(_ => "admin" as const));
    }

    override get displayedColumns(): InvoiceColumn[] {
        return this.displayedProperties.map(x => x.id as InvoiceColumn);
    }

    override itemRoute(_invoice: Invoice): string {
        return ``;
    }

    override noAccessMessage(_invoice: Invoice): string {
        return ``;
    }
}

export class CreditsTable extends ResourceTable<Credits, CreditsColumn> {
    override readonly properties = creditsPropertiesList;
    override readonly primaryProperty = creditsProperties.amount;
    override readonly displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(creditsProperties.name),
        longFilterSpec(creditsProperties.amount),
    ]

    constructor(protected org: Organization, private api: SubscriptionApi, private _authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(_params: Parameters): Observable<ApiListResponse<Credits>> {
        return this.api.listCredits(this.org.uuid, this.unsub$);
    }

    override getAccessLevels(resources: Credits[]): Observable<AccessLevel[]> {
        return of(resources.map(_ => "admin" as const));
    }

    override get displayedColumns(): CreditsColumn[] {
        return this.displayedProperties.map(x => x.id as CreditsColumn);
    }

    override itemRoute(_credits: Credits): string {
        return ``;
    }

    override noAccessMessage(_credits: Credits): string {
        return ``;
    }
}

export class DiscountsTable extends ResourceTable<Discount, DiscountColumn> {
    override readonly properties = discountPropertiesList;
    override readonly primaryProperty = discountProperties.percentage;
    override readonly displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(creditsProperties.name),
        longFilterSpec(discountProperties.percentage),
    ]

    constructor(protected org: Organization, private api: SubscriptionApi, private _authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(_params: Parameters): Observable<ApiListResponse<Discount>> {
        return this.api.listDiscounts(this.org.uuid, this.unsub$);
    }

    override getAccessLevels(resources: Discount[]): Observable<AccessLevel[]> {
        return of(resources.map(_ => "admin" as const));
    }

    override get displayedColumns(): DiscountColumn[] {
        return this.displayedProperties.map(x => x.id as DiscountColumn);
    }

    override itemRoute(_discount: Discount): string {
        return ``;
    }

    override noAccessMessage(_discount: Discount): string {
        return ``;
    }
}
