/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { DeploymentCtlProtoGetDeploymentOptionsRes } from "../../application/protocol/deployment-controller";
import { MachineTypeProto, ProviderProto, RegionProto, StorageTypeProto } from "../../protocol/concept";

export interface DeploymentOptions {
    providers: ProviderOptions[];
    typeDBVersions: string[];
}

export interface Provider {
    id: string;
    name: string;
}

export interface ProviderOptions extends Provider {
    regions: RegionOptions[];
    isFreeAvailable: boolean;
}

export interface Region {
    id: string;
    continentName: string;
    continentOrdinal: number;
    countryCode: string;
    locationName: string;
    isFreeAvailable: boolean;
    vendorId: string;
}

export function regionToString(region: Region, form: "short" | "long" = "long"): string {
    return form === "long" ? `${region.locationName} (${region.vendorId})` : region.vendorId;
}

export interface RegionOptions extends Region {
    machineTypes: MachineType[];
    storageTypes: StorageType[];
}

export interface MachineType {
    vendorId: string;
    cpu: number;
    memGB: number;
    isFree: boolean;
}

export function machineTypeComputeString(machine: MachineType): string {
    return `${machine.cpu} vCPU, ${machine.memGB} GB RAM`;
}

export function machineTypeToString(machine: MachineType): string {
    return `${machineTypeComputeString(machine)} (${machine.vendorId})`;
}

export interface StorageType {
    vendorId: string;
    gbMin: number;
    gbMax: number;
    iops: string;
}

export function storageTypeToString(storage: StorageType): string {
    return `${storage.iops} (${storage.vendorId})`;
}

export function deploymentOptionsOf(options: DeploymentCtlProtoGetDeploymentOptionsRes): DeploymentOptions {
    return {
        providers: [options.gcp, options.aws, options.azure, options.ovh].filter(x => !!x).map(providerOptionsOf),
        typeDBVersions: options.typedbVersions,
    };
}

export function providerOf(provider: ProviderProto): Provider {
    return {
        id: provider.id,
        name: provider.name,
    };
}

export function providerProtoOf(provider: Provider): ProviderProto {
    return new ProviderProto({
        id: provider.id,
        name: provider.name,
    });
}

export function providerOptionsOf(provider: ProviderProto): ProviderOptions {
    const regions = provider.regions.map((region) => regionOptionsOf(region))
    return {
        id: provider.id,
        name: provider.name,
        isFreeAvailable: regions.some(region => region.isFreeAvailable),
        regions,
    };
}

export function regionOf(region: RegionProto): Region {
    return {
        id: region.id,
        continentName: region.continentName,
        continentOrdinal: region.continentOrdinal,
        countryCode: region.countryCode,
        locationName: region.locationName,
        isFreeAvailable: region.machineTypes.some(machine => machine.isFree),
        vendorId: region.vendorId,
    };
}

export function regionProtoOf(region: Region): RegionProto {
    return new RegionProto({
        id: region.id,
        countryCode: region.countryCode,
        locationName: region.locationName,
        vendorId: region.vendorId
    });
}

function regionOptionsOf(region: RegionProto): RegionOptions {
    return {
        id: region.id,
        continentName: region.continentName,
        continentOrdinal: region.continentOrdinal,
        countryCode: region.countryCode,
        locationName: region.locationName,
        isFreeAvailable: region.machineTypes.some(machine => machine.isFree),
        vendorId: region.vendorId,
        machineTypes: region.machineTypes.map(machineTypeOf),
        storageTypes: region.storageTypes.map(storageTypeOf)
    }
}

export function machineTypeOf(machineType: MachineTypeProto): MachineType {
    return {
        vendorId: machineType.vendorId,
        cpu: machineType.cpu,
        memGB: machineType.ramGb,
        isFree: machineType.isFree,
    }
}

export function machineTypeProtoOf(machineType: MachineType): MachineTypeProto {
    return new MachineTypeProto({ vendorId: machineType.vendorId, isFree: machineType.isFree });
}

export function storageTypeOf(storageType: StorageTypeProto): StorageType {
    return {
        vendorId: storageType.vendorId,
        gbMin: storageType.minGb,
        gbMax: storageType.maxGb,
        iops: storageType.iops,
    };
}

export function storageTypeProtoOf(storageType: StorageType): StorageTypeProto {
    return new StorageTypeProto({ vendorId: storageType.vendorId });
}
