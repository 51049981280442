/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SubscriptionApi } from "../../../service/subscription/subscription-api.service";
import { BehaviorSubject } from "rxjs";
import { AsyncPipe, NgClass } from "@angular/common";
import { IntegrationCardComponent, SpinnerComponent } from "typedb-platform-framework";

@Component({
    selector: "tp-marketplace-cards",
    standalone: true,
    imports: [FontAwesomeModule, MatTooltipModule, AsyncPipe, NgClass, IntegrationCardComponent, SpinnerComponent],
    templateUrl: "./marketplace-cards.component.html",
    styleUrl: "./marketplace-cards.component.scss"
})
export class MarketplaceCardsComponent implements OnInit {
    @Input({ required: true }) gcpAccountPresent!: boolean;
    @Input( {required: true }) azureAccountPresent!: boolean;
    @Input({ required: true }) canDisconnect!: boolean;
    @Output() cardClick = new EventEmitter<"gcp" | "aws" | "azure">();
    loaded = false;

    readonly gcpMarketplaceAddress$ = new BehaviorSubject<string | null>(null);
    readonly azureMarketplaceAddress$ = new BehaviorSubject<string | null>(null);

    constructor(private subscriptionApi: SubscriptionApi) {
    }

    ngOnInit() {
        this.fetchMarketplaceAddresses()
    }

    private fetchMarketplaceAddresses() {
        this.subscriptionApi.getMarketplaceAddresses().subscribe((addresses) => {
            this.gcpMarketplaceAddress$.next(addresses.gcp);
            this.azureMarketplaceAddress$.next(addresses.azure);
            this.loaded = true;
        })
    }
}
