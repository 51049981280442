<tp-modal dialogTitle="Invite user to organization" [isBusy]="(isSubmitting$ | async) || !(isReady$ | async)">
  @if (isReady$ | async) {
    <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
      <section class="form">
        <div class="ui-form-fields-row">
          <tp-form-input type="email" [form]="form" field="email" label="User Email"/>
          <tp-form-select label="Organization Access Level" [form]="form" field="accessLevel" [options]="accessLevelOptions"/>
        </div>
      </section>

      <section class="form">
        <div class="ui-resources-header-row">
          <h3>Teams</h3>
          <div [matTooltip]="(addTeamTooltip$ | async) || ''">
            <button type="button" mat-mini-fab color="" aria-label="add" [disabled]="!(orgHasTeams$ | async)" (click)="addTeamEntry()"><fa-icon [icon]="['fal', 'plus']"/></button>
          </div>
        </div>
        @for (teamForm of form.controls.teams.controls; track teamForm) {
          <div class="ui-form-fields-row">
            <tp-form-select label="Team" [form]="teamForm" field="resource" [options]="(teamOptions$ | async) || []"/>
            <tp-form-select label="Access Level" [form]="teamForm" field="accessLevel" [options]="accessLevelOptions"/>
            <button type="button" mat-icon-button aria-label="remove" class="ui-remove-resource" (click)="removeTeamEntryAt($index)"><fa-icon [icon]="['fal', 'trash-can-xmark']"/></button>
          </div>
        }
        @if (form.controls.teams.getError("errorText")) {
          <mat-error>{{ form.controls.teams.getError("errorText") }}</mat-error>
        }
      </section>

      <section class="form">
        <div class="ui-resources-header-row">
          <h3>Projects</h3>
          <button type="button" mat-mini-fab color="" aria-label="add" (click)="addProjectEntry()"><fa-icon [icon]="['fal', 'plus']"/></button>
        </div>
        @for (projectForm of form.controls.projects.controls; track projectForm) {
          <div class="ui-form-fields-row">
            <tp-form-select label="Project" [form]="projectForm" field="resource" [options]="(projectOptions$ | async) || []"/>
            <tp-form-select label="Access Level" [form]="projectForm" field="accessLevel" [options]="accessLevelOptions"/>
            <button type="button" mat-icon-button aria-label="remove" class="ui-remove-resource" (click)="removeProjectEntryAt($index)"><fa-icon [icon]="['fal', 'trash-can-xmark']"/></button>
          </div>
        }
        @if (form.controls.projects.getError("errorText")) {
          <mat-error>{{ form.controls.projects.getError("errorText") }}</mat-error>
        }
      </section>

      @if (form.getError("errorText")) {
        <mat-error>{{ form.getError("errorText") }}</mat-error>
      }

      <tp-form-actions
        [cancellable]="true" submitText="Invite user" [isSubmitting]="isSubmitting$ | async"
        buttonIdPrefix="invitationModal" (cancel)="close()"
      />
    </tp-form>
  }
</tp-modal>
