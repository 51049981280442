/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable, Subject, forkJoin, map } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, FormOption, FormSelectComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { AccessLevel } from "../../../concept/iam";
import { Project } from "../../../concept/project";
import { Team } from "../../../concept/team";
import { emailPattern, emailPatternErrorText } from "../../../util";
import { DialogResult } from "../../../service/dialog.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { ApplicationState } from "../../../service/application-state.service";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { SnackbarService } from "../../../service/snackbar.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

type Resource = Project | Team;
type ResourceFormGroup = FormGroup<{ resource: FormControl<Resource | null>, accessLevel: FormControl<AccessLevel | null>}>;

const resourcesUniqueValidator: (resourceType: string) => ValidatorFn = (resourceType) => (data) => {
    const entries = (data as FormArray<ResourceFormGroup>).controls;
    const resources = entries.map(x => x.value.resource);
    if (resources.some(x => x == null)) return null; // fall back to individual field validation
    let resourceUuids = {} as Record<string, true>;
    for (const resource of (resources as Resource[])) {
        if (resource.uuid in resourceUuids) {
            return { errorText: `The ${resourceType} '${resource.id}' has been added twice` }
        }
        resourceUuids[resource.uuid] = true;
    }
    return null;
};

@Component({
    selector: "tp-invitation-dialog",
    templateUrl: "./invitation-dialog.component.html",
    styleUrls: ["./invitation-dialog.component.scss"],
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormSelectComponent, FormActionsComponent, MatTooltipModule,
        FormsModule, ReactiveFormsModule, FormComponent, MatButtonModule, MatFormFieldModule,
        FontAwesomeModule,
    ],
})
export class InvitationDialogComponent {
    readonly form = this.formBuilder.group({
        email: ["", [patternValidator(emailPattern, emailPatternErrorText), requiredValidator]],
        accessLevel: ["read" as AccessLevel, [requiredValidator]],
        teams: this.formBuilder.array([] as ResourceFormGroup[], [resourcesUniqueValidator("team")]),
        projects: this.formBuilder.array([] as ResourceFormGroup[], [resourcesUniqueValidator("project")]),
    }, {
        validators: [(form) => {
            return form.value.accessLevel === "admin" || form.value.teams.length || form.value.projects.length ? null : { errorText: "Must select at least one team or project (or grant admin access)" };
        }],
    });
    readonly teams$ = this.orgCtl.listWritableTeamsSnapshot();
    readonly teamOptions$: Observable<FormOption<Team>[]> = this.teams$.pipe(map(teams => teams.map(team => ({
        value: team, viewValue: team.id,
    }))));
    readonly orgHasTeams$: Observable<boolean> = this.teams$.pipe(map(x => !!x.length));
    readonly addTeamTooltip$: Observable<string> = this.orgHasTeams$.pipe(map(x => x ? "" : "This organization has no teams"));
    readonly projects$ = this.orgCtl.listWritableProjectsSnapshot();
    readonly projectOptions$: Observable<FormOption<Project>[]> = this.projects$.pipe(map(projects => projects.map(project => ({
        value: project, viewValue: project.id,
    }))));
    readonly accessLevelOptions: FormOption<AccessLevel>[] = (["read", "write", "admin"] as const).map(x => ({ value: x, viewValue: x[0].toUpperCase() + x.slice(1) }));
    readonly isReady$ = forkJoin([this.teams$, this.projects$]).pipe(map(() => true));
    readonly isSubmitting$ = new Subject<boolean>();

    constructor(
        private app: ApplicationState, private dialogRef: MatDialogRef<InvitationDialogComponent, DialogResult>,
        private router: Router, private formBuilder: FormBuilder, private orgCtl: OrgController, private orgApi: OrgApi,
        private snackbar: SnackbarService,
    ) {
        forkJoin([this.teamOptions$, this.projectOptions$]).subscribe(([teams, _projects]) => {
            if (teams.length) this.addTeamEntry();
            else this.addProjectEntry();
        });
    }

    addProjectEntry() {
        this.form.controls.projects.push(this.newResourceEntry());
    }

    removeProjectEntryAt(idx: number) {
        this.form.controls.projects.removeAt(idx);
    }

    addTeamEntry() {
        this.form.controls.teams.push(this.newResourceEntry());
    }

    removeTeamEntryAt(idx: number) {
        this.form.controls.teams.removeAt(idx);
    }

    private newResourceEntry() {
        return this.formBuilder.group({
            resource: [null as Resource | null, [requiredValidator]],
            accessLevel: ["read" as AccessLevel, [requiredValidator]],
        });
    }

    submit() {
        const org = this.app.requireCurrentOrg();
        this.orgApi.inviteMember({
            orgUuid: org.uuid,
            userEmail: this.form.value.email!,
            accessLevel: this.form.value.accessLevel!,
            teams: this.form.value.teams!.map(x => ({
                uuid: x.resource!.uuid,
                accessLevel: x.accessLevel!,
            })),
            projects: this.form.value.projects!.map(x => ({
                uuid: x.resource!.uuid,
                accessLevel: x.accessLevel!,
            })),
        }).subscribe({
            next: () => {
                this.snackbar.success(`An invitation email has been sent to '${this.form.value.email}'`);
                this.close("ok");
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
