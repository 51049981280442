/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, Input } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ResourceTable } from "../../../service/resource-table.service";
import { BannerComponent } from "../banner/banner.component";
import { SidebarExternalLinksComponent } from "../sidebar/external-links/sidebar-external-links.component";
import { SidebarComponent } from "../sidebar/sidebar.component";
import { TopbarComponent } from "../topbar/topbar.component";
import { Observable, combineLatest, map } from "rxjs";
import { SpinnerComponent } from "typedb-platform-framework";

export type ResourceAvailability = "ready" | "loading" | "failed";

export function resourceAvailabilityStreamOf(...resourceTables: ResourceTable<any, any>[]): Observable<ResourceAvailability> {
    return combineLatest(resourceTables.map(x => x.ready$)).pipe(
        map(loadingStates => loadingStates.some(y => !y) ? "loading" : "ready")
    );
}

@Component({
    selector: "tp-page-scaffold",
    templateUrl: "./page-scaffold.component.html",
    styleUrls: ["./page-scaffold.component.scss"],
    standalone: true,
    imports: [
        SidebarComponent, TopbarComponent, SpinnerComponent, SidebarExternalLinksComponent, BannerComponent,
        MatSidenavModule, MatToolbarModule
    ],
})
export class PageScaffoldComponent {
    @Input() pageAvailability: ResourceAvailability | null = "ready";
}
