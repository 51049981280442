<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    <a routerLink="create" [relativeTo]="route">
      <tp-button
        [matTooltip]="(cannotCreateReason$ | async) || ''" buttonStyle="secondary"
        [enabled]="(createEnabled$ | async) || false" buttonId="createDeployment"
      >
        <fa-icon [icon]="['fal', 'square-plus']"/> Create
      </tp-button>
    </a>
    <tp-button
      [matTooltip]="(cannotSuspendReason$ | async) || ''" buttonStyle="secondary" buttonId="suspendDeployment"
      [enabled]="(suspendEnabled$ | async) || false" (click)="openSuspendResumeModal('suspend')"
    >
      <fa-icon [icon]="['fal', 'circle-pause']"/> Suspend
    </tp-button>
    <tp-button
      [matTooltip]="(cannotResumeReason$ | async) || ''" buttonStyle="secondary" buttonId="resumeDeployment"
      [enabled]="(resumeEnabled$ | async) || false" (click)="openSuspendResumeModal('resume')"
    >
      <fa-icon [icon]="['fal', 'circle-play']"/> Resume
    </tp-button>
    <tp-button
      [matTooltip]="(cannotDestroyReason$ | async) || ''" buttonStyle="secondary" buttonId="destroyDeployment"
      [enabled]="(destroyEnabled$ | async) || false" (click)="openDestroyModal()"
    >
      <fa-icon [icon]="['fal', 'trash-can-xmark']"/> Destroy
    </tp-button>
  </div>
  <article>
    <section>
      <tp-table-toolbar [table]="deployments" filterGroupId="deployments" tableTitle="Deployments"/>
      <tp-deployments-table [table]="deployments"/>
      <tp-table-paginator [table]="deployments"/>
    </section>
  </article>
</tp-page-scaffold>
