/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Location as NgLocation } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { map, Observable, Subject, switchMap, tap } from "rxjs";
import { ActivatedRoute, Router, UrlTree, RouterLink, UrlSegment, Params } from "@angular/router";

import {
    ButtonComponent, FormActionsComponent, FormComponent, FormInputComponent, FormPasswordInputComponent,
    SpinnerComponent, passwordValidator, patternValidator, requiredValidator
} from "typedb-platform-framework";
import { emailPattern, emailPatternErrorText } from "../../../util";
import { AnalyticsService } from "../../../service/analytics.service";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { UserApi } from "../../../service/user/user-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { LoginScaffoldComponent } from "../scaffold/login-scaffold.component";
import { AsyncPipe } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { EMAIL, LOGOUT_REASON, RETURN_TO } from "../../../framework/url-params";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

const AUTHENTICATION_FAILED = "Authentication failed, please try again";

@Component({
    selector: "tp-login-page",
    templateUrl: "login-page.component.html",
    standalone: true,
    imports: [
        SpinnerComponent, RouterLink, ButtonComponent, LoginScaffoldComponent, AsyncPipe,
        FormComponent, FormActionsComponent, FormInputComponent, FormPasswordInputComponent, MatFormFieldModule,
        FontAwesomeModule
    ],
})
export class LoginPageComponent implements OnInit {
    private redirectTo?: UrlTree;
    selectedTab$!: Observable<"sign-in" | "sign-up">;
    signInForm = this.formBuilder.nonNullable.group({
        email: ["", [patternValidator(emailPattern, emailPatternErrorText), requiredValidator]],
        password: ["", [passwordValidator, requiredValidator]],
    });
    signUpForm = this.formBuilder.nonNullable.group({
        email: ["", [patternValidator(emailPattern, emailPatternErrorText), requiredValidator]],
        password: ["", [passwordValidator, requiredValidator]],
    });
    isSubmitting$ = new Subject<boolean>();

    constructor(
        private identityAuth: IdentityAuthService, private route: ActivatedRoute, private app: ApplicationState,
        private router: Router, private platformAuth: PlatformAuthService, private snackbar: SnackbarService,
        private location: NgLocation, private userApi: UserApi, private formBuilder: FormBuilder,
        private analytics: AnalyticsService,
    ) {}

    public ngOnInit() {
        this.selectedTab$ = this.route.url.pipe(map((url: UrlSegment[]) => url[0].path as "sign-in" | "sign-up"));
        this.route.queryParamMap.subscribe((params) => {
            const [returnToUrl, logoutReason, email] = [params.get(RETURN_TO), params.get(LOGOUT_REASON), params.get(EMAIL)];
            if (returnToUrl) {
                this.redirectTo = this.router.parseUrl(returnToUrl);
            }
            if (logoutReason === "invalid_token") {
                this.snackbar.warn(`The previously logged-in user session is no longer valid; please sign in again to continue.`);
                const newQueryParams: Params = {};
                if (params.has(RETURN_TO)) newQueryParams[RETURN_TO] = params.get(RETURN_TO);
                const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: newQueryParams }).toString();
                this.location.go(url);
            }
            if (email) {
                this.signInForm.patchValue({ email });
            }
        });
    }

    microsoftSignIn() {
        this.processSignIn(this.identityAuth.microsoftLogin());
    }

    googleSignIn() {
        this.processSignIn(this.identityAuth.googleLogin());
    }

    githubSignIn() {
        this.processSignIn(this.identityAuth.githubLogin());
    }

    onSubmitSignInForm() {
        this.processSignIn(this.identityAuth.signIn(this.signInForm.value.email!, this.signInForm.value.password!));
    }

    processSignIn(signInObservable: Observable<unknown>) {
        signInObservable.pipe(
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
        ).subscribe({
            next: (userAuth) => {
                this.app.userAuth$.next(userAuth);
                if (this.redirectTo) this.router.navigateByUrl(this.redirectTo);
                else this.router.navigate(["/"]);
            },
            error: (err) => {
                if (["auth/email-already-in-use", "auth/account-exists-with-different-credential"].includes(err?.code))
                    this.snackbar.errorPersistent("A user with the given email already exists");
                else if (["auth/user-not-found", "auth/wrong-password"].includes(err?.code)) this.snackbar.errorPersistent(
                    "No user exists with the provided email address and password"
                );
                else if (err?.code === "auth/user-disabled") this.snackbar.errorPersistent(
                    "This account has been disabled. If you didn't request this, please contact support at support@typedb.com"
                );
                else if (err?.code !== "auth/popup-closed-by-user") this.snackbar.errorPersistent(err?.message || err?.toString() || AUTHENTICATION_FAILED);
                this.isSubmitting$.next(false);
            }
        });
    }

    onSubmitSignUpForm() {
        const [email, password] = [this.signUpForm.value.email!, this.signUpForm.value.password!];
        this.identityAuth.signUp(email, password).pipe(
            switchMap(() => this.userApi.signUp(email)),
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap((userAuth) => this.app.userAuth$.next(userAuth)),
            switchMap((userAuth) => {
                if (userAuth.status === "valid") return this.userApi.sendVerifyEmail(true);
                else {
                    console.warn(`Expected user with status 'unverified', but was:`, userAuth);
                    throw AUTHENTICATION_FAILED;
                }
            })
        ).subscribe({
            next: () => {
                this.router.navigate(["/"]);
                this.analytics.google.reportAdConversion("signup");
            },
            error: (err) => {
                if (["auth/email-already-in-use", "auth/account-exists-with-different-credential"].includes(err?.code)) {
                    this.snackbar.errorPersistent("A user with the given email already exists");
                }
                else this.snackbar.errorPersistent(err.toString() || AUTHENTICATION_FAILED);
                this.isSubmitting$.next(false);
            }
        });
    }
}
