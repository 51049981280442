/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { Credits } from "../../concept/subscription";

@Pipe({
    name: "creditsStatus",
    standalone: true,
})
export class CreditsStatusPipe implements PipeTransform {
    transform(value: Credits): string {
        let now = Date.now()
        if (value.startTime.getTime() > now) return "Starts in future"
        if (value.endTime.getTime() < now) return "Expired"
        if (value.amount == 0) return "Used"
        return "Active"
    }
}
