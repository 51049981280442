/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/project-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/concept";
import * as dependency_2 from "./../../protocol/common";
import * as pb_1 from "google-protobuf";
export class ProjectCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProto {
        const message = new ProjectCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProto {
        return ProjectCtlProto.deserialize(bytes);
    }
}
export class ProjectCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: ProjectCtlProtoGetAccessLevelsReq;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: ProjectCtlProtoCheckProjectIDReq;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: ProjectCtlProtoCreateProjectReq;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: ProjectCtlProtoGetProjectReq;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: ProjectCtlProtoUpdateProjectReq;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: ProjectCtlProtoDeleteProjectReq;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: ProjectCtlProtoListDeploymentsReq;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: ProjectCtlProtoListTeamsReq;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: ProjectCtlProtoListUsersReq;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: ProjectCtlProtoAssignUserReq;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: ProjectCtlProtoUnassignUserReq;
        assignTeam?: never;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: ProjectCtlProtoAssignTeamReq;
        unassignTeam?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        createProject?: never;
        getProject?: never;
        updateProject?: never;
        deleteProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
        assignUser?: never;
        unassignUser?: never;
        assignTeam?: never;
        unassignTeam?: ProjectCtlProtoUnassignTeamReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkProjectId" in data && data.checkProjectId != undefined) {
                this.checkProjectId = data.checkProjectId;
            }
            if ("createProject" in data && data.createProject != undefined) {
                this.createProject = data.createProject;
            }
            if ("getProject" in data && data.getProject != undefined) {
                this.getProject = data.getProject;
            }
            if ("updateProject" in data && data.updateProject != undefined) {
                this.updateProject = data.updateProject;
            }
            if ("deleteProject" in data && data.deleteProject != undefined) {
                this.deleteProject = data.deleteProject;
            }
            if ("listDeployments" in data && data.listDeployments != undefined) {
                this.listDeployments = data.listDeployments;
            }
            if ("listTeams" in data && data.listTeams != undefined) {
                this.listTeams = data.listTeams;
            }
            if ("listUsers" in data && data.listUsers != undefined) {
                this.listUsers = data.listUsers;
            }
            if ("assignUser" in data && data.assignUser != undefined) {
                this.assignUser = data.assignUser;
            }
            if ("unassignUser" in data && data.unassignUser != undefined) {
                this.unassignUser = data.unassignUser;
            }
            if ("assignTeam" in data && data.assignTeam != undefined) {
                this.assignTeam = data.assignTeam;
            }
            if ("unassignTeam" in data && data.unassignTeam != undefined) {
                this.unassignTeam = data.unassignTeam;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoGetAccessLevelsReq, 1) as ProjectCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: ProjectCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkProjectId() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoCheckProjectIDReq, 2) as ProjectCtlProtoCheckProjectIDReq;
    }
    set checkProjectId(value: ProjectCtlProtoCheckProjectIDReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckProjectId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get createProject() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoCreateProjectReq, 3) as ProjectCtlProtoCreateProjectReq;
    }
    set createProject(value: ProjectCtlProtoCreateProjectReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasCreateProject() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getProject() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoGetProjectReq, 4) as ProjectCtlProtoGetProjectReq;
    }
    set getProject(value: ProjectCtlProtoGetProjectReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetProject() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get updateProject() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoUpdateProjectReq, 5) as ProjectCtlProtoUpdateProjectReq;
    }
    set updateProject(value: ProjectCtlProtoUpdateProjectReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasUpdateProject() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get deleteProject() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoDeleteProjectReq, 6) as ProjectCtlProtoDeleteProjectReq;
    }
    set deleteProject(value: ProjectCtlProtoDeleteProjectReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasDeleteProject() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get listDeployments() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoListDeploymentsReq, 7) as ProjectCtlProtoListDeploymentsReq;
    }
    set listDeployments(value: ProjectCtlProtoListDeploymentsReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListDeployments() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listTeams() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoListTeamsReq, 8) as ProjectCtlProtoListTeamsReq;
    }
    set listTeams(value: ProjectCtlProtoListTeamsReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListTeams() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get listUsers() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoListUsersReq, 9) as ProjectCtlProtoListUsersReq;
    }
    set listUsers(value: ProjectCtlProtoListUsersReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasListUsers() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get assignUser() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoAssignUserReq, 10) as ProjectCtlProtoAssignUserReq;
    }
    set assignUser(value: ProjectCtlProtoAssignUserReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasAssignUser() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get unassignUser() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoUnassignUserReq, 11) as ProjectCtlProtoUnassignUserReq;
    }
    set unassignUser(value: ProjectCtlProtoUnassignUserReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasUnassignUser() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get assignTeam() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoAssignTeamReq, 12) as ProjectCtlProtoAssignTeamReq;
    }
    set assignTeam(value: ProjectCtlProtoAssignTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasAssignTeam() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get unassignTeam() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoUnassignTeamReq, 13) as ProjectCtlProtoUnassignTeamReq;
    }
    set unassignTeam(value: ProjectCtlProtoUnassignTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasUnassignTeam() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkProjectId" | "createProject" | "getProject" | "updateProject" | "deleteProject" | "listDeployments" | "listTeams" | "listUsers" | "assignUser" | "unassignUser" | "assignTeam" | "unassignTeam";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkProjectId",
            3: "createProject",
            4: "getProject",
            5: "updateProject",
            6: "deleteProject",
            7: "listDeployments",
            8: "listTeams",
            9: "listUsers",
            10: "assignUser",
            11: "unassignUser",
            12: "assignTeam",
            13: "unassignTeam"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof ProjectCtlProtoGetAccessLevelsReq.prototype.toObject>;
        checkProjectId?: ReturnType<typeof ProjectCtlProtoCheckProjectIDReq.prototype.toObject>;
        createProject?: ReturnType<typeof ProjectCtlProtoCreateProjectReq.prototype.toObject>;
        getProject?: ReturnType<typeof ProjectCtlProtoGetProjectReq.prototype.toObject>;
        updateProject?: ReturnType<typeof ProjectCtlProtoUpdateProjectReq.prototype.toObject>;
        deleteProject?: ReturnType<typeof ProjectCtlProtoDeleteProjectReq.prototype.toObject>;
        listDeployments?: ReturnType<typeof ProjectCtlProtoListDeploymentsReq.prototype.toObject>;
        listTeams?: ReturnType<typeof ProjectCtlProtoListTeamsReq.prototype.toObject>;
        listUsers?: ReturnType<typeof ProjectCtlProtoListUsersReq.prototype.toObject>;
        assignUser?: ReturnType<typeof ProjectCtlProtoAssignUserReq.prototype.toObject>;
        unassignUser?: ReturnType<typeof ProjectCtlProtoUnassignUserReq.prototype.toObject>;
        assignTeam?: ReturnType<typeof ProjectCtlProtoAssignTeamReq.prototype.toObject>;
        unassignTeam?: ReturnType<typeof ProjectCtlProtoUnassignTeamReq.prototype.toObject>;
    }): ProjectCtlProtoReq {
        const message = new ProjectCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = ProjectCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.checkProjectId != null) {
            message.checkProjectId = ProjectCtlProtoCheckProjectIDReq.fromObject(data.checkProjectId);
        }
        if (data.createProject != null) {
            message.createProject = ProjectCtlProtoCreateProjectReq.fromObject(data.createProject);
        }
        if (data.getProject != null) {
            message.getProject = ProjectCtlProtoGetProjectReq.fromObject(data.getProject);
        }
        if (data.updateProject != null) {
            message.updateProject = ProjectCtlProtoUpdateProjectReq.fromObject(data.updateProject);
        }
        if (data.deleteProject != null) {
            message.deleteProject = ProjectCtlProtoDeleteProjectReq.fromObject(data.deleteProject);
        }
        if (data.listDeployments != null) {
            message.listDeployments = ProjectCtlProtoListDeploymentsReq.fromObject(data.listDeployments);
        }
        if (data.listTeams != null) {
            message.listTeams = ProjectCtlProtoListTeamsReq.fromObject(data.listTeams);
        }
        if (data.listUsers != null) {
            message.listUsers = ProjectCtlProtoListUsersReq.fromObject(data.listUsers);
        }
        if (data.assignUser != null) {
            message.assignUser = ProjectCtlProtoAssignUserReq.fromObject(data.assignUser);
        }
        if (data.unassignUser != null) {
            message.unassignUser = ProjectCtlProtoUnassignUserReq.fromObject(data.unassignUser);
        }
        if (data.assignTeam != null) {
            message.assignTeam = ProjectCtlProtoAssignTeamReq.fromObject(data.assignTeam);
        }
        if (data.unassignTeam != null) {
            message.unassignTeam = ProjectCtlProtoUnassignTeamReq.fromObject(data.unassignTeam);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof ProjectCtlProtoGetAccessLevelsReq.prototype.toObject>;
            checkProjectId?: ReturnType<typeof ProjectCtlProtoCheckProjectIDReq.prototype.toObject>;
            createProject?: ReturnType<typeof ProjectCtlProtoCreateProjectReq.prototype.toObject>;
            getProject?: ReturnType<typeof ProjectCtlProtoGetProjectReq.prototype.toObject>;
            updateProject?: ReturnType<typeof ProjectCtlProtoUpdateProjectReq.prototype.toObject>;
            deleteProject?: ReturnType<typeof ProjectCtlProtoDeleteProjectReq.prototype.toObject>;
            listDeployments?: ReturnType<typeof ProjectCtlProtoListDeploymentsReq.prototype.toObject>;
            listTeams?: ReturnType<typeof ProjectCtlProtoListTeamsReq.prototype.toObject>;
            listUsers?: ReturnType<typeof ProjectCtlProtoListUsersReq.prototype.toObject>;
            assignUser?: ReturnType<typeof ProjectCtlProtoAssignUserReq.prototype.toObject>;
            unassignUser?: ReturnType<typeof ProjectCtlProtoUnassignUserReq.prototype.toObject>;
            assignTeam?: ReturnType<typeof ProjectCtlProtoAssignTeamReq.prototype.toObject>;
            unassignTeam?: ReturnType<typeof ProjectCtlProtoUnassignTeamReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkProjectId != null) {
            data.checkProjectId = this.checkProjectId.toObject();
        }
        if (this.createProject != null) {
            data.createProject = this.createProject.toObject();
        }
        if (this.getProject != null) {
            data.getProject = this.getProject.toObject();
        }
        if (this.updateProject != null) {
            data.updateProject = this.updateProject.toObject();
        }
        if (this.deleteProject != null) {
            data.deleteProject = this.deleteProject.toObject();
        }
        if (this.listDeployments != null) {
            data.listDeployments = this.listDeployments.toObject();
        }
        if (this.listTeams != null) {
            data.listTeams = this.listTeams.toObject();
        }
        if (this.listUsers != null) {
            data.listUsers = this.listUsers.toObject();
        }
        if (this.assignUser != null) {
            data.assignUser = this.assignUser.toObject();
        }
        if (this.unassignUser != null) {
            data.unassignUser = this.unassignUser.toObject();
        }
        if (this.assignTeam != null) {
            data.assignTeam = this.assignTeam.toObject();
        }
        if (this.unassignTeam != null) {
            data.unassignTeam = this.unassignTeam.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckProjectId)
            writer.writeMessage(2, this.checkProjectId, () => this.checkProjectId.serialize(writer));
        if (this.hasCreateProject)
            writer.writeMessage(3, this.createProject, () => this.createProject.serialize(writer));
        if (this.hasGetProject)
            writer.writeMessage(4, this.getProject, () => this.getProject.serialize(writer));
        if (this.hasUpdateProject)
            writer.writeMessage(5, this.updateProject, () => this.updateProject.serialize(writer));
        if (this.hasDeleteProject)
            writer.writeMessage(6, this.deleteProject, () => this.deleteProject.serialize(writer));
        if (this.hasListDeployments)
            writer.writeMessage(7, this.listDeployments, () => this.listDeployments.serialize(writer));
        if (this.hasListTeams)
            writer.writeMessage(8, this.listTeams, () => this.listTeams.serialize(writer));
        if (this.hasListUsers)
            writer.writeMessage(9, this.listUsers, () => this.listUsers.serialize(writer));
        if (this.hasAssignUser)
            writer.writeMessage(10, this.assignUser, () => this.assignUser.serialize(writer));
        if (this.hasUnassignUser)
            writer.writeMessage(11, this.unassignUser, () => this.unassignUser.serialize(writer));
        if (this.hasAssignTeam)
            writer.writeMessage(12, this.assignTeam, () => this.assignTeam.serialize(writer));
        if (this.hasUnassignTeam)
            writer.writeMessage(13, this.unassignTeam, () => this.unassignTeam.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = ProjectCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkProjectId, () => message.checkProjectId = ProjectCtlProtoCheckProjectIDReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.createProject, () => message.createProject = ProjectCtlProtoCreateProjectReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getProject, () => message.getProject = ProjectCtlProtoGetProjectReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.updateProject, () => message.updateProject = ProjectCtlProtoUpdateProjectReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.deleteProject, () => message.deleteProject = ProjectCtlProtoDeleteProjectReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listDeployments, () => message.listDeployments = ProjectCtlProtoListDeploymentsReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listTeams, () => message.listTeams = ProjectCtlProtoListTeamsReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.listUsers, () => message.listUsers = ProjectCtlProtoListUsersReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.assignUser, () => message.assignUser = ProjectCtlProtoAssignUserReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.unassignUser, () => message.unassignUser = ProjectCtlProtoUnassignUserReq.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.assignTeam, () => message.assignTeam = ProjectCtlProtoAssignTeamReq.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.unassignTeam, () => message.unassignTeam = ProjectCtlProtoUnassignTeamReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoReq {
        return ProjectCtlProtoReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 4, 7, 8, 9]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: ProjectCtlProtoGetAccessLevelsRes;
        checkProjectId?: never;
        getProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: ProjectCtlProtoCheckProjectIDRes;
        getProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        getProject?: ProjectCtlProtoGetProjectRes;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        getProject?: never;
        listDeployments?: ProjectCtlProtoListDeploymentsRes;
        listTeams?: never;
        listUsers?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        getProject?: never;
        listDeployments?: never;
        listTeams?: ProjectCtlProtoListTeamsRes;
        listUsers?: never;
    } | {
        getAccessLevels?: never;
        checkProjectId?: never;
        getProject?: never;
        listDeployments?: never;
        listTeams?: never;
        listUsers?: ProjectCtlProtoListUsersRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkProjectId" in data && data.checkProjectId != undefined) {
                this.checkProjectId = data.checkProjectId;
            }
            if ("getProject" in data && data.getProject != undefined) {
                this.getProject = data.getProject;
            }
            if ("listDeployments" in data && data.listDeployments != undefined) {
                this.listDeployments = data.listDeployments;
            }
            if ("listTeams" in data && data.listTeams != undefined) {
                this.listTeams = data.listTeams;
            }
            if ("listUsers" in data && data.listUsers != undefined) {
                this.listUsers = data.listUsers;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoGetAccessLevelsRes, 1) as ProjectCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: ProjectCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkProjectId() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoCheckProjectIDRes, 2) as ProjectCtlProtoCheckProjectIDRes;
    }
    set checkProjectId(value: ProjectCtlProtoCheckProjectIDRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckProjectId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getProject() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoGetProjectRes, 4) as ProjectCtlProtoGetProjectRes;
    }
    set getProject(value: ProjectCtlProtoGetProjectRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetProject() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get listDeployments() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoListDeploymentsRes, 7) as ProjectCtlProtoListDeploymentsRes;
    }
    set listDeployments(value: ProjectCtlProtoListDeploymentsRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListDeployments() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listTeams() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoListTeamsRes, 8) as ProjectCtlProtoListTeamsRes;
    }
    set listTeams(value: ProjectCtlProtoListTeamsRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListTeams() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get listUsers() {
        return pb_1.Message.getWrapperField(this, ProjectCtlProtoListUsersRes, 9) as ProjectCtlProtoListUsersRes;
    }
    set listUsers(value: ProjectCtlProtoListUsersRes) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasListUsers() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkProjectId" | "getProject" | "listDeployments" | "listTeams" | "listUsers";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkProjectId",
            4: "getProject",
            7: "listDeployments",
            8: "listTeams",
            9: "listUsers"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 4, 7, 8, 9])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof ProjectCtlProtoGetAccessLevelsRes.prototype.toObject>;
        checkProjectId?: ReturnType<typeof ProjectCtlProtoCheckProjectIDRes.prototype.toObject>;
        getProject?: ReturnType<typeof ProjectCtlProtoGetProjectRes.prototype.toObject>;
        listDeployments?: ReturnType<typeof ProjectCtlProtoListDeploymentsRes.prototype.toObject>;
        listTeams?: ReturnType<typeof ProjectCtlProtoListTeamsRes.prototype.toObject>;
        listUsers?: ReturnType<typeof ProjectCtlProtoListUsersRes.prototype.toObject>;
    }): ProjectCtlProtoRes {
        const message = new ProjectCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = ProjectCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.checkProjectId != null) {
            message.checkProjectId = ProjectCtlProtoCheckProjectIDRes.fromObject(data.checkProjectId);
        }
        if (data.getProject != null) {
            message.getProject = ProjectCtlProtoGetProjectRes.fromObject(data.getProject);
        }
        if (data.listDeployments != null) {
            message.listDeployments = ProjectCtlProtoListDeploymentsRes.fromObject(data.listDeployments);
        }
        if (data.listTeams != null) {
            message.listTeams = ProjectCtlProtoListTeamsRes.fromObject(data.listTeams);
        }
        if (data.listUsers != null) {
            message.listUsers = ProjectCtlProtoListUsersRes.fromObject(data.listUsers);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof ProjectCtlProtoGetAccessLevelsRes.prototype.toObject>;
            checkProjectId?: ReturnType<typeof ProjectCtlProtoCheckProjectIDRes.prototype.toObject>;
            getProject?: ReturnType<typeof ProjectCtlProtoGetProjectRes.prototype.toObject>;
            listDeployments?: ReturnType<typeof ProjectCtlProtoListDeploymentsRes.prototype.toObject>;
            listTeams?: ReturnType<typeof ProjectCtlProtoListTeamsRes.prototype.toObject>;
            listUsers?: ReturnType<typeof ProjectCtlProtoListUsersRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkProjectId != null) {
            data.checkProjectId = this.checkProjectId.toObject();
        }
        if (this.getProject != null) {
            data.getProject = this.getProject.toObject();
        }
        if (this.listDeployments != null) {
            data.listDeployments = this.listDeployments.toObject();
        }
        if (this.listTeams != null) {
            data.listTeams = this.listTeams.toObject();
        }
        if (this.listUsers != null) {
            data.listUsers = this.listUsers.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckProjectId)
            writer.writeMessage(2, this.checkProjectId, () => this.checkProjectId.serialize(writer));
        if (this.hasGetProject)
            writer.writeMessage(4, this.getProject, () => this.getProject.serialize(writer));
        if (this.hasListDeployments)
            writer.writeMessage(7, this.listDeployments, () => this.listDeployments.serialize(writer));
        if (this.hasListTeams)
            writer.writeMessage(8, this.listTeams, () => this.listTeams.serialize(writer));
        if (this.hasListUsers)
            writer.writeMessage(9, this.listUsers, () => this.listUsers.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = ProjectCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkProjectId, () => message.checkProjectId = ProjectCtlProtoCheckProjectIDRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getProject, () => message.getProject = ProjectCtlProtoGetProjectRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listDeployments, () => message.listDeployments = ProjectCtlProtoListDeploymentsRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listTeams, () => message.listTeams = ProjectCtlProtoListTeamsRes.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.listUsers, () => message.listUsers = ProjectCtlProtoListUsersRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoRes {
        return ProjectCtlProtoRes.deserialize(bytes);
    }
}
export class ProjectCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoGetAccessLevels {
        const message = new ProjectCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoGetAccessLevels {
        return ProjectCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class ProjectCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuids" in data && data.projectUuids != undefined) {
                this.projectUuids = data.projectUuids;
            }
        }
    }
    get projectUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set projectUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        projectUuids?: Uint8Array[];
    }): ProjectCtlProtoGetAccessLevelsReq {
        const message = new ProjectCtlProtoGetAccessLevelsReq({});
        if (data.projectUuids != null) {
            message.projectUuids = data.projectUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuids?: Uint8Array[];
        } = {};
        if (this.projectUuids != null) {
            data.projectUuids = this.projectUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuids.length)
            writer.writeRepeatedBytes(1, this.projectUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoGetAccessLevelsReq {
        return ProjectCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_1.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_1.AccessLevelProto[];
    }
    set accessLevels(value: dependency_1.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_1.AccessLevelProto[];
    }): ProjectCtlProtoGetAccessLevelsRes {
        const message = new ProjectCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_1.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoGetAccessLevelsRes {
        return ProjectCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class ProjectCtlProtoCheckProjectID extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoCheckProjectID {
        const message = new ProjectCtlProtoCheckProjectID({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoCheckProjectID {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoCheckProjectID();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoCheckProjectID {
        return ProjectCtlProtoCheckProjectID.deserialize(bytes);
    }
}
export class ProjectCtlProtoCheckProjectIDReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        id?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        id?: string;
    }): ProjectCtlProtoCheckProjectIDReq {
        const message = new ProjectCtlProtoCheckProjectIDReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            id?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.id.length)
            writer.writeString(2, this.id);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoCheckProjectIDReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoCheckProjectIDReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoCheckProjectIDReq {
        return ProjectCtlProtoCheckProjectIDReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoCheckProjectIDRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        exists?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("exists" in data && data.exists != undefined) {
                this.exists = data.exists;
            }
        }
    }
    get exists() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set exists(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        exists?: boolean;
    }): ProjectCtlProtoCheckProjectIDRes {
        const message = new ProjectCtlProtoCheckProjectIDRes({});
        if (data.exists != null) {
            message.exists = data.exists;
        }
        return message;
    }
    toObject() {
        const data: {
            exists?: boolean;
        } = {};
        if (this.exists != null) {
            data.exists = this.exists;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.exists != false)
            writer.writeBool(1, this.exists);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoCheckProjectIDRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoCheckProjectIDRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.exists = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoCheckProjectIDRes {
        return ProjectCtlProtoCheckProjectIDRes.deserialize(bytes);
    }
}
export class ProjectCtlProtoCreateProject extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoCreateProject {
        const message = new ProjectCtlProtoCreateProject({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoCreateProject {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoCreateProject();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoCreateProject {
        return ProjectCtlProtoCreateProject.deserialize(bytes);
    }
}
export class ProjectCtlProtoCreateProjectReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        project?: dependency_1.ProjectProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("project" in data && data.project != undefined) {
                this.project = data.project;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get project() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProjectProto, 2) as dependency_1.ProjectProto;
    }
    set project(value: dependency_1.ProjectProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasProject() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        project?: ReturnType<typeof dependency_1.ProjectProto.prototype.toObject>;
    }): ProjectCtlProtoCreateProjectReq {
        const message = new ProjectCtlProtoCreateProjectReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.project != null) {
            message.project = dependency_1.ProjectProto.fromObject(data.project);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            project?: ReturnType<typeof dependency_1.ProjectProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.project != null) {
            data.project = this.project.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasProject)
            writer.writeMessage(2, this.project, () => this.project.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoCreateProjectReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoCreateProjectReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.project, () => message.project = dependency_1.ProjectProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoCreateProjectReq {
        return ProjectCtlProtoCreateProjectReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoGetProject extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoGetProject {
        const message = new ProjectCtlProtoGetProject({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoGetProject {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoGetProject();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoGetProject {
        return ProjectCtlProtoGetProject.deserialize(bytes);
    }
}
export class ProjectCtlProtoGetProjectReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        projectId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("projectId" in data && data.projectId != undefined) {
                this.projectId = data.projectId;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get projectId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set projectId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        projectId?: string;
    }): ProjectCtlProtoGetProjectReq {
        const message = new ProjectCtlProtoGetProjectReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.projectId != null) {
            message.projectId = data.projectId;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            projectId?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.projectId != null) {
            data.projectId = this.projectId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.projectId.length)
            writer.writeString(2, this.projectId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoGetProjectReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoGetProjectReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.projectId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoGetProjectReq {
        return ProjectCtlProtoGetProjectReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoGetProjectRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        project?: dependency_1.ProjectProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("project" in data && data.project != undefined) {
                this.project = data.project;
            }
        }
    }
    get project() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProjectProto, 2) as dependency_1.ProjectProto;
    }
    set project(value: dependency_1.ProjectProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasProject() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        project?: ReturnType<typeof dependency_1.ProjectProto.prototype.toObject>;
    }): ProjectCtlProtoGetProjectRes {
        const message = new ProjectCtlProtoGetProjectRes({});
        if (data.project != null) {
            message.project = dependency_1.ProjectProto.fromObject(data.project);
        }
        return message;
    }
    toObject() {
        const data: {
            project?: ReturnType<typeof dependency_1.ProjectProto.prototype.toObject>;
        } = {};
        if (this.project != null) {
            data.project = this.project.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasProject)
            writer.writeMessage(2, this.project, () => this.project.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoGetProjectRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoGetProjectRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    reader.readMessage(message.project, () => message.project = dependency_1.ProjectProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoGetProjectRes {
        return ProjectCtlProtoGetProjectRes.deserialize(bytes);
    }
}
export class ProjectCtlProtoUpdateProject extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoUpdateProject {
        const message = new ProjectCtlProtoUpdateProject({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoUpdateProject {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoUpdateProject();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoUpdateProject {
        return ProjectCtlProtoUpdateProject.deserialize(bytes);
    }
}
export class ProjectCtlProtoUpdateProjectReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        project?: dependency_1.ProjectProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("project" in data && data.project != undefined) {
                this.project = data.project;
            }
        }
    }
    get project() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProjectProto, 1) as dependency_1.ProjectProto;
    }
    set project(value: dependency_1.ProjectProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasProject() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        project?: ReturnType<typeof dependency_1.ProjectProto.prototype.toObject>;
    }): ProjectCtlProtoUpdateProjectReq {
        const message = new ProjectCtlProtoUpdateProjectReq({});
        if (data.project != null) {
            message.project = dependency_1.ProjectProto.fromObject(data.project);
        }
        return message;
    }
    toObject() {
        const data: {
            project?: ReturnType<typeof dependency_1.ProjectProto.prototype.toObject>;
        } = {};
        if (this.project != null) {
            data.project = this.project.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasProject)
            writer.writeMessage(1, this.project, () => this.project.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoUpdateProjectReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoUpdateProjectReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.project, () => message.project = dependency_1.ProjectProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoUpdateProjectReq {
        return ProjectCtlProtoUpdateProjectReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoDeleteProject extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoDeleteProject {
        const message = new ProjectCtlProtoDeleteProject({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoDeleteProject {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoDeleteProject();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoDeleteProject {
        return ProjectCtlProtoDeleteProject.deserialize(bytes);
    }
}
export class ProjectCtlProtoDeleteProjectReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
    }): ProjectCtlProtoDeleteProjectReq {
        const message = new ProjectCtlProtoDeleteProjectReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoDeleteProjectReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoDeleteProjectReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoDeleteProjectReq {
        return ProjectCtlProtoDeleteProjectReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoListDeployments extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoListDeployments {
        const message = new ProjectCtlProtoListDeployments({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListDeployments {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListDeployments();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListDeployments {
        return ProjectCtlProtoListDeployments.deserialize(bytes);
    }
}
export class ProjectCtlProtoListDeploymentsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): ProjectCtlProtoListDeploymentsReq {
        const message = new ProjectCtlProtoListDeploymentsReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListDeploymentsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListDeploymentsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListDeploymentsReq {
        return ProjectCtlProtoListDeploymentsReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoListDeploymentsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deployments?: dependency_1.DeploymentProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deployments" in data && data.deployments != undefined) {
                this.deployments = data.deployments;
            }
        }
    }
    get deployments() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.DeploymentProto, 1) as dependency_1.DeploymentProto[];
    }
    set deployments(value: dependency_1.DeploymentProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        deployments?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>[];
    }): ProjectCtlProtoListDeploymentsRes {
        const message = new ProjectCtlProtoListDeploymentsRes({});
        if (data.deployments != null) {
            message.deployments = data.deployments.map(item => dependency_1.DeploymentProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            deployments?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>[];
        } = {};
        if (this.deployments != null) {
            data.deployments = this.deployments.map((item: dependency_1.DeploymentProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deployments.length)
            writer.writeRepeatedMessage(1, this.deployments, (item: dependency_1.DeploymentProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListDeploymentsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListDeploymentsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.deployments, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.DeploymentProto.deserialize(reader), dependency_1.DeploymentProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListDeploymentsRes {
        return ProjectCtlProtoListDeploymentsRes.deserialize(bytes);
    }
}
export class ProjectCtlProtoListTeams extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoListTeams {
        const message = new ProjectCtlProtoListTeams({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListTeams {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListTeams();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListTeams {
        return ProjectCtlProtoListTeams.deserialize(bytes);
    }
}
export class ProjectCtlProtoListTeamsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): ProjectCtlProtoListTeamsReq {
        const message = new ProjectCtlProtoListTeamsReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListTeamsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListTeamsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListTeamsReq {
        return ProjectCtlProtoListTeamsReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoListTeamsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teams?: dependency_1.TeamProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teams" in data && data.teams != undefined) {
                this.teams = data.teams;
            }
        }
    }
    get teams() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.TeamProto, 1) as dependency_1.TeamProto[];
    }
    set teams(value: dependency_1.TeamProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        teams?: ReturnType<typeof dependency_1.TeamProto.prototype.toObject>[];
    }): ProjectCtlProtoListTeamsRes {
        const message = new ProjectCtlProtoListTeamsRes({});
        if (data.teams != null) {
            message.teams = data.teams.map(item => dependency_1.TeamProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            teams?: ReturnType<typeof dependency_1.TeamProto.prototype.toObject>[];
        } = {};
        if (this.teams != null) {
            data.teams = this.teams.map((item: dependency_1.TeamProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teams.length)
            writer.writeRepeatedMessage(1, this.teams, (item: dependency_1.TeamProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListTeamsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListTeamsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.teams, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.TeamProto.deserialize(reader), dependency_1.TeamProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListTeamsRes {
        return ProjectCtlProtoListTeamsRes.deserialize(bytes);
    }
}
export class ProjectCtlProtoListUsers extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoListUsers {
        const message = new ProjectCtlProtoListUsers({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListUsers {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListUsers();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListUsers {
        return ProjectCtlProtoListUsers.deserialize(bytes);
    }
}
export class ProjectCtlProtoListUsersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): ProjectCtlProtoListUsersReq {
        const message = new ProjectCtlProtoListUsersReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListUsersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListUsersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListUsersReq {
        return ProjectCtlProtoListUsersReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoListUsersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        users?: dependency_1.UserProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("users" in data && data.users != undefined) {
                this.users = data.users;
            }
        }
    }
    get users() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.UserProto, 1) as dependency_1.UserProto[];
    }
    set users(value: dependency_1.UserProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        users?: ReturnType<typeof dependency_1.UserProto.prototype.toObject>[];
    }): ProjectCtlProtoListUsersRes {
        const message = new ProjectCtlProtoListUsersRes({});
        if (data.users != null) {
            message.users = data.users.map(item => dependency_1.UserProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            users?: ReturnType<typeof dependency_1.UserProto.prototype.toObject>[];
        } = {};
        if (this.users != null) {
            data.users = this.users.map((item: dependency_1.UserProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.users.length)
            writer.writeRepeatedMessage(1, this.users, (item: dependency_1.UserProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoListUsersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoListUsersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.users, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.UserProto.deserialize(reader), dependency_1.UserProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoListUsersRes {
        return ProjectCtlProtoListUsersRes.deserialize(bytes);
    }
}
export class ProjectCtlProtoAssignUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoAssignUser {
        const message = new ProjectCtlProtoAssignUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoAssignUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoAssignUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoAssignUser {
        return ProjectCtlProtoAssignUser.deserialize(bytes);
    }
}
export class ProjectCtlProtoAssignUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }): ProjectCtlProtoAssignUserReq {
        const message = new ProjectCtlProtoAssignUserReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            userUuid?: Uint8Array;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.userUuid.length)
            writer.writeBytes(3, this.userUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoAssignUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoAssignUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 3:
                    message.userUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoAssignUserReq {
        return ProjectCtlProtoAssignUserReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoUnassignUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoUnassignUser {
        const message = new ProjectCtlProtoUnassignUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoUnassignUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoUnassignUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoUnassignUser {
        return ProjectCtlProtoUnassignUser.deserialize(bytes);
    }
}
export class ProjectCtlProtoUnassignUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }): ProjectCtlProtoUnassignUserReq {
        const message = new ProjectCtlProtoUnassignUserReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            userUuid?: Uint8Array;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.userUuid.length)
            writer.writeBytes(3, this.userUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoUnassignUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoUnassignUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 3:
                    message.userUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoUnassignUserReq {
        return ProjectCtlProtoUnassignUserReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoAssignTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoAssignTeam {
        const message = new ProjectCtlProtoAssignTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoAssignTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoAssignTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoAssignTeam {
        return ProjectCtlProtoAssignTeam.deserialize(bytes);
    }
}
export class ProjectCtlProtoAssignTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        teamUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        teamUuid?: Uint8Array;
    }): ProjectCtlProtoAssignTeamReq {
        const message = new ProjectCtlProtoAssignTeamReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            teamUuid?: Uint8Array;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.teamUuid.length)
            writer.writeBytes(2, this.teamUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoAssignTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoAssignTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 2:
                    message.teamUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoAssignTeamReq {
        return ProjectCtlProtoAssignTeamReq.deserialize(bytes);
    }
}
export class ProjectCtlProtoUnassignTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ProjectCtlProtoUnassignTeam {
        const message = new ProjectCtlProtoUnassignTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoUnassignTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoUnassignTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoUnassignTeam {
        return ProjectCtlProtoUnassignTeam.deserialize(bytes);
    }
}
export class ProjectCtlProtoUnassignTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        teamUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        teamUuid?: Uint8Array;
    }): ProjectCtlProtoUnassignTeamReq {
        const message = new ProjectCtlProtoUnassignTeamReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            teamUuid?: Uint8Array;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.teamUuid.length)
            writer.writeBytes(2, this.teamUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProjectCtlProtoUnassignTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProjectCtlProtoUnassignTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 2:
                    message.teamUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ProjectCtlProtoUnassignTeamReq {
        return ProjectCtlProtoUnassignTeamReq.deserialize(bytes);
    }
}
