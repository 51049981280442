/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { Invoice, invoiceStatusOf, invoiceStatusToString } from "../../concept/subscription";

@Pipe({
    name: "invoiceStatus",
    standalone: true,
})
export class InvoiceStatusPipe implements PipeTransform {
    transform(value: Invoice): string {
        return invoiceStatusToString(invoiceStatusOf(value));
    }
}
