/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AfterViewInit, Component } from "@angular/core";
import { AnalyticsService } from "./service/analytics.service";
import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, Router, RouterOutlet, Event as RouterEvent, NavigationEnd } from "@angular/router";
import { ApiService } from "./service/backend/api.service";
import { ApplicationState } from "./service/application-state.service";
import { SnackbarService } from "./service/snackbar.service";
import { EMPTY, filter, of, switchMap } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { SpinnerComponent } from "typedb-platform-framework";

@Component({
    selector: "tp-root", // eslint-disable-line @angular-eslint/component-selector
    templateUrl: "./root.component.html",
    styleUrls: ["root.component.scss"],
    standalone: true,
    imports: [RouterOutlet, SpinnerComponent, AsyncPipe],
})
export class RootComponent implements AfterViewInit {
    routeIsLoading$ = this.router.events.pipe(
        switchMap((event) => {
            if (event instanceof GuardsCheckStart) {
                return of(true);
            } else if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
                return of(false);
            } else {
                return EMPTY;
            }
        })
    );

    constructor(
        private analytics: AnalyticsService, app: ApplicationState, private router: Router,
        api: ApiService, private snackbarService: SnackbarService,
    ) {
        this.signOutOnUnauthenticatedRequest(api, app);
        this.capturePageViewOnNavigation(router, analytics);
    }

    private signOutOnUnauthenticatedRequest(api: ApiService, app: ApplicationState) {
        api.onUnauthenticatedRequest$.pipe(
            switchMap(() => app.signOut({ callDeauthUser: false, redirectToLoginPage: true })),
        ).subscribe(() => {
            this.snackbarService.errorPersistent("You have been logged out; please log back in to continue.");
        });
    }

    private capturePageViewOnNavigation(router: Router, analytics: AnalyticsService) {
        router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe(() => {
            analytics.posthog.capturePageView();
        });
    }

    ngAfterViewInit() {
        this.analytics.google.loadScriptTag();
    }
}
