/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "../../../util";
import { OrgApi } from "../../../service/org/org-api.service";
import { Router } from "@angular/router";
import { RETURN_TO } from '../../../framework/url-params';

@Component({
    selector: "tp-org-creation-dialog",
    templateUrl: "./org-creation-dialog.component.html",
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent],
})
export class OrgCreationDialogComponent {
    @Input() redirectAfterClose: boolean = true;

    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: ["", [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private dialogRef: MatDialogRef<OrgCreationDialogComponent>,
        private formBuilder: FormBuilder, private orgApi: OrgApi,
        private router: Router
    ) {
    }

    submit() {
        const id = this.form.value.id!;
        const name = this.form.value.name!;
        this.orgApi.createOrg({ id, name }).subscribe({
            next: () => {
                this.close();
                if (this.redirectAfterClose) {
                    let params = new URLSearchParams({[RETURN_TO]: `/org/${id}/settings`})
                    window.location.href = `${window.location.origin}/setup/marketplace?${params}`;
                }
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close() {
        this.dialogRef.close();
    }
}
