/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe, NgClass, NgOptimizedImage } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router } from "@angular/router";
import {
    BehaviorSubject, combineLatest, concat, debounceTime, distinctUntilChanged, filter, first, map, Observable, of,
    ReplaySubject, shareReplay, startWith, Subject, switchMap, tap
} from "rxjs";
import { mergeListChanges } from "../../../concept/api-response";
import { Deployment, storageSizeToString } from "../../../concept/deployment";
import { MachineType, ProviderOptions, RegionOptions, StorageType, machineTypeToString, regionToString, storageTypeToString, Provider, Region } from "../../../concept/deployment-options";
import { Project } from "../../../concept/project";
import { PROJECT_ID } from "../../../framework/url-params";
import { AnalyticsService } from "../../../service/analytics.service";
import { idPattern, idPatternErrorText, renderCentsAsUSD } from "../../../util";

import { deploymentDetailsPath } from "../../../routing/resource-paths";
import { CreditApplicationModalComponent } from "../../org/credit-application-modal/credit-application-modal.component";
import { PaymentDetailsModalComponent } from "../../org/payment-details-modal/payment-details-modal.component";
import { DeploymentApi } from "../../../service/deployment/deployment-api.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { ProjectApi } from "../../../service/project/project-api.service";
import { UserApi } from "../../../service/user/user-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import {
    FormActionsComponent, FormComponent, SpinnerComponent, FormOption, FormOptionGroup, FormSelectComponent,
    FormInputComponent, FormToggleGroupComponent, ButtonComponent, patternValidator, requiredValidator
} from "typedb-platform-framework";
import { MatDialog } from "@angular/material/dialog";
import { MachineTypePipe } from "../machine-type.pipe";
import { RegionPipe } from "../region.pipe";
import { StorageTypePipe } from "../storage-type.pipe";

const serverCountOptions = [1, 3, 5, 7] as const;
const serverCountDefaultOption: typeof serverCountOptions[number] = 3;
const storageSliderValueToGBMap: { [key: number]: number } = {
    0: 5,
    1: 25,
    2: 50,
    3: 75,
    4: 100,
    5: 200,
    6: 400,
    7: 600,
    8: 800,
    9: 1000,
};
const storageSliderDefaultOption = 1;

type CanSubmitChecks = {
    cannotCreateSecondFreeDeployment: boolean;
    mustAddPaymentMethod: boolean;
    mustVerifyEmail: boolean;
    isSubmitting: boolean;
}

@Component({
    selector: "tp-deployment-creation-page",
    templateUrl: "./deployment-creation-page.component.html",
    styleUrls: ["./deployment-creation-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, PaymentDetailsModalComponent, MatFormFieldModule, MatInputModule, MatSelectModule,
        FormsModule, ReactiveFormsModule, AsyncPipe, ButtonComponent, FormSelectComponent, FormInputComponent,
        FormActionsComponent, SpinnerComponent, FormComponent, FormToggleGroupComponent, NgClass, NgOptimizedImage,
        MatSliderModule, MatTooltipModule, RegionPipe, MachineTypePipe, StorageTypePipe,
    ],
})
export class DeploymentCreationPageComponent implements OnInit {
    readonly org = this.app.requireCurrentOrg();
    readonly deploymentOptions$ = this.deploymentApi.getDeploymentOptions().pipe(first(), shareReplay(1));
    providers$: Observable<ProviderOptions[]> = this.deploymentOptions$.pipe(
        map((options) => options?.providers || [])
    );
    regionGroups: FormOptionGroup<RegionOptions>[] = [];
    machineTypes: FormOption<MachineType>[] = [];
    storageTypes: FormOption<StorageType>[] = [];
    readonly projects$: Observable<Project[]> = this.orgCtl.listWritableProjectsSnapshot().pipe(shareReplay(1));
    readonly projectOptions$: Observable<FormOption<Project>[]> = this.projects$
        .pipe(map((projects) => projects.map(x => ({ value: x, viewValue: x.id }))));
    readonly typeDBVersions$: Observable<FormOption<string>[]> = this.deploymentOptions$.pipe(
        map((options) => (options?.typeDBVersions || [])
            .sort((x, y) => -x.localeCompare(y))
            .map(x => ({ value: x })))
    );
    readonly existingDeployments$ = new BehaviorSubject<Deployment[] | null>(null);
    readonly unsubExistingDeployments$ = new Subject<void>();
    readonly payableProviders$ = new ReplaySubject<Provider[]>();

    readonly isSubmitting$ = new BehaviorSubject(false);
    readonly form = this.formBuilder.group({
        id: ["", [
            patternValidator(idPattern, idPatternErrorText),
            requiredValidator,
            (control: AbstractControl) => this.deploymentIDUniqueValidator(control)
        ]],
        project: [null as Project | null, [requiredValidator]],
        provider: [null as ProviderOptions | null, [requiredValidator]],
        region: [null as RegionOptions | null, [requiredValidator]],
        machineType: [null as MachineType | null, [requiredValidator]],
        serverCount: [serverCountDefaultOption, [requiredValidator]],
        storageType: [null as StorageType | null, [requiredValidator]],
        storageSlider: [storageSliderDefaultOption, [requiredValidator]],
        typeDBVersion: ["", [requiredValidator]],
    });
    private readonly billableFields: (keyof typeof this.form.value)[] = ["provider", "region", "machineType", "serverCount", "storageSlider"];

    pricePerHourCents$ = combineLatest(this.billableFields.map(field => concat(of(this.form.value[field]), this.form.get(field)!.valueChanges))).pipe(
        debounceTime(300),
        switchMap(() => this.fetchPrice()),
        shareReplay(1),
    );
    pricePerHourString$ = this.pricePerHourCents$.pipe(map(x => {
        if (x === "N/A") return "N/A";
        else if (x === "loading") return undefined;
        else return this.pricePerHourToPerHourString(x);
    }));
    pricePerMonthString$ = this.pricePerHourCents$.pipe(map(x => {
        if (x === "N/A") return "N/A";
        else if (x === "loading") return undefined;
        else return this.pricePerHourToPerMonthString(x);
    }));
    firstSectionReady = false;
    providerSectionReady = false;
    readonly isFreeTierSelected$ = new BehaviorSubject<boolean>(false);
    memorisedFieldsBeforeChoosingFreeTier = {
        serverCount: serverCountDefaultOption,
        storageSlider: storageSliderDefaultOption,
    };
    serverCountLocked = false;
    storageSliderLocked = false;
    readonly serverCountTooltip$: Observable<string> = this.isFreeTierSelected$.pipe(map((isFreeTierSelected) => {
        return isFreeTierSelected ? "Free deployments are limited to 1 machine" : "";
    }));
    readonly storageSliderTooltip$: Observable<string> = this.isFreeTierSelected$.pipe(map((isFreeTierSelected) => {
        return isFreeTierSelected ? "Free deployments are limited to 5 GB of storage" : "";
    }));
    serverCounts$ = this.isFreeTierSelected$.pipe(
        map((isFreeTierSelected) => {
            if (isFreeTierSelected) return serverCountOptions.map(x => ({ value: x, viewValue: x === 1 ? "1*" : x.toString() }));
            else return serverCountOptions.map(x => ({ value: x }));
        })
    );
    readonly alreadyHasFreeDeployment$ = this.orgApi.getFreeDeploymentCount(this.org.uuid).pipe(
        map(x => x > 0),
        distinctUntilChanged(),
        shareReplay(1, 60000),
    );
    readonly mustVerifyEmail$ = this.app.currentUser$.pipe(map(x => !x || !x.isVerified));
    readonly cannotCreateSecondFreeDeployment$ = combineLatest([this.isFreeTierSelected$, this.alreadyHasFreeDeployment$]).pipe(map(([x, y]) => x && y));
    readonly showFreeTierSuspensionWarning$ = combineLatest([this.isFreeTierSelected$, this.alreadyHasFreeDeployment$]).pipe(map(([x, y]) => x && !y));
    readonly hasUsablePaymentMethod$ = combineLatest([this.payableProviders$, this.form.valueChanges]).pipe(
        filter(() => !!this.form.value.provider),
        map(([payable]) => payable.map(x => x.id).includes(this.form.value.provider!.id))
    );
    readonly mustAddPaymentMethod$ = combineLatest([this.isFreeTierSelected$, this.hasUsablePaymentMethod$]).pipe(map(([isFreeTierSelected, hasUsablePaymentMethod]) => !isFreeTierSelected && !hasUsablePaymentMethod));
    readonly showAddPaymentMethodPrompt$ = combineLatest([this.mustAddPaymentMethod$, this.app.hasOrgAdminAccess$]).pipe(
        map(([mustAddPaymentMethod, isAdmin]) => mustAddPaymentMethod && isAdmin),
    );
    readonly showAskAdminForPaymentMethodPrompt$ = combineLatest([this.mustAddPaymentMethod$, this.app.hasOrgAdminAccess$]).pipe(
        map(([mustAddPaymentMethod, isAdmin]) => mustAddPaymentMethod && !isAdmin),
    );
    readonly canSubmitChecks$: Observable<CanSubmitChecks> = combineLatest([
        this.form.valueChanges.pipe(debounceTime(300)), this.cannotCreateSecondFreeDeployment$, this.mustAddPaymentMethod$, this.mustVerifyEmail$, this.isSubmitting$
    ]).pipe(map(([_, cannotCreateSecondFreeDeployment, mustAddPaymentMethod, mustVerifyEmail, isSubmitting]) => ({
        cannotCreateSecondFreeDeployment, mustAddPaymentMethod, mustVerifyEmail, isSubmitting
    })));
    readonly canSubmit$ = this.canSubmitChecks$.pipe(map(({ cannotCreateSecondFreeDeployment, mustAddPaymentMethod, mustVerifyEmail, isSubmitting }) => {
        return this.form.valid && !cannotCreateSecondFreeDeployment && !mustAddPaymentMethod && !mustVerifyEmail && !isSubmitting;
    }));
    readonly submitButtonTooltip$: Observable<string> = this.canSubmitChecks$.pipe(
        map((check) => {
            if (check.mustVerifyEmail) return "A verified email address is required to create a deployment";
            else if (check.mustAddPaymentMethod) return "A valid payment method is required to create this deployment";
            else if (check.cannotCreateSecondFreeDeployment) return "Limited to 1 free deployment per organization";
            else return "";
        }),
        startWith(""),
    );

    constructor(
        private app: ApplicationState, private formBuilder: FormBuilder, private route: ActivatedRoute,
        private deploymentApi: DeploymentApi, private projectApi: ProjectApi, private orgApi: OrgApi,
        private router: Router, private snackbar: SnackbarService, private userApi: UserApi, private dialog: MatDialog,
        private orgCtl: OrgController, private analytics: AnalyticsService,
    ) {}

    ngOnInit() {
        this.initExistingDeploymentsLoader();
        this.fetchPayableProviders(false);
        this.restrictSpecsWhenOnFreeTier();
        this.autoUpdateRegionAndMachines();
        this.prepopulateNameAndProject();
        this.prepopulateProviderAndTypeDBVersion();
    }

    private initExistingDeploymentsLoader() {
        this.form.controls.project.valueChanges.pipe(
            tap(() => this.unsubExistingDeployments$.next()),
            switchMap((project) => this.projectApi.listDeployments(
                project!.uuid,
                { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } },
                this.unsubExistingDeployments$
            )),
        ).subscribe((res) => {
            const mergeResult = mergeListChanges(this.existingDeployments$.value, res);
            if (mergeResult.result === "shouldResync") {
                console.warn(`Received update is not valid in the current state; forcing a full resync`);
                this.initExistingDeploymentsLoader();
                return;
            }
            this.existingDeployments$.next(mergeResult.data);
            this.form.controls.id.updateValueAndValidity();
        });
    }

    fetchPayableProviders(manualTrigger: boolean) {
        const org = this.org;
        if (manualTrigger) this.isSubmitting$.next(true);
        this.orgApi.getPayableProviders(org.uuid).subscribe((payableProviders) => {
            this.payableProviders$.next(payableProviders);
            this.isSubmitting$.next(false);
            if (manualTrigger) {
                const selectedProvider = this.form.value.provider!;
                if (payableProviders.map(x => x.id).includes(selectedProvider.id)) {
                    this.snackbar.success("Payment method validated successfully");
                } else {
                    this.snackbar.warnPersistent(`No valid payment method found. Please contact an organization administrator.`);
                }
            }
        });
    }

    private autoUpdateRegionAndMachines() {
        // TODO: should use the same logic as setup/project. This would require a data migration as setup/project
        //       expects a different data layout
        this.form.controls.provider.valueChanges.subscribe((provider) => {
            const continentNames = [...new Set(provider!.regions
                .sort((x, y) => x.continentOrdinal - y.continentOrdinal)
                .map(x => x.continentName)
            )];
            const regionMap = Object.fromEntries(continentNames.map(x => [x, [] as FormOption<RegionOptions>[]]));
            provider!.regions.sort((a, b) => a.vendorId.localeCompare(b.vendorId)).forEach(region => {
                const formOption = this.regionOptionOf(region);
                regionMap[region.continentName].push(formOption);
            });
            this.regionGroups = Object.entries(regionMap).map(([name, options]) => ({ name, options }));
            this.form.patchValue({ region: this.regionGroups
                .flatMap(x => x.options)
                .find(x => x.value.isFreeAvailable)?.value
                    || this.regionGroups[0].options[0].value
            });
        });

        this.form.controls.region.valueChanges.subscribe((region) => {
            this.machineTypes = region!.machineTypes
                .sort((a, b) => (a.cpu - b.cpu) || (a.vendorId.localeCompare(b.vendorId)) || (Number(b.isFree) - Number(a.isFree)))
                .map(this.machineTypeOptionOf);
            this.storageTypes = region!.storageTypes
                .sort((a, b) => a.vendorId.localeCompare(b.vendorId))
                .map(this.storageTypeOptionOf);
            this.form.patchValue({ machineType: this.machineTypes.find(x => x.value.isFree)?.value || this.machineTypes[0].value });
            this.form.patchValue({ storageType: this.storageTypes[0].value });
        });
    }

    private prepopulateProviderAndTypeDBVersion() {
        combineLatest([this.providers$, this.payableProviders$]).pipe(first()).subscribe(([providers, payable]) => {
            const preferredProvider = providers.find(x => x.isFreeAvailable && payable.map(y => y.id).includes(x.id))
                || providers.find(x => payable.map(y => y.id).includes(x.id))
                || providers.find(x => x.isFreeAvailable)
                || providers[0];
            this.form.patchValue({ provider: preferredProvider });
            this.providerSectionReady = true;
        });
        this.typeDBVersions$.subscribe((versions) => {
            this.form.patchValue({ typeDBVersion: versions[0].value });
        });
    }

    private prepopulateNameAndProject() {
        combineLatest([this.route.queryParamMap.pipe(first()), this.projects$.pipe(first())]).subscribe(([params, projects]) => {
            const requestedProjectID = params.get(PROJECT_ID);
            if (requestedProjectID != null) {
                const project = projects.find(x => x.id === requestedProjectID);
                if (project) {
                    this.form.patchValue({ project });
                    return;
                } else {
                    this.snackbar.warn(`Project with ID '${requestedProjectID}' not found`);
                }
            }
            if (projects.length) this.form.patchValue({ project: projects.find(x => x.id === "default") || projects[0] })
            else this.snackbar.warn(`This organization has no projects. Please create a project first.`);
        });

        this.existingDeployments$.pipe(
            first((deployments) => deployments != null),
            map((deployments) => deployments!),
        ).subscribe((deployments) => {
            this.form.patchValue({ id: `typedb-cloud-${deployments.length + 1}` });
            this.firstSectionReady = true;
        });
    }

    private restrictSpecsWhenOnFreeTier() {
        this.form.get("machineType")!.valueChanges.pipe(
            map((machineType) => machineType!.isFree)
        ).subscribe((isFree) => {
            this.isFreeTierSelected$.next(isFree);
        });

        this.isFreeTierSelected$.pipe(
            distinctUntilChanged(),
        ).subscribe((isFreeTierSelected) => {
            if (isFreeTierSelected) {
                this.memorisedFieldsBeforeChoosingFreeTier = {
                    serverCount: this.form.value.serverCount!,
                    storageSlider: this.form.value.storageSlider!,
                };
                this.form.patchValue({ serverCount: 1, storageSlider: 0 });
                this.serverCountLocked = true;
                this.storageSliderLocked = true;
            } else {
                this.serverCountLocked = false;
                this.storageSliderLocked = false;
                this.form.patchValue({
                    serverCount: this.memorisedFieldsBeforeChoosingFreeTier.serverCount,
                    storageSlider: this.memorisedFieldsBeforeChoosingFreeTier.storageSlider,
                });
            }
        });
    }

    private deploymentIDUniqueValidator(control: AbstractControl<string>) {
        if (!this.existingDeployments$.value) return null;
        if (this.existingDeployments$.value.some(x => x.id === control.value)) {
            return { errorText: `The deployment ID '${control.value}' is already in use in this project` };
        } else return null;
    }

    get isBelowRecommendedNodeCount(): boolean {
        return this.form.controls.serverCount.value != null && this.form.controls.serverCount.value < 3;
    }

    regionOptionOf(region: RegionOptions): FormOption<RegionOptions> {
        return { value: region, viewValue: regionToString(region) };
    }

    machineTypeOptionOf(machine: MachineType): FormOption<MachineType> {
        return { value: machine, viewValue: machineTypeToString(machine) };
    }

    storageTypeOptionOf(storage: StorageType): FormOption<StorageType> {
        return { value: storage, viewValue: storageTypeToString(storage) };
    }

    providerCardClasses(provider: ProviderOptions): { [clazz: string]: boolean } {
        return {
            "provider-card-selected": this.form.value.provider?.id === provider.id,
            [`provider-card-${provider.id}`]: true,
            // "provider-card-free-available": provider.isFreeAvailable, // TODO: bring back once something isn't free-available
        };
    }

    providerImageUrl(provider: Provider): string {
        return `/assets/logo/${provider.id}.svg`;
    }

    regionFlagUrl(region: Region): string {
        return `/assets/flags/${region.countryCode.toLowerCase()}.svg`;
    }

    selectProvider(provider: ProviderOptions) {
        this.form.patchValue({ provider });
    }

    get regionFieldTitle(): string {
        return this.regionGroups.flatMap(x => x.options).find(x => x.value === this.form.value.region)?.viewValue ?? "Select an option";
    }

    get machineTypeFieldTitle(): string {
        return this.machineTypes.find(x => x.value === this.form.value.machineType)?.viewValue ?? "Select an option";
    }

    storageSliderValueToGB(rawValue: number): number {
        return storageSliderValueToGBMap[rawValue];
    }

    storageSliderValueToString(rawValue: number): string {
        const gb = storageSliderValueToGBMap[rawValue];
        return storageSizeToString(gb);
    }

    get storageSliderIndicatorText(): string {
        let storageString = this.storageSliderValueToString(this.form.value.storageSlider!);
        if (this.isFreeTierSelected$.value) {
            storageString += "*";
        }
        return storageString;
    }

    get storageSliderCurrentValueToString(): string {
        return this.storageSliderValueToString(this.form.value.storageSlider!);
    }

    private fetchPrice(): Observable<number | "loading" | "N/A"> {
        const values = this.form.value;
        if (this.billableFields.some(x => values[x] == null)) return of("N/A");
        const deployment = this.deploymentFromFormValues();

        return this.isFreeTierSelected$.pipe(
            first(),
            switchMap((isFreeTier) => {
                if (isFreeTier) return of(0);
                else return this.deploymentApi.getDeploymentPriceCentsPerHour({
                    deployment,
                    orgUuid: this.org.uuid,
                }).pipe(startWith<number | "loading">("loading"));
            }),
        );
    }

    pricePerHourToPerHourString(pricePerHourCents: number): string {
        if (pricePerHourCents === 0) return `Free`;
        else return `${renderCentsAsUSD(pricePerHourCents)}`;
    }

    pricePerHourToPerMonthString(pricePerHourCents: number): string {
        if (pricePerHourCents === 0) return `Free`;
        else return `${renderCentsAsUSD(pricePerHourCents * 730)}`;
    }

    resendVerificationEmail() {
        const user = this.app.requireCurrentUser();
        this.userApi.sendVerifyEmail(false).subscribe({
            next: () => {
                this.snackbar.success(`A link to verify your email address has been sent to ${user.email}.`);
            },
        });
    }

    deploymentFromFormValues() {
        const form = this.form.value;
        const id = form.id!;
        const project = form.project!;
        const [provider, region] = [form.provider!, form.region!];
        const [machineType, serverCount, storageType, storageGB]
            = [form.machineType!, form.serverCount!, form.storageType!, this.storageSliderValueToGB(form.storageSlider!)]
        const typeDBVersion = form.typeDBVersion!;

        return { id, project, provider, region, machineType, serverCount, storageType, storageGB, typeDBVersion };
    }

    submit() {
        const deployment = this.deploymentFromFormValues();
        this.deploymentApi.createDeployment(deployment).subscribe({
            next: () => {
                this.analytics.google.reportAdConversion(deployment.machineType.isFree ? "createFreeDeployment" : "createPaidDeployment");
                this.router.navigate([deploymentDetailsPath(deployment, this.org)]);
                this.snackbar.success(`Your deployment is starting up and will be ready soon. You can monitor its status from this page.`, { duration: 10000 });
            },
            error: () => {
                this.isSubmitting$.next(false);
            }
        });
    }

    openCreditApplicationDialog() {
        this.dialog.open(CreditApplicationModalComponent);
    }
}
