/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, combineLatest, combineLatestWith, map, of, startWith, switchMap } from "rxjs";
import {
    ModalComponent, ConfirmationModalComponent, StrongConfirmationModalComponent, StrongConfirmationModalData,
    ButtonComponent
} from "typedb-platform-framework";
import { Project } from "../../../concept/project";
import { LOADING, NO_ITEMS_SELECTED } from "../../../framework/strings";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { ProjectApi } from "../../../service/project/project-api.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { ProjectCreationDialogComponent } from "../create/project-creation-dialog.component";
import { PageScaffoldComponent, resourceAvailabilityStreamOf } from "../../scaffold/page/page-scaffold.component";
import { ProjectsTableComponent } from "../table/projects-table.component";
import { AsyncPipe } from "@angular/common";
import { ApplicationState } from "../../../service/application-state.service";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: "tp-projects-page",
    templateUrl: "./projects-page.component.html",
    styleUrls: ["./projects-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, ProjectsTableComponent, ModalComponent, ConfirmationModalComponent, AsyncPipe,
        TableToolbarComponent, TablePaginatorComponent, ButtonComponent, FontAwesomeModule, MatTooltipModule,
    ],
})
export class ProjectsPageComponent implements OnDestroy {
    readonly org = this.app.requireCurrentOrg();
    private readonly unsub$ = new Subject<void>();
    readonly projects = this.orgCtl.projectsTable(this.unsub$);
    readonly availability$ = resourceAvailabilityStreamOf(this.projects);

    readonly cannotCreateReason$ = this.app.hasOrgWriteAccess$.pipe(map(canWrite => {
        if (!canWrite) return "Only users with write permissions to the organization can create projects";
        return null;
    }), startWith(LOADING));
    readonly cannotDeleteReason$ = this.projects.hasAdminAccessToSelected$.pipe(switchMap(isAdmin => {
        if (!this.projects.isAnySelected()) return of(NO_ITEMS_SELECTED);
        if (!isAdmin) return of("You don't have admin access to one or more of the selected project(s)");
        return this.projects.selectionChanged$.pipe(
            map(() => this.projects.selected),
            switchMap((selectedProjects) => {
                if (!selectedProjects.length) return of([]);
                else return combineLatest(selectedProjects.map(x => this.projectApi.listDeployments(
                    x.uuid, { pagination: { offset: 0, limit: 1 }, sorting: { attributeType: "id" } }, of(undefined),
                ).pipe(map((res) => res.initial!.length), combineLatestWith(of(x)))));
            }),
            map((deploymentCounts) => {
                if (this.everyProjectInOrgIsSelected()) return "An organization must have at least one project";
                const projectWithDeployments = deploymentCounts.find(x => x[0] > 0)?.at(1) as Project | undefined;
                if (projectWithDeployments) return `Project '${projectWithDeployments.id}' cannot be deleted because it has at least one deployment`;
                return null;
            }),
        );
    }), startWith(LOADING));
    readonly createEnabled$ = this.cannotCreateReason$.pipe(map(x => !x));
    readonly deleteEnabled$ = this.cannotDeleteReason$.pipe(map(x => !x));

    constructor(
        private projectApi: ProjectApi, private orgCtl: OrgController, private dialog: MatDialog,
        private app: ApplicationState, private snackbar: SnackbarService,
    ) {}

    ngOnDestroy() {
        this.unsub$.next();
    }

    everyProjectInOrgIsSelected(): boolean {
        return this.projects.isNonEmptyAndAllSelected()
            && this.projects.pagination$.value.offset === 0
            && this.projects.items!.length < this.projects.pagination$.value.limit;
    }

    openCreateModal() {
        this.dialog.open(ProjectCreationDialogComponent);
    }

    openDeleteModal() {
        const selected = [...this.projects.selected];
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: selected.length === 1 ? "Delete project" : "Delete projects",
                body: confirmationMessage("delete", selected, "project") + ` This action cannot be undone.`,
                confirmText: selected.length === 1 ? "Delete project" : "Delete projects",
                strongConfirmationString: strongConfirmationStringOf(selected),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => combineLatest(selected.map(x => this.projectApi.deleteProject(x.uuid))))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Project(s) deleted successfully.`);
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
