@if (table.ready$ | async) {
  <table mat-table [dataSource]="table.items$" matSort (matSortChange)="tableService.handleMatSortChange(table, $event)">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef><tp-table-selection-header-cell [table]="table"/></th>
      <td mat-cell *matCellDef="let deployment"><tp-table-selection-cell [table]="table" [row]="deployment"/></td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.id.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.id }}</td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.project.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.project.id }}</td>
    </ng-container>
    <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.provider.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.provider.name }}</td>
    </ng-container>
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.region.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.region | region:'short' }}</td>
    </ng-container>
    <ng-container matColumnDef="machineType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.machineType.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.machineType.vendorId }}</td>
    </ng-container>
    <ng-container matColumnDef="clusterSize">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.clusterSize.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.serverCount }}</td>
    </ng-container>
    <ng-container matColumnDef="storageType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.storageType.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.storageType.id }}</td>
    </ng-container>
    <ng-container matColumnDef="storageSize">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.storageSize.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.storageGB }}</td>
    </ng-container>
    <ng-container matColumnDef="deploymentStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.deploymentStatus.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.status | deploymentStatus }}</td>
    </ng-container>
    <ng-container matColumnDef="typeDBVersion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.typeDBVersion.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.typeDBVersion }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.createdAt.name }}</th>
      <td mat-cell *matCellDef="let deployment">{{ deployment.createdAt | datetime }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let deployment"><tp-table-status-cell [status]="deploymentIcon(deployment)"/></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
    <tr
      mat-row *matRowDef="let deployment; columns: table.displayedColumns"
      [class]="tableService.rowClass$(table, deployment) | async"
      (click)="tableService.handleRowClick(table, deployment)"
    ></tr>
  </table>
} @else {
  <tp-spinner/>
}
