/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    ApplicationProtoPrivateReq, ApplicationProtoPrivateRes, ApplicationProtoPrivateResType, ApplicationProtoPublicReq,
    ApplicationProtoReq, ApplicationProtoRes
} from "../../../application/protocol/application";
import { DeploymentCtlProtoReq, DeploymentCtlProtoRes } from "../../../application/protocol/deployment-controller";
import { OrganizationCtlProtoReq, OrganizationCtlProtoRes } from "../../../application/protocol/organization-controller";
import { ProjectCtlProtoReq, ProjectCtlProtoRes } from "../../../application/protocol/project-controller";
import { SubscriptionCtlProtoReq, SubscriptionCtlProtoRes } from "../../../application/protocol/subscription-controller";
import { TeamCtlProtoReq, TeamCtlProtoRes } from "../../../application/protocol/team-controller";
import { UserCtlProtoReq, UserCtlProtoRes } from "../../../application/protocol/user-controller";
import { AuthenticationCtlProtoPublicRes } from "../../../protocol/authentication-controller";
import { StatusProtoError } from "../../../protocol/common";
import { bytesToString, stripUndefinedValues } from "../../util";

export function reqToJson(req: ApplicationProtoReq) {
    const obj = {
        uuid: bytesToString(req.uuid),
        ...(req.hasPublic ? (publicReqToJson(req.public)) : {}),
        ...(req.hasPrivate ? privateReqToJson(req.private) : {}),
    };
    return stripUndefinedValues(obj);
}

export function publicReqToJson(req: ApplicationProtoPublicReq) {
    switch (req.authCtl.req) {
        case "none": return {};
        default: return { [req.authCtl.req]: req.authCtl[req.authCtl.req] };
    }
}

export function privateReqToJson(req: ApplicationProtoPrivateReq) {
    switch (req.req) {
        case "none": return {};
        case "authCtl": return { authCtl: { deauthUser: req.authCtl.deauthUser }};
        case "userCtl": return { userCtl: userCtlReqToJson(req.userCtl) };
        case "orgCtl": return { orgCtl: orgCtlReqToJson(req.orgCtl) };
        case "projectCtl": return { projectCtl: projectCtlReqToJson(req.projectCtl) };
        case "teamCtl": return { teamCtl: teamCtlReqToJson(req.teamCtl) };
        case "subscriptionCtl": return { subscriptionCtl: subscriptionCtlReqToJson(req.subscriptionCtl) };
        case "deploymentCtl": return { deploymentCtl: deploymentCtlReqToJson(req.deploymentCtl) };
        case "unsub": return { unsub: req.unsub };
    }
}

function userCtlReqToJson(req: UserCtlProtoReq) {
    switch (req.req) {
        case "none": return {};
        default: return { [req.req]: req[req.req] };
    }
}

function orgCtlReqToJson(req: OrganizationCtlProtoReq) {
    switch (req.req) {
        case "none": return {};
        default: return { [req.req]: req[req.req] };
    }
}

function subscriptionCtlReqToJson(req: SubscriptionCtlProtoReq) {
    switch (req.req) {
        case "none": return {};
        default: return { [req.req]: req[req.req] };
    }
}

function projectCtlReqToJson(req: ProjectCtlProtoReq) {
    switch (req.req) {
        case "none": return {};
        default: return { [req.req]: req[req.req] };
    }
}

function deploymentCtlReqToJson(req: DeploymentCtlProtoReq) {
    switch (req.req) {
        case "none": return {};
        default: return { [req.req]: req[req.req] };
    }
}

function teamCtlReqToJson(req: TeamCtlProtoReq) {
    switch (req.req) {
        case "none": return {};
        default: return { [req.req]: req[req.req] };
    }
}

export function resToJson(res: ApplicationProtoRes) {
    const obj = {
        uuid: bytesToString(res.uuid),
        status: res.status,
        error: res.hasError ? errorStatusToJson(res.error) : undefined,
        ...(res.hasPublic ? (authPublicResToJson(res.public.authCtl)) : {}),
        ...(res.hasPrivate ? privateResToJson(res.private) : {}),
    };
    return stripUndefinedValues(obj);
}

export function authPublicResToJson(res: AuthenticationCtlProtoPublicRes) {
    switch (res.res) {
        case "none": return {};
        case "startAdminAuth": return { startAdminAuth: res.startAdminAuth };
        case "authAdminToken": return { authAdminToken: res.authAdminToken };
        case "startUserAuth": return { startUserAuth: res.startUserAuth };
        case "authUserToken": return { authUserToken: res.authUserToken };
    }
}

export function privateResToJson(res: ApplicationProtoPrivateRes) {
    const obj = {
        type: controllerResTypeToString(res.type),
        userCtl: res.hasUserCtl ? userCtlResToJson(res.userCtl) : undefined,
        orgCtl: res.hasOrgCtl ? orgCtlResToJson(res.orgCtl) : undefined,
        subscriptionCtl: res.hasSubscriptionCtl ? subscriptionCtlResToJson(res.subscriptionCtl) : undefined,
        projectCtl: res.hasProjectCtl ? projectCtlResToJson(res.projectCtl) : undefined,
        deploymentCtl: res.hasDeploymentCtl ? deploymentCtlResToJson(res.deploymentCtl) : undefined,
        teamCtl: res.hasTeamCtl ? teamCtlResToJson(res.teamCtl) : undefined,
    };
    return stripUndefinedValues(obj);
}

const resTypeStrings: Record<ApplicationProtoPrivateResType, string> = {
    [ApplicationProtoPrivateResType.INITIAL]: "initial",
    [ApplicationProtoPrivateResType.ADDITION]: "add",
    [ApplicationProtoPrivateResType.UPDATE]: "update",
    [ApplicationProtoPrivateResType.DELETE]: "delete",
}

function controllerResTypeToString(resType: ApplicationProtoPrivateResType) {
    return resTypeStrings[resType];
}

function errorStatusToJson(res: StatusProtoError) {
    return {
        code: res.code,
        message: res.message,
    };
}

function userCtlResToJson(res: UserCtlProtoRes) {
    switch (res.res) {
        case "none": return {};
        default: return { [res.res]: res[res.res] };
    }
}

function orgCtlResToJson(res: OrganizationCtlProtoRes) {
    switch (res.res) {
        case "none": return {};
        default: return { [res.res]: res[res.res] };
    }
}

function subscriptionCtlResToJson(res: SubscriptionCtlProtoRes) {
    switch (res.res) {
        case "none": return {};
        default: return { [res.res]: res[res.res] };
    }
}

function projectCtlResToJson(res: ProjectCtlProtoRes) {
    switch (res.res) {
        case "none": return {};
        default: return { [res.res]: res[res.res] };
    }
}

function deploymentCtlResToJson(res: DeploymentCtlProtoRes) {
    switch (res.res) {
        case "none": return {};
        default: return { [res.res]: res[res.res] };
    }
}

function teamCtlResToJson(res: TeamCtlProtoRes) {
    switch (res.res) {
        case "none": return {};
        default: return { [res.res]: res[res.res] };
    }
}
