/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { DeploymentApi } from "./deployment/deployment-api.service";
import { TeamApi } from "./team/team-api.service";
import { UserApi } from "./user/user-api.service";
import { OrgApi } from "./org/org-api.service";
import { ProjectApi } from "./project/project-api.service";

@Injectable({
    providedIn: "root",
})
export class AuthorizationService {
    constructor(
        private orgApi: OrgApi, private deploymentApi: DeploymentApi, private projectApi: ProjectApi,
        private teamApi: TeamApi, private userApi: UserApi,
    ) {
    }

    orgAccessLevels(orgUuids: string[]) {
        return this.orgApi.getAccessLevels(orgUuids);
    }

    deploymentAccessLevels(deploymentUuids: string[]) {
        return this.deploymentApi.getAccessLevels(deploymentUuids);
    }

    projectAccessLevels(projectUuids: string[]) {
        return this.projectApi.getAccessLevels(projectUuids);
    }

    teamAccessLevels(teamUuids: string[]) {
        return this.teamApi.getAccessLevels(teamUuids);
    }

    userAccessLevels(userUuids: string[]) {
        return this.userApi.getAccessLevels(userUuids);
    }
}
