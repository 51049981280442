/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, ViewChild } from "@angular/core";
import { BannerComponent } from "../../scaffold/banner/banner.component";
import { TopbarComponent } from "../../scaffold/topbar/topbar.component";
import { RightSidebarComponent } from "../../scaffold/sidebar/right/right-sidebar.component";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";
import { RouterOutlet } from "@angular/router";
import { MatToolbarModule } from "@angular/material/toolbar";

@Component({
    selector: "tp-setup-scaffold",
    standalone: true,
    imports: [
        RouterOutlet, BannerComponent, TopbarComponent, RightSidebarComponent, MatSidenavModule, MatToolbarModule,
    ],
    templateUrl: "./setup-scaffold.component.html",
    styleUrl: "./setup-scaffold.component.scss"
})
export class SetupScaffoldComponent {
    @ViewChild(MatSidenav) rightSidenav!: MatSidenav;

    toggleRightSidenav() {
        this.rightSidenav.toggle(undefined, "mouse");
    }
}
